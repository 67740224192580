import React, { Component } from 'react';
import ReactPlayer from 'react-player';

class SeedDataTemplate extends Component {
	render() { 
		let { dataToPreview } = this.props;
		let previewSeedDataWrapper = {
			marginTop: '20px',
			marginRight: '10px',
			marginLeft: '10px',
		};
		return ( 
			<div style={previewSeedDataWrapper}>
				<div>
					<h3>Title</h3>
					<p>{dataToPreview.en.title}</p>
					<p>{dataToPreview.hi.title}</p>
				</div>
				<div>
					<h3>Images</h3>
					{
						dataToPreview.allImages.map(image => 
							<div key={image._id} style={{margin: '5px', width: '150px'}}>
								<img src={image.path} />
							</div>
						)
					}
				</div>
				<div>
					{
						dataToPreview.videos.map(vid => 
							<ReactPlayer 
								key={vid._id}
								url={vid.path} 
								playing={false} 
								controls={true} 
								width="70%"/>
						)
					}
				</div>
				<div>
					<h3>Summary</h3>
					<p>{dataToPreview.en.summary}</p>
					<p>{dataToPreview.hi.summary}</p>
				</div>
				<div>
					<h3>Links</h3> 
					{
						dataToPreview .length && dataToPreview.en.links.map((link, index) => 
							<a key={index} href={link.url}>{link.url}</a>
						)
					}
					{
						dataToPreview .length && dataToPreview.hi.links.map((link, index) => 
							<a key={index} href={link.url}>{link.url}</a>
						)
					}
				</div>
				<div>
					<h3>Article Link</h3>
					<p>{dataToPreview.en.article_url}</p>
					<p>{dataToPreview.hi.article_url}</p>
					<p>{}</p>
				</div>
			</div>
		);
	}
}
 
export default SeedDataTemplate;