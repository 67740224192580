const constants = Object.freeze({
	BASE_URL: 'https://factcheck.altnews.in/api/',
	// BASE_URL: 'http://192.168.0.6:3000/api/',
	// BASE_URL: 'http://localhost:3000/api/',
	// BASE_URL: 'http://54.169.111.145:3000/api/',
	SALT: '1Ixx32YJqoJ',
	CREATE_SEED_DATA: 'fc',
	CREATE_LITE_SEED_DATA: 'lite-fc',
	ALL_LITE_SEED_LIST: 'all-lite-fc',
	CREATE: 'create',
	SCRAPE: 'scrape',
	USER: 'moderator',
	USER_REQUESTS: 'request',
	APP_SETTING: 'setting',
	LOGIN: 'moderator/login',
	ACCEPTED_REQUESTS: 'request/accepted',
	SEARCH_BY_KEYWORDS: 'searchByKeywords',
	SUGGEST: 'suggest',
	MERGE: 'merge',
	BULK_MERGE: 'bulk-merge',
	VERIFY_JWT: 'moderator/verifyToken',
	REQUEST_LOG: 'requestLog',
	ADMIN: 'ADMIN',
	MODERATOR: 'MODERATOR',
	FC_HASH: 'fcHash',
	FILTERS: 'filters',
	APP_VERSION: 'appVersion',
	PENDING_REQUESTS: 'Pending',
	ACCEPTED_REQUESTS: 'accepted',
	REJECTED_REQUESTS: 'rejected',
	BOOKMARK: 'bookmark',
	BULK: 'bulk',
	DONATE_NOTIFICATIONS: 'donate-notifications',
	SEND: 'send',
	GROUPED: 'grouped',
	SIMILAR: 'similar',
	PHASH: 'phash',
	MD5: 'md5',
	LEFT_OUT: 'left-out'
});

export default constants;
