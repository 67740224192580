import React, { Component, lazy, Suspense } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  Dialog,
  DialogTitle,
  Tab,
  Tabs
} from '@material-ui/core';
import './style.css';
import Api from '../../services/api';
const moment = require('moment');

const InfiniteCalendar = lazy(() => import('react-infinite-calendar'));

class AppSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appId: '',
      androidAppVersion: '',
      iosAppVersion: '',
      androidappVersion: {
        major: '',
        minor: '',
        patch: ''
      },
      iosappVersion: {
        major: '',
        minor: '',
        patch: ''
      },
      tempAndroidAppVersion: {
        major: '',
        minor: '',
        patch: ''
      },
      tempIosAppVersion: {
        major: '',
        minor: '',
        patch: ''
      },
      hammingDistance: 0,
      openDialog: false,
      expiredOpenDialog: false,
      rejectionTemplate: [],
      updateAppSettings: false,
      settingsId: '',
      loader: false,
      snackbar: false,
      currentTab: 0,
      autoExpiredValue: 0,
      snackbarContent: {
        variant: '',
        message: ''
      },
      selectedDate: '',
      showCalendar: false,
      showCalendarFor: '',
      En: 'Hey! The information you submitted has been fact-checked by Alt News.Take a look!',
      Hi: 'आपने जो जानकारी सबमिट की थी, ऑल्ट न्यूज़ ने उसकी पड़ताल की है, जिसे आप यहाँ पढ़ सकते हैं।',
      disableEnglishField: true,
      disableHindiField: true
    };

    this.textInput = React.createRef();
  }

  componentDidMount() {
    let { androidappVersion, tempAndroidAppVersion, iosappVersion, tempIosAppVersion  } = this.state;
    this.setState({ loader: true, currentTab: 0 });
    Api.getAppSettings()
      .then(res => {
        return res.json();
      }).then(res => {
        if (res.data.length) {
          this.setState({ updateAppSettings: true });
          let appId = res.data[0]._id;
          let hammingDistance = res.data[0].hammingDistance;
          let En = res.data[0].En;
          let Hi = res.data[0].Hi;
          let rejectionTemplate = res.data[0].rejectionTemplate;
          let settingsId = res.data[0]._id;
          let androidAppVersion = res.data[0].androidAppVersion;
          let splittedVersion = androidAppVersion.split('.');
          let iosAppVersion = res.data[0].iosAppVersion;
          let splittedIosVersion = iosAppVersion.split('.');
          androidappVersion.major = splittedVersion[0];
          androidappVersion.minor = splittedVersion[1];
          androidappVersion.patch = splittedVersion[2];
          tempAndroidAppVersion.major = splittedVersion[0];
          tempAndroidAppVersion.minor = splittedVersion[1];
          tempAndroidAppVersion.patch = splittedVersion[2];
          iosappVersion.major = splittedIosVersion[0];
          iosappVersion.minor = splittedIosVersion[1];
          iosappVersion.patch = splittedIosVersion[2];
          tempIosAppVersion.major = splittedIosVersion[0];
          tempIosAppVersion.minor = splittedIosVersion[1];
          tempIosAppVersion.patch = splittedIosVersion[2];
          this.setState({ En, Hi, hammingDistance, rejectionTemplate, settingsId, appId,
              androidAppVersion: androidAppVersion, androidappVersion,tempAndroidAppVersion, iosAppVersion: iosAppVersion, 
              iosappVersion, tempIosAppVersion })
        } else {
          this.setState({ updateAppSettings: false });
        }
        this.setState({ loader: false });
      });
  }

  addTemplate = () => {
    let rejectionTemplate = this.state.rejectionTemplate;
    rejectionTemplate.push({ body: '' });
    this.setState({ rejectionTemplate });
    let len = rejectionTemplate.length - 1;
    setTimeout(() => {
      this.refs[len].focus();
    }, 300);
  }

  handleKeyPress = (index, e) => {
    if (e.keyCode === 13) {
      this.addTemplate();
      index++;
      setTimeout(() => {
        this.refs[index].focus();
      }, 100);
    } else {
      let value = e.target.value;
      let rejectionTemplate = [...this.state.rejectionTemplate];
      rejectionTemplate[index].body = value;
      this.setState({ rejectionTemplate });
    }
  }

  handleKeyPressHindi = (index, e) => {
    if (e.keyCode === 13) {
      this.addTemplate();
      index++;
      setTimeout(() => {
        this.refs[index].focus();
      }, 100);
    } else {
      let value = e.target.value;
      let rejectionTemplate = [...this.state.rejectionTemplate];
      rejectionTemplate[index].bodyHi = value;
      this.setState({ rejectionTemplate });
    }
  }

  deleteTemplate = (index) => {
    let rejectionTemplate = [...this.state.rejectionTemplate];
    rejectionTemplate = rejectionTemplate.filter((template, indx) => indx !== index);
    this.setState({ rejectionTemplate });
    index = index === 0 ? 0 : index--;
  }

  saveAppSettings = () => {
    this.setState({ loader: true });
    let { En, Hi, hammingDistance, androidAppVersion, iosAppVersion, rejectionTemplate, settingsId } = this.state;
    rejectionTemplate = rejectionTemplate.filter(item => item.body !== '');
    let appSettings = { En, Hi, hammingDistance, androidAppVersion, iosAppVersion, rejectionTemplate };
    if (this.state.updateAppSettings) {
      // UPDATE APP SETTINGS
      Api.updateAppSettings(settingsId, appSettings)
        .then(res => {
          return res.json();
        }).then(res => {
          let { En, Hi, hammingDistance, androidAppVersion, iosAppVersion, rejectionTemplate } = res.settingData;
          this.setState({ En, Hi, hammingDistance, androidAppVersion, iosAppVersion, rejectionTemplate }, () => {
          });
          this.setState({ loader: false });
          let snackbarContent = { variant: 'success-bg-color', message: 'App Settings Updated Successfully!' };
          this.setState({ snackbarContent });
          this.setState({ snackbar: true });
        });
    } else {
      // CREATE APP SETTINGS
      Api.saveAppSettings(appSettings)
        .then(res => {
          return res.json();
        }).then(res => {
          this.setState({ loader: false });
        });
    }
  }

  setHammingDistance = (e) => {
    let hammingDistance = e.target.value;
    this.setState({ hammingDistance });
  }

  setAndroidAppVarsion = (e) => {
    let androidAppVersion = e.target.value;
    this.setState({ androidAppVersion })
  }

  setIosAppVarsion = (e) =>{
    let iosAppVersion = e.target.value;
    this.setState({ iosAppVersion })
  }
  closeSnackbar = () => {
    this.setState({ snackbar: false });
  }

  setAppVersion = (e, field) => {
    let { androidappVersion } = this.state;
    let value = e.target.value;
    switch (field) {
      case 'major':
        androidappVersion.major = value;
        this.setState({ androidappVersion });
        break;
      case 'minor':
        androidappVersion.minor = value;
        this.setState({ androidappVersion });
        break;
      case 'patch':
        androidappVersion.patch = value;
        this.setState({ androidappVersion });
        break;
    }
  }

  updateAppVersion = async () => {

    let { androidappVersion, tempAndroidAppVersion, iosappVersion, tempIosAppVersion, appId } = this.state;
    let androidDiffMajor = androidappVersion.major - tempAndroidAppVersion.major;
    let androidDiffMinor = androidappVersion.minor - tempAndroidAppVersion.minor;
    let androidDiffPatch = androidappVersion.patch - tempAndroidAppVersion.patch;

    let iosDiffMajor = iosappVersion.major - tempIosAppVersion.major;
    let iosDiffMinor = iosappVersion.minor - tempIosAppVersion.minor;
    let iosDiffPatch = iosappVersion.patch - tempIosAppVersion.patch;
    let updatedAndroidAppVersion = androidappVersion.major + '.' + androidappVersion.minor + '.' + androidappVersion.patch;
    let updatedIosAppVersion = iosappVersion.major + '.' + iosappVersion.minor + '.' + iosappVersion.patch;

    let validation = 0;

    if (androidDiffMajor > 0 && iosDiffMajor > 0) {
      await this.changeAndUpdateAndroidVersion(updatedAndroidAppVersion, updatedIosAppVersion, appId);

    } else if ((androidDiffMajor === 0 && iosDiffMajor === 0) && (androidDiffMinor > 0 && iosDiffMinor > 0)) {
      await this.changeAndUpdateAndroidVersion(updatedAndroidAppVersion, updatedIosAppVersion, appId);

    } else if ((androidDiffMajor === 0 && iosDiffMajor === 0)  && (androidDiffMinor > 0 && iosDiffMinor > 0) && (androidDiffPatch > 0 && iosDiffMinor > 0)) {
      await this.changeAndUpdateAndroidVersion(updatedAndroidAppVersion, updatedIosAppVersion, appId);

    } else {
      androidappVersion.major = tempAndroidAppVersion.major;
      androidappVersion.minor = tempAndroidAppVersion.minor;
      androidappVersion.patch = tempAndroidAppVersion.patch;
      iosappVersion.major = tempIosAppVersion.major;
      iosappVersion.minor = tempIosAppVersion.minor;
      iosappVersion.patch = tempIosAppVersion.patch;

      let snackbarContent = {
        variant: 'danger-bg-color',
        message: 'Error : App version to be updated is less than current version'
      };
      this.setState(
        { snackbarContent, snackbar: true, androidappVersion, tempAndroidAppVersion, iosappVersion, tempIosAppVersion}
      );
    }
    this.setState({
      openDialog: false
    })
  }

  changeAndUpdateAndroidVersion = async (updatedAndroidAppVersion, updatedIosAppVersion, appId) => {
    const { androidAppVersion, iosAppVersion } = this.state;
    const res = await Api.updateAppVersion(updatedAndroidAppVersion, updatedIosAppVersion, appId);

    if (res.settingData.androidAppVersion !== androidAppVersion && res.settingData.iosAppVersion !== iosAppVersion) {
      let androidAppVersion = res.settingData.androidAppVersion;
      let splittedVersion = androidAppVersion.split('.');
      let iosAppVersion = res.settingData.iosAppVersion;
      let splittedIosVersion = iosAppVersion.split('.');
      let { androidappVersion, tempAndroidAppVersion , iosappVersion, tempIosAppVersion} = this.state;
      androidappVersion.major = splittedVersion[0];
      androidappVersion.minor = splittedVersion[1];
      androidappVersion.patch = splittedVersion[2];
      tempAndroidAppVersion.major = splittedVersion[0];
      tempAndroidAppVersion.minor = splittedVersion[1];
      tempAndroidAppVersion.patch = splittedVersion[2];
      iosappVersion.major = splittedIosVersion[0];
      iosappVersion.minor = splittedIosVersion[1];
      iosappVersion.patch = splittedIosVersion[2];
      tempIosAppVersion.major = splittedIosVersion[0];
      tempIosAppVersion.minor = splittedIosVersion[1];
      tempIosAppVersion.patch = splittedIosVersion[2];
      let snackbarContent = {
        variant: 'success-bg-color',
        message: 'App version updated successfully'
      };
      this.setState(
        { snackbarContent, snackbar: true, androidappVersion, tempAndroidAppVersion, androidAppVersion }
      );
    }
  }

  setRequestExpired = (e) => {
    this.setState({ autoExpiredValue: e })
  }

  updateExpiredRequest = () => {
    const date = moment(this.state.autoExpiredValue).toISOString()

    Api.deleteOldRequest(date).then(res => {
      if (res.success) {
        const messageSnackbar = { 
          variant: 'success-bg-color',
          message: `${res.mssg} ${moment(this.state.autoExpiredValue).format("DD/MM/YYYY")}`
        }
        this.setState({
          snackbar: true,
          snackbarContent: messageSnackbar,
          expiredOpenDialog: false
        })
      } else {
        const messageSnackbar = {
          variant: 'danger-bg-color',
          message: res.mssg ? res.mssg : 'Expired Request Failed'
        }
        this.setState({
          snackbar: true,
          snackbarContent: messageSnackbar,
          expiredOpenDialog: false
        })
      }
    })
  }

  hideCalendar = (e) => {
    if (e.target.className === 'calendar-wrapper') {
      this.setState({ showCalendar: false });
    }
  };

  renderCalendar = () => {
    let { showCalendarFor } = this.state;
    return (
      <div className='calendar-wrapper' onClick={(e) => this.hideCalendar(e)}>
        <Suspense fallback={<p>Loading Calendar...</p>}>
          <InfiniteCalendar
            selected={false}
            onSelect={(e) => { this.dateSelected(e, showCalendarFor); }}
          />
        </Suspense>
      </div>
    );
  }


  dateSelected = async (date, showCalendarFor) => {
    let { selectedDate } = this.state;

    if (showCalendarFor == 'Date') {
      selectedDate = date;
      await this.setState({ selectedDate, showCalendar: true });
      this.setRequestExpired(this.state.selectedDate)
    }
    else {
      //nothing
    }
  }

  handleLSDNotifEnglish = (e) => {
    this.setState({ En: e.target.value })
  }

  handleLSDNotifHindi = (e) => {
    this.setState({ Hi: e.target.value })
  }

  handleEnglishText = () => {
    this.setState({ disableEnglishField: !this.state.disableEnglishField });

  }

  handleHindiText = () => {
    this.setState({ disableHindiField: !this.state.disableHindiField });
  }

  render() {
    const {
      rejectionTemplate,
      hammingDistance,
      androidAppVersion,
      iosAppVersion,
      En,
      Hi,
      loader,
      snackbar,
      snackbarContent,
      currentTab,
      openDialog,
      expiredOpenDialog,
    } = this.state;

    return (
      <div className="app-settings-wrapper">
        {loader ?
          <div className="" style={{ textAlign: 'center' }}>
            <CircularProgress color="secondary" />
          </div>
          : <Grid container>
            <Grid item xs={12} >
              <Tabs
                value={currentTab}
                style={{
                  backgroundColor: '#efefef',
                  top: '0px',
                  position: 'fixed',
                  width: '100%',
                  zIndex: '20',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.09)'
                }}
              >
                <Tab
                  onClick={() => this.setState({ currentTab: 0 })}
                  label="Mobile Configuration"
                  className="mobile-configuration"
                />
                <Tab
                  onClick={() => this.setState({ currentTab: 1 })}
                  className="Dashboard Configuration"
                  label="dashboard-configuration"
                />
              </Tabs>
              {currentTab === 0 &&
                <div autoComplete="off" style={{ marginTop: '70px', padding: '20px', height: '90vh', overflow: 'scroll' }}>
                  <div style={{ display: 'flex' }}>
                    <Typography component="h3" variant="h4">
                      Mobile App Settings
                </Typography>
                    {
                      (this.state.En && this.state.Hi) ?
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.saveAppSettings()}
                        >
                          Submit
                        </Button>
                        :
                        <Button
                          variant="contained"
                          color="primary"
                          disabled
                        >
                          Submit
                        </Button>

                    }
                  </div>
                  <Paper className="cust-paper" elevation={1}>
                    <TextField
                      value={hammingDistance}
                      label="Hamming Distance"
                      margin="normal"
                      width="300px"
                      onChange={(e) => this.setHammingDistance(e)}
                      type="number"
                    />
                     <TextField
                      value={androidAppVersion}
                      label="Android App Version"
                      margin="normal"
                      style={{marginLeft: "35px"}}
                      width="300px"
                      onChange={(e) => this.setAndroidAppVarsion(e)}
                    />
                     <TextField
                      value={iosAppVersion}
                      label="Ios App Version"
                      margin="normal"
                      style={{marginLeft: "35px"}}
                      width="300px"
                      onChange={(e) => this.setIosAppVarsion(e)}
                    />
                  </Paper>
                  <Paper className="cust-paper" >
                    <div style={{ marginBottom: '15px' }}>
                      English Fact Check Notification for LSD
                    <TextField
                        value={En}
                        margin="normal"
                        width="300px"
                        fullWidth
                        required
                        label="Required"
                        onChange={(e) => this.handleLSDNotifEnglish(e)}
                        type="String"
                      />
                    </div>
                    <div>
                      Hindi Fact Check Notification for LSD
                    <TextField
                        value={Hi}
                        margin="normal"
                        width="300px"
                        fullWidth
                        required
                        label="Required"
                        onChange={(e) => this.handleLSDNotifHindi(e)}
                        type="String"
                      />
                    </div>
                  </Paper>

                  <Paper className="cust-paper" elevation={1}>
                    <Typography component="label" variant="h6">
                      Request Rejection Template
  								</Typography>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: '60%',
                        backgroundColor: '#eee',
                      }}>
                      <div
                        style={{
                          borderRight: '1px solid #ccc',
                          padding: '16px',
                          width: '50%',
                          textAlign: 'center'
                        }}>
                        English Variant
                    </div>
                      <div style={{ padding: '16px', width: '50%', textAlign: 'center' }}>Hindi Variant</div>
                    </div>
                    {rejectionTemplate.map((template, index) =>
                      <div className="template-wrapper" key={index}>
                        <div >
                          <input
                            ref={index}
                            value={template.body}
                            className="cust-input"
                            onChange={(e) => this.handleKeyPress(index, e)}
                            onKeyUp={(e) => this.handleKeyPress(index, e)}
                            onBlur={(e) => this.handleKeyPress(index, e)}
                            placeholder='english variant'
                          />
                        </div>
                        <div >
                          <input
                            value={template.bodyHi}
                            className="cust-input"
                            onChange={(e) => this.handleKeyPressHindi(index, e)}
                            onKeyUp={(e) => this.handleKeyPressHindi(index, e)}
                            onBlur={(e) => this.handleKeyPressHindi(index, e)}
                            placeholder='hindi variant'
                          />
                        </div>
                        <i
                          onClick={() => this.deleteTemplate(index)}
                          className="material-icons danger-text icon-action">
                          cancel
                      </i>
                      </div>
                    )}
                    <i onClick={() => this.addTemplate()} style={{ fontSize: '30px' }}
                      className="material-icons success-text icon-action">
                      add_circle
  								</i>
                  </Paper>
                </div>}
              {currentTab === 1 &&
                <div className="dashboard-app-settings" style={{ marginTop: '70px', padding: '20px' }}>
                  <Typography component="h3" variant="h4">
                    Dashboard Settings
  						    </Typography>
                  <Paper className="cust-paper" elevation={1} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div className='date-filter'>
                      Expire requests before
                      <input
                        type='text'
                        onChange={() => console.log('')}
                        onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'Date' })}
                        value={this.state.selectedDate !== '' ? moment(this.state.selectedDate).format('DD/MM/YYYY') : ''} />
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '16px', marginBottom: '8px' }}
                      onClick={() => this.setState({ expiredOpenDialog: true })} >
                      Update
  								  </Button>
                  </Paper>
                </div>
              }
            </Grid>
          </Grid>
        }
        {
          this.state.showCalendar ?
            <div>
              {this.renderCalendar()}
            </div>
            :
            null
        }
        <Dialog aria-labelledby="simple-dialog-title" open={openDialog}>
          <DialogTitle id="simple-dialog-title">Are You Sure? This action will be irreversable.</DialogTitle>
          <div style={{ display: 'flex', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() => { this.setState({ openDialog: false }) }}
            >
              Cancel
          </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.updateAppVersion}
            >
              Submit
          </Button>
          </div>
        </Dialog>
        <Snackbar
          open={snackbar}
          autoHideDuration={3000}
          onClose={() => this.closeSnackbar()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SnackbarContent
            className={snackbarContent.variant}
            message={snackbarContent.message} />
        </Snackbar>
        <Dialog aria-labelledby="expired-request-dialog-title" open={expiredOpenDialog}>
          <DialogTitle id="expired-request-dialog-title">Are you sure you want to delete these. Once deleted they cannot be recovered!</DialogTitle>
          <div style={{ display: 'flex', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() => { this.setState({ expiredOpenDialog: false }) }}
            >
              Cancel
          </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.updateExpiredRequest}
            >
              Submit
          </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default AppSettings;