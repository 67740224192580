import React, { Component, lazy, Suspense } from 'react';
import './style.css';

// const StepperForm = lazy(() => require('../StepperForm'));

import StepperForm from '../StepperForm';

import Api from '../../services/api';
import noImage from '../../assets/no-image.png'
import {
	Tabs,
	Tab,
	ListItem,
	List,
	Button,
	CircularProgress,
	TextField,
	Chip,
	Radio,
	Box,
	Snackbar,
	Card,
	CardContent,
	Paper,
	Typography,
	CardMedia,
	Fab,
	SnackbarContent
} from '@material-ui/core';
import {
	Delete
} from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import ReactPlayer from 'react-player';
import MergedFC from '../merged-fc';
import constants from '../../services/constants';


// const StepperForm = lazy(() => import('../StepperForm'));



class SeedData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			currentTab: 1,
			currentIndex: 0,
			preview: false,
			showEdit: false,
			listLoader: false,
			searchKeyword: '',
			showClearButton: false,
			pageSize: 20,
			numOfRequest: 0,
			dataToDisplay: [],
			initialPage: 0,
			showPager: true,
			fc_hash: [],
			checkedBulkItem: [],
			loading_Fc_Hash: false,
			showMergedFC: false,
			openSnackbar: false,
			showSimilarRequestFcModal: false,
			snackbarMessage: '',
			snackbarClass: '',
			currentRequest: [],
			similarRequestBlock: [],
			changeClass: false
		};
	}

	showPreview = (index) => {
		this.setState({ currentIndex: index, preview: true, showEdit: false });
		this.getFcHash(this.state.dataToDisplay[index]._id);
	}

	hideMergedFC = () => {
		this.setState({ showMergedFC: false });
	}

	getFcHash = (fc_id) => {
		this.setState({ loading_Fc_Hash: true });
		Api.getFcHashOfAFC(fc_id)
			.then(res => {
				return res.json();
			})
			.then(fc_hash => {
				this.setState({ fc_hash: fc_hash.data, loading_Fc_Hash: false });
			});
	}

	listSeed = () => {
		this.setState({ listLoader: true });
		Api.getSeedData()
			.then(res => {
				return res.json();
			})
			.then(res => {
				let data = res.data;
				this.setState({
					data: data,
					dataToDisplay: data.slice(0, 20),
					listLoader: false,
					currentTab: 1,
					numOfRequest: data.length,
					showPager: true
				});
			});
		this.setState({ currentTab: 1 });
	}

	componentDidMount() {
		this.listSeed();
	}

	handleEdit = () => {
		this.setState({ showEdit: true, preview: false, changeClass: true });
	}

	updateData = () => {
		this.listSeed();
	}

	searchSeedData = (e) => {
		let searchKeyword = e.target.value;
		this.setState({ searchKeyword });
	}

	onSearchKeyUp = (e) => {
		let keyCode = e.keyCode;
		if (keyCode === 13) {
			let keyword = e.target.value;
			if (e.target.value.length > 0) {
				let keywords = [keyword];
				this.setState({
					listLoader: true,
					data: [],
					dataToDisplay: [],
					preview: false,
					numOfRequest: 0,
					showPager: false
				});
				Api.getFactchecksByKeywords(keywords, false, false).then(res => {
					this.setState({
						data: res.data,
						dataToDisplay: res.data.slice(0, 20),
						numOfRequest: res.data.length,
						listLoader: false,
						initialPage: 0,
						showClearButton: true,
						showPager: true
					});
				});
			}
		}
	}

	actionsBar = () => {

		return (
			<div
				className='action-bar-wrapper'
			>
				<TextField
					style={{ width: '50%', marginLeft: '5px' }}
					id="outlined-search"
					label={<i style={{ lineHeight: '18px' }} className="material-icons">search</i>}
					variant="outlined"
					placeholder="Type & Press Return to Search"
					onChange={this.searchSeedData}
					onKeyUp={this.onSearchKeyUp}
					value={this.state.searchKeyword}
				/>
				{this.state.showClearButton ?
					<Button
						style={{ left: '-75px', margin: '5px', lineHeight: '33px' }}
						onClick={this.clearSearchBar}>
						clear
          </Button>
					: null
				}
				{this.state.currentTab === 1 ?
					<div
						className='pagination-wrapper'
						style={{ padding: '0px 16px', marginTop: '10px' }}>
						{
							this.state.showPager ?
								this.renderPager()
								: null
						}
					</div>
					: null
				}
			</div>
			// </div>
		);
	}

	clearSearchBar = () => {
		let searchKeyword = '';
		let showClearButton = false;
		this.setState({ searchKeyword, showClearButton, numOfRequest: 0, showPager: false });
		this.listSeed();
	}

	renderPager = () => {
		let pages = this.state.numOfRequest / this.state.pageSize;
		return <ReactPaginate
			disableInitialCallback={true}
			previousLabel={'<'}
			nextLabel={'>'}
			breakLabel={'...'}
			breakClassName={'break-me'}
			pageCount={pages}
			marginPagesDisplayed={2}
			pageRangeDisplayed={5}
			onPageChange={this.changePage}
			containerClassName={'pagination'}
			subContainerClassName={'paginate'}
			activeClassName={'active'}
			initialPage={this.state.initialPage}
		/>;
	}

	changePage = (e) => {
		this.setState({ preview: false });
		let selectedPage = e.selected + 1;
		let lastPage = selectedPage;
		let pageSize = this.state.pageSize;
		lastPage = pageSize * lastPage;
		let firstPage = lastPage - pageSize;
		this.setState({
			pageNum: selectedPage,
			dataToDisplay: this.state.data.slice(firstPage, lastPage)
		});
	}

	handleListItemCheckbox = (e) => {
		e.stopPropagation();
		this.setState({
			checkedBulkItem: [
				{ id: e.target.value, status: true }
			]
		})
	}

	handleCheckedBulkItem = () => {
		this.setState({
			checkedBulkItem: []
		});
	}

	// handleAcceptBulkAction = () => {

	//   let {checkedBulkItem} = this.state;
	//   Api.searchLeftOutRequests(checkedBulkItem).then(res => {

	//   });
	// }

	handleAcceptBulkAction = () => {
		const { checkedBulkItem } = this.state;
		Api.searchLeftOutRequests(checkedBulkItem).then(res => {
			this.setSnackBarStatus(true, res.mssg, 'success-bg-color');
		});
	}

	setSnackBarStatus = (status, msg, classname) => {
		let snackBarClass = '', snackBarMessage = '';
		if (status) {
			snackBarClass = classname ? classname : 'custom-snackbar-success';
			snackBarMessage = msg ? msg : 'Merge Success';
		} else {
			snackBarClass = classname ? classname : 'custom-snackbar-danger';
			snackBarMessage = msg ? msg : 'Merge Failed';
		}
		this.setState({
			snackbarClass: snackBarClass,
			snackbarMessage: snackBarMessage,
			openSnackbar: true
		});
	}

	closeSnackbar = () => {
		this.setState({ openSnackbar: false });
	}

	getSimilarSeedData = () => {
		this.setState({
			currentTab: 2,
			listLoader: true
		});
		Api.getSimilarSeedDataRequest().then(res => {
			if (res.success)
				this.setState({
					listLoader: false,
					similarRequestBlock: res.matchingRequests
				})
		})
	}

	showSimilarRequestFcModal = (id, item) => {
		this.setState({
			currentRequest: {
				id: id,
				data: item
			},
			showSimilarRequestFcModal: true,
		})
	}

	acceptSimilarBulkRequest = () => {
		const { currentRequest } = this.state;
		const ids = currentRequest ? currentRequest.data.map(request => { return { id: request._id } }) : '';
		Api
			.sendAcceptBulkAction(ids, constants.ACCEPTED_REQUESTS)
			.then(res => res.json())
			.then(res => {
				if (res.success) {
					this.setSnackBarStatus(true, res.mssg, 'success-bg-color');
					this.setState({ showSimilarRequestFcModal: false }, this.getSimilarSeedData);
					Api.deleteSimilarSeedData(currentRequest.id).then(res => {
						if (res.success)
							this.setSnackBarStatus(true, 'Operation Completed!!', 'success-bg-color');
					});
				} else {
					this.setSnackBarStatus(true, 'Something went wrong', 'danger-bg-color');
					this.setState({ showSimilarRequestFcModal: false }, this.getSimilarSeedData);
				}
			});
	}

	removeDifferentRequest = (id) => {
    const { currentRequest } = this.state;
    const filteredRequest = currentRequest.data.filter(item => item._id !== id);
    currentRequest.data = filteredRequest;
    this.setState({ currentRequest });
	}

	renderSimilarRequestDetailedView = () => {
		const { currentRequest } = this.state;
		return (
			<div className='seed-data detailed-view-wrapper'>
				<div className='detailed-view' style={{ backgroundColor: '#fff', margin: '40px', borderRadius: '5px', height: 'auto' }}>
					<div className='action-bar'>
						<h2>Seed Data Similar Request</h2>
						{currentRequest.data.length > 0 ? <div className="form-action" style={{ display: 'flex', justifyContent: 'flex-end' }}>
							{currentRequest.data[0].status.toLowerCase() === "pending" ? <Button
								color='primary'
								style={{ lineHeight: 'normal' }}
								variant="contained"
								onClick={this.acceptSimilarBulkRequest}
							>Accept</Button> : ''
							}
						</div> : ''}
						<Button onClick={() => this.setState({ showSimilarRequestFcModal: false })}>
							<i className='material-icons' >
								cancel
            </i>
						</Button>
					</div>
					<Paper className='detailed-view-images-wrapper' style={{ boxShadow: 'none', height: '70vh', overflow: 'auto' }}>
						{currentRequest.data.length > 0 ?
							currentRequest.data.map((request, index) =>
								<Card key={request._id} className="detailed-view-images">
									<i
										className='material-icons'
										style={{ zIndex: 3 }}
										onClick={() => this.removeDifferentRequest(request._id)}>
										cancel
                </i>
									{request.images.length > 0 ?
										<CardMedia
											style={{
												height: 0,
												paddingTop: '56.25%',
											}}
											image={request.images[0].path}
										/>
										:
										<ReactPlayer
											className='lazy-vid'
											style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
											key={index}
											url={request.videos[0].path}
											playing={false}
											controls={true}
											width='100%'
											height='250px' />
									}
									<Chip
										className='bottom-left-chip'
										style={{ zIndex: 3 }}
										label={<Moment format='DD MMM YYYY'>
											{request.createdAt}
										</Moment>}
										color='secondary'>
									</Chip>
									<Chip
										className="bottom-right-chip"
										label={`${request.preferredLanguage === "en" ? "EN" : "HI"}`}
										color={`${request.preferredLanguage === "en" ? "primary" : "secondary"}`}
									/>
									<CardContent>
										<Typography variant="body2" color="textSecondary" component="p">
											{request.description ? request.description : ''}
										</Typography>
									</CardContent>
								</Card>
							) :
							<div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex', width: '100%' }}>
								<h2 className='empty-list'>
									<i className='material-icons'>error</i>
									No Data Found To Show
              </h2>
							</div>
						}
					</Paper>
				</div>
			</div>
		)
	}

	handleDeleteAllSimilarRequest = () => {
		this.setState({ listLoader: true })
		Api.deleteAllSimilarRequestSeedData().then(res => {
			if (res.success)
				this.setState({
					snackbarClass: 'custom-snackbar-success',
					snackbarMessage: res.mssg,
					openSnackbar: true,
				}, () => {
					this.getSimilarSeedData()
				})
			this.setState({ listLoader: false })
		})
	}

	handleCancel = () => {
		this.setState({ showEdit: false })
	}

	renderENChip = (item) =>{
		if(item.en.title.length > 0){
			return(
			<Chip
				label="EN"
				color="primary"
			/>)
		}
	}

	renderHIChip = (item) =>{
		if(item.hi.title.length > 0) {
		return(
		<Chip
			label="HI"
			color="secondary"
		/>)
		}
	}

	render() {
		const {
			currentTab,
			currentIndex,
			preview,
			data,
			showEdit,
			listLoader,
			dataToDisplay,
			checkedBulkItem,
			openSnackbar,
			snackbarClass,
			snackbarMessage,
			similarRequestBlock,
			showSimilarRequestFcModal,
			showMergedFC
		} = this.state;
		return (
			<div className="seed-data-container" style={{ position: 'relative' }}>
				<Tabs
					value={currentTab}
					style={{
						backgroundColor: '#efefef',
						top: '0px',
						position: 'fixed',
						width: '100%',
						zIndex: '20',
						boxShadow: '0px 2px 4px rgba(0,0,0,0.09)'
					}}
				>
					<Tab
						onClick={() => this.setState({ currentTab: 0 })}
						label="Add Seed"
						className="add-seed"
					/>

					<Tab
						onClick={this.listSeed}
						className="list-seed"
						label={<span>
							List Seed -
                      ( {this.state.data.length} )
-
  						<i style={{ verticalAlign: 'middle', lineHeight: '0px' }} className="material-icons">refresh</i>
						</span>
						}
					/>
					<Tab
						onClick={this.getSimilarSeedData}
						label={<span>
							Similar Request -
  						<i style={{ verticalAlign: 'middle', lineHeight: '0px' }} className="material-icons">refresh</i>
						</span>
						}
					/>
				</Tabs>
				{currentTab === 0 &&
					// <Suspense fallback={<p>Loading...</p>}>
					<StepperForm seedData={data} />
					// </Suspense>
				}
				{currentTab === 1 &&
					<div className="seed-data-wrapper">
						{this.actionsBar()}
						<div className={showEdit === true ? "hide" : "list"}>
							{checkedBulkItem.length > 0 ?
								<Box className="bulk-action-container">
									<Button
										variant="outlined"
										component="span"
										style={{ backgroundColor: 'purple', color: 'white', marginRight: '10px' }}
										onClick={this.handleAcceptBulkAction}
									>
										Search Similar Request
                  </Button>
									<Button
										variant="contained"
										onClick={this.handleCheckedBulkItem}
									>
										Clear
                  </Button>
								</Box>
								: ''
							}
							{listLoader ?
								<div style={{ textAlign: 'center', padding: '10px' }}>
									<CircularProgress color="secondary" />
								</div>
								:
								<List>
									{
										this.state.dataToDisplay.length ?
											this.state.dataToDisplay.map((item, index) =>
												<ListItem
													className='auto-height-list-item'
													style={{
														backgroundColor: index === currentIndex ? '#e8eaf6' : '',
														height: 'auto !important',
														overflow: 'hidden',
														textOverflow: 'ellipsis'
													}}
													key={index}
													onClick={() => this.showPreview(index)}
												>
													<Radio
														checked={checkedBulkItem.length > 0 ? checkedBulkItem.find((itemId) => itemId.id === item._id ? true : false) : false
														}
														onChange={(e) => this.handleListItemCheckbox(e)}
														onClick={(e) => e.stopPropagation()}
														value={item._id}
														name="search-bulk-seed-data"
														color="primary"
														inputProps={{
															'aria-label': item._id,
														}}
													/>
													<div style={{ marginRight: '10px' }}>
														{item.allImages.length ?
															<img src={item.allImages[0].path} style={{ width: '100px', height: '40px' }} />
															:
															item.videos.length ?
																<ReactPlayer
																	style={{ backgroundColor: '#222' }}
																	url={item.videos[0].path}
																	width='100px'
																	height='40px' />
																:
																<span
																	style={{
																		color: 'red',
																		width: '100px',
																		display: 'inline-block',
																		textAlign: 'center',
																		fontWeight: 'bolder'
																	}}>
																	No Media
          											</span>
														}
													</div>
													<div style={{ flex: 1 }} >
														<div style={{ display: 'block', width: '100%', marginBottom: '5px' }}>
															{item.en.title}
														</div>
														<div style={{ display: 'block', width: '100%' }}>
															{item.hi.title}
														</div>
													</div>
													<div>
														<Moment
															style={{ fontWeight: 'bold', fontSize: '13px' }}
															format='DD MMM YYYY'>
															{item.createdAt}
														</Moment>
														<br />
														<Moment
															style={{ fontWeight: 'bold', fontSize: '13px' }}
															format='hh:mm:ss a'>
															{item.createdAt}
														</Moment>
													</div>
													<div>						
														<div style={{margin: '0px 0px 10px 30px'}}>{this.renderENChip(item)}</div>	
														<div style={{marginLeft: '30px'}}>{this.renderHIChip(item)}</div>
													</div>
												</ListItem>)
											: <p style={{ textAlign: 'center' }}>"No matching seed data found"</p>
									}
								</List>
							}
						</div>
						{preview ?
							<div className={showEdit === true ? "expand" : "preview-wrapper"}>
								<div className="preview">
									<div className="actions-wrapper">
										<div className='action-button-wrapper' style={{ display: 'flex', flex: 1 }}>
											<Button
												color="primary"
												onClick={() => this.setState({ showMergedFC: true })}
												className='fc_hash_count_wrapper' 
												style={{ padding: '4px 6px'}}
												>
												<div className='fc_hash_count_wrapper-div'>
													<div className='fc_hash_count_icon'>
														{
															this.state.loading_Fc_Hash ? <CircularProgress size={13} className='fc_hash_loading' color="secondary" /> :
																<i className="material-icons">call_merge
                              </i>
														}
													</div>
													<div>
														FC-HASH ->
                          </div>
													<div className='fc_hash_count_length'>
														{!this.state.loading_Fc_Hash ?
															this.state.fc_hash.length
															: ' '}
													</div>
												</div>
											</Button>
											<Button color="secondary" onClick={() => this.handleEdit()} className='edit-button-wrapper'>
												<div className='edit-button-wrappr-div' className='edit-button-wrappr-div-text'>
													<i className="material-icons">edit</i>
													Edit
                        </div>
											</Button>
											<Button style={{ position: 'absolute', right: 0 }} color="secondary" onClick={() => this.setState({ preview: false })}>
												<i className="material-icons">cancel</i>
											</Button>
										</div>
									</div>
									<div className="preview-content">
										<h4>
											{this.state.dataToDisplay[currentIndex].en.title}
										</h4>
										<h4>
											{this.state.dataToDisplay[currentIndex].hi.title}
										</h4>
										<h5 style={{ margin: '5px 0', color: '#555', fontWeight: '300' }}>
											created at:
          							<Moment format="DD MMM YYYY">{
												dataToDisplay[currentIndex].createdAt}
											</Moment>
										</h5>
										<h5 style={{ margin: '5px 0', color: '#555', fontWeight: '300' }}>
											updated at:
          							<Moment format="DD MMM YYYY">{
												dataToDisplay[currentIndex].updatedAt}
											</Moment>
										</h5>
										{dataToDisplay[currentIndex].allImages.map((image, index) =>
											<div key={index} className="img-wrapper">
												<img src={image.path} alt='' />
											</div>
										)}
										{
											dataToDisplay[currentIndex].videos.length ?
												<div className="videos">
													<h5>VIDEOS</h5>
													{dataToDisplay[currentIndex].videos.map((video, index) =>
														// <video key={index} width="150px" src={video.path} />
														<ReactPlayer
															key={index}
															url={video.path}
															playing={false}
															controls={true}
															width="550px"
														/>
													)}
												</div> : null
										}
										<p>{dataToDisplay[currentIndex].summary}</p>
										{
											dataToDisplay[currentIndex].en.links.length || data[currentIndex].hi.links.length ?
												<h5>Links</h5> : null
										}
										{dataToDisplay[currentIndex].en.links.map((link, index) =>
											<a
												key={index}
												className="break-word"
												href={link.url}
											>
												{link.url}
											</a>
										)}
										{dataToDisplay[currentIndex].hi.links.map((link, index) =>
											<a
												key={index}
												className="break-word"
												href={link.url}
											>
												{link.url}
											</a>
										)}
										<div>
											<div>
												<h5>
													English:
          								</h5>
												<a href={dataToDisplay[currentIndex].en.article_url} target="_blank">
													{dataToDisplay[currentIndex].en.article_url}
												</a>
											</div>
											<div>
												<h5>
													Hindi:
          								</h5>
												<a href={dataToDisplay[currentIndex].hi.article_url} target="_blank">{dataToDisplay[currentIndex].hi.article_url}</a>
											</div>
										</div>
										<div className='keywords-wrapper'>
											<div className='keywords-wrapper-en'>
												{
													dataToDisplay[currentIndex].en.keywords.map(keyword =>
														<Chip
															avatar={<Avatar>En</Avatar>}
															label={keyword}
															color="secondary"
															variant="outlined"
															key={keyword}
														/>
													)
												}
											</div>
											<div className='keywords-wrapper-hi'>
												{
													dataToDisplay[currentIndex].hi.keywords.map(keyword =>
														<Chip
															avatar={<Avatar>Hi</Avatar>}
															label={keyword}
															color="primary"
															variant="outlined"
															key={keyword}
														/>
													)
												}
											</div>
										</div>
									</div>
								</div>
							</div>
							:
							null
						}

						{showEdit ?
							<div className={showEdit === false ? "preview-wrapper" : "expand"}>
								<div className="preview">
									<div style={{ textAlign: 'right' }}>
										<i
											style={{ cursor: 'pointer' }}
											className="material-icons secondary-color"
											onClick={() => this.handleCancel()}
										>
											cancel
          					</i>
									</div>
									<StepperForm
										marTop="0px"
										onUpdate={this.updateData}
										seedData={data}
										currentData={dataToDisplay[currentIndex]}
									/>
								</div>
							</div>

							:
							null
						}
					</div>
				}
				{currentTab === 2 && <div style={{ marginTop: '40px', padding: '16px', height: '90vh', overflow: 'scroll' }}>
					<div className='seed-data-similar-request-wrapper' style={{ justifyContent: listLoader ? 'center' : 'flex-start' }}>
						{listLoader ?
							<div style={{ textAlign: 'center', padding: '10px' }}>
								<CircularProgress color="secondary" />
							</div>
							: similarRequestBlock.map((item, index) => (
								<div className='blocks' key={index}>
									<div className='card-action-bar'>
										<Button onClick={() => this.showSimilarRequestFcModal(item._id, item.matching_assets)}>Similar Requests: {item.matching_assets ? item.matching_assets.data ? item.matching_assets.data.length : item.matching_assets.length : 0}</Button>
									</div>
									<div className='overlapped'>
										{
											item.fc_id.allImages.length ?
												<img src={item.fc_id.allImages.length > 0 ? item.fc_id.allImages[0].path : noImage} />
												: item.fc_id.videos.length > 0 ? <ReactPlayer url={item.fc_id.videos.length > 0 ? item.fc_id.videos[0].path : ''} width='250px' height='150px' controls={true} /> : <img src={noImage} />
										}
										<p>{item.fc_id.en.title}</p>
									</div>
								</div>
							))}</div>
					<Fab
						className="clear-all-button" color="secondary"
						aria-label="add">
						<Delete onClick={this.handleDeleteAllSimilarRequest} />
					</Fab>
				</div>
				}

				{
					showSimilarRequestFcModal ?
						this.renderSimilarRequestDetailedView() : null
				}

				{
					showMergedFC
						? <MergedFC closeModal={this.hideMergedFC} hash={this.state.fc_hash} />
						: null
				}

				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={openSnackbar}
					autoHideDuration={6000}
					onClose={this.closeSnackbar}
				>
					<SnackbarContent
						className={snackbarClass}
						message={snackbarMessage}
					/>
				</Snackbar>

				{/* {currentTab == 1 ?
  				<div 
  					className='pagination-wrapper'
  					style={{padding:'0px 16px', marginTop: '10px'}}>
  					{
  						this.state.showPager ? 
  							this.renderPager()
  							: null
  					}
  				</div>
  				: null 
  			} */}
			</div>
		);
	}
}

export default SeedData;
