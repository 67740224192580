import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import ReactPaginate from 'react-paginate';
import Api from '../../services/api';
import {
  ListItem,
  List,
  CircularProgress,
  Button
} from '@material-ui/core';
import './style.css';
import Moment from 'react-moment';
import PreviewImage from '../preview-image';
import LazyLoad from 'react-lazyload';

export default class LsdRequestLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      data: [],
      currentData: '',
      pageNum: 1,
      requestCount: 0,
      pageSize: 20,
      showPreview: false,
      loading: false
    };
  }

  componentDidMount() {
    this.loadRequests();
  }

  loadRequests = () => {
    this.setState({ loader: true, data: [] });
    Api.getLsdRequestLog(this.state.pageSize, this.state.pageNum)
      .then(res => res.json())
      .then(res => {
        res.data = res.data.filter((item) => item.media);
        this.setState({
          data: res.data,
          loader: false,
          requestCount: res.numDocs
        });
      });
  }

  showAssetPreview = (item) => {
    this.setState({ currentData: item, showPreview: true });
  }

  hideImageModal = () => {
    this.setState({ showPreview: false });
  }

  changePage = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      pageNum: selectedPage,
      showPreview: false,
    }, () => {
      this.loadRequests();
    });
  }

  renderPager = () => {
    let pages = this.state.requestCount / this.state.pageSize;
    return <ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={this.changePage}
      containerClassName={'pagination'}
      subContainerClassName={'paginate'}
      activeClassName={'active'}
      initialPage={0}
    />;
  }


  render() {
    const { data, requestCount } = this.state;
    return (
      <div>
        {this.state.loader ?
          (<div className="" style={{ textAlign: 'center', marginTop: '70px', height: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </div>)
          :
          <div>
            <div className='requestLog-wrapper'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant='outlined'
                  onClick={() => this.loadRequests()}
                  style={{ color: '#777', marginBottom: '10px' }}>
                  <img
                    style={{
                      width: '25px',
                      height: '25px',
                      cursor: 'pointer',
                      marginRight: '3px',
                    }}
                    src={require('../../assets/icons/reload.png')}
                    alt=''
                  />
                    Refresh
                  </Button>
                <div>
                  <span style={{ verticalAlign: 'sub' }}>Request Count: {requestCount}</span>
                </div>
              </div>
              <div
                className='list-wrapper'>
                <List>
                  {
                    data.length > 0 ?
                      data.map((item, index) =>
                        <div key={index}>
                          {
                            item.fc.length !== 0 ?
                              <LazyLoad overflow={true} placeholder={<CircularProgress />}>
                                <ListItem key={item._id} onClick={() => this.showAssetPreview(item)} >
                                  <div className='item-main-wrapper'>
                                    <div className='item-wrapper'>
                                      <div>
                                        {
                                          item.media.split(/\.(?=[^\.]+$)/)[1] === 'mp4' ?
                                            <ReactPlayer
                                              url={item.media}
                                              playing={false}
                                              controls={false}
                                              width="100px"
                                              height="40px"
                                              style={{ backgroundColor: '#111' }} />
                                            :
                                            <img
                                              src={item.media}
                                              alt=''
                                              className="image_in_list"
                                            />
                                        }
                                      </div>
                                      <div
                                        className="article-wrapper"
                                      >
                                        <div>
                                          <h5 key={item._id}>
                                            {item.fc[0].article_url}
                                          </h5>
                                        </div>
                                        <div>
                                          <h6 key={item._id}>
                                            {item.fc[0].title}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="article-wrapper meta-deta">
                                        <div>
                                          <h5>
                                            {item.key}
                                          </h5>
                                        </div>
                                        <div>
                                          <h6>
                                            <Moment format="DD MMM YYYY">{item.createdAt}</Moment>
                                          </h6>
                                          <h6>
                                            <Moment format="h:mm:ss a">{item.createdAt}</Moment>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ListItem>
                              </LazyLoad>
                              : null
                          }
                        </div>
                      ) :
                      <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex' }}>
                        <h2 className='empty-list'>
                          <i className='material-icons'>error</i>
                              No Lite Seed Data Logs Found
                            </h2>
                      </div>
                  }
                </List>
              </div>
            </div>
          </div>
        }
        <div
          className='pagination-wrapper'
          style={{ padding: '0 20px' }}>
          {
            this.renderPager()
          }
        </div>
        <PreviewImage
          imgSrc={this.state.currentData.media}
          showImageModal={this.state.showPreview}
          hideImageModal={this.hideImageModal} />
      </div>
    );
  }
}
