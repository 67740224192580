import React, { Component } from 'react';
import {
	TextField,
	Chip,
	List,
	ListItem,
	Button,
	CircularProgress
} from '@material-ui/core';
import './style.css';
import Api from '../../services/api';
import SeedDataTemplate from '../../templates/seed-data-template';
import SuggestedFactchecks from '../suggested-factchecks';


class SelectedReqBulkMerge extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keywords: [],
			suggestedFactChecks: [],
			preferredLanguage: [],
			preview: false,
			currentData: [],
			noFcFound: false,
			currentIndex: '',
			fetchingFC: false,
			merging: false
		};

	}

	componentDidMount = () => {
		let language = this.state.preferredLanguage;
		this.props.dataToCheck.map((item) => {
			language.push(item.preferredLanguage);
		})

		this.setState({ preferredLanguage : language });
	}

	handleKeywords = (e) => {
		let value = e.target.value;
		let keyCode = e.keyCode;

		if (keyCode === 13 && value != '') {
			let keywords = [...this.state.keywords];
			keywords.push(value);
			this.setState({ keywords }, () => {
				this.fetchFactChecks();
			});
			e.target.value = '';
		}
	}

	fetchFactChecks = () => {
		let keywords = this.state.keywords;
		// let language = `${this.state.preferredLanguage}.keywords`;
		this.setState({ fetchingFC: true });
		Api.getFactchecksByKeywords(keywords, false, true).then(res => {
			if (!res.data.length) {
				this.setState({ noFcFound: true });
			} else {

				this.setState({ suggestedFactChecks: res.data, noFcFound: false });
			}
			this.setState({ fetchingFC: false });
		});
	}

	handleDelete = (index) => {
		let reset = {
			keywords: [],
			suggestedFactChecks: [],
			currentData: [],
			preview: false,
			noFcFound: false
		};
		let keywords = [...this.state.keywords];
		keywords = keywords.filter((keyword, indx) =>
			index != indx
		);
		if (keywords.length) {
			let currentData = SuggestedFactchecks[0];
			let currentIndex = 0;
			this.setState({ keywords, preview: false }, () => {
				this.fetchFactChecks();
			});
		} else {
			this.setState(reset);
		}
	}

	showPreview = (index) => {
		let currentData = this.state.suggestedFactChecks[index];
		let preview = true;
		let currentIndex = index;
		this.setState({ currentData, preview, currentIndex });
		this.props.updateCurrentArticle(this.state.suggestedFactChecks[index]);
	}

	mergeWithFC = () => {
		this.setState({ merging: true });
		let { dataToCheck } = this.props;
		let factCheck = this.state.currentData;
			Api.bulkMergeWithFC(factCheck, dataToCheck).then(res => {
				this.props.mergeStatus(res.success);
				this.props.closeModal();
				this.setState({ merging: false });
				window.location.reload(false);
			});
	
	}

	renderENChip = (item) =>{
		if(item.en.title.length > 0){
			return(
			<Chip
				label="EN"
				color="primary"
			/>)
		}
	}

	renderHIChip = (item) =>{
		if(item.hi.title.length > 0) {
		return(
		<Chip
			label="HI"
			color="secondary"
		/>
		)
		}
	}

	render() {
		const { isBulk } = this.props;
		const {
			suggestedFactChecks,
			preview,
			currentData,
			keywords,
			noFcFound,
			currentIndex,
			fetchingFC
		} = this.state;
		const maxHeight = window.innerHeight - 150 + 'px';
		return (
			<div className="fc-by-keywords-wrapper" style={{ maxHeight }}>
				<div className="search-by-keywords">
					<TextField
						style={{ width: '70%', margin: '0 auto' }}
						id="search-key"
						label="Enter Keywords"
						onKeyUp={(e) => this.handleKeywords(e)}
						margin="normal"
						variant="outlined"
						placeholder="Enter keywords and press Return"
					/>
					<div className="active-keywords">
						{
							keywords.map((keyword, index) =>
								<Chip
									style={{ margin: '3px' }}
									key={index}
									label={keyword}
									onDelete={() => this.handleDelete(index)}
									variant="outlined"
								/>
							)
						}
					</div>
					{
						fetchingFC ? <CircularProgress /> : null
					}
				</div>
				<div className="flex-keywords-wrapper">
					{
						suggestedFactChecks.length ?
							(
								<div className="list-by-keywords-wrapper">
									<List>
										{suggestedFactChecks.map((item, index) =>
											<ListItem
												key={index}
												style={{ backgroundColor: currentIndex === index ? '#e8eaf6' : ''
												,display: 'flex', justifyContent:'space-between'
											}}
												onClick={() => this.showPreview(index)}
											>						
											<div style={{display : 'flex', flexDirection:'column'}}>						
													<div className="chip-wrapper">
														<div>{item.en.title}</div>
														<div style={{marginLeft: '30px'}}>{this.renderENChip(item)}</div>
													</div>
													<div className="chip-wrapper">	
														<div>{item.hi.title}</div>
														<div style={{marginLeft: '30px'}}>{this.renderHIChip(item)}</div>
													</div>
												</div>
												<div>
												{
													item.allImages.length ?
														(<i className="material-icons primary-color">
															image
														</i>)
														: null
												}
												{
													item.videos.length ?
														(<i className="material-icons primary-color">
															videocam
														</i>)
														: null
												}
												</div>
											</ListItem>
										)}
									</List>
								</div>
							) :
							<div style={{ textAlign: 'center', flex: 1 }} >
								{noFcFound ? <p>No Fact Checks Found</p> : null}
							</div>
					}
					{
						preview ? (
							<div className="keywords-fc-preview-wrapper" >
								<div className="keywords-fc-actions-wrapper">
									{
										this.state.preferredLanguage.map(item=>{
											if(currentData[item].article_url === ''){
												return(
													<span className='error-mssg'>Cannot Merge: Language Mismatch</span>
												)
											}
										})
										
									}
									{isBulk ? <Button
										variant="contained"
										color="primary"
										onClick={() => this.props.openDialog(true)}
										// disabled={this.state.merging ^ (currentData[preferredLanguage].article_url == '' ? true : false)}
									>
										Bulk Merge
									</Button>
										: ''}
									{!isBulk ? <Button
										variant="contained"
										color="primary"
										onClick={() => this.mergeWithFC()}
										// disabled={this.state.merging ^ (currentData[preferredLanguage].article_url == '' ? true : false)}
									>
										{this.state.merging ? 'Merging' : 'Merge'}
										{this.state.merging ? <CircularProgress /> : ''}
									</Button> : ''}
									<Button
										color="secondary"
										onClick={() => this.setState({ preview: false })}
									>
										<i className="material-icons">cancel</i>
									</Button>
								</div>
								<div>

									<SeedDataTemplate dataToPreview={currentData} />
								</div>
							</div>
						) : null
					}
				</div>
			</div>
		);
	}
}

export default SelectedReqBulkMerge;
