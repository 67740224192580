import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Login from './components/login';
import SeedData from './components/seed-data';
import ManageRequests from './components/manage-requests';
import Sidebar from './components/sidebar';
import Header  from './components/header';
import AppSettings from './components/app-settings';
import Dashboard from './components/dashboard';
import UserManagement from './components/user-management';
import RolesPermission from './services/roles-permission';
import Api from './services/api';
import CookieManager from './services/cookie-manager';
import RequestLog  from './components/request-log';
import DonateNotification from './components/donate-notification';
import Workbench from './components/workbench';
import SimilarRequests from './components/similar-requests';
import Notifications from './components/notifications';
import LSDRequestLog from './components/lsd-request-log';
import {register, unregister} from './serviceWorker';
import LiteSeedData from './components/LiteSeedData';

class App extends Component {
	constructor(props) {
    super(props); 
    let shrinkMain = CookieManager.getCookie('shrinkSidebar') === null ? true : CookieManager.getCookie('shrinkSidebar');
    shrinkMain = shrinkMain === 'true';
		this.state = {
			shrinkMain: shrinkMain,
			pageLoaded: window.location.pathname
		};
	}
	componentDidMount() {
    register();
    this.verifyToken();
    
  }

  shrinkExpandMain = () => {
  	let shrinkMain = this.state.shrinkMain ? false : true;
  	this.setState({ shrinkMain });
  }

  verifyToken = async () => {
    let jwt = RolesPermission.getToken();
    try {
      if ( jwt ) {
          let tokenVerified = await Api.verifyJwtToken(jwt);
          if ( typeof tokenVerified.data !== 'undefined') {
              if ( !tokenVerified.data.success ) {
                CookieManager.deleteCookie('_data');
              } 
          }

      } else {
        CookieManager.deleteCookie('_data');
      }
    } catch (err) {
    }
  }

  render() {
  	let {shrinkMain} = this.state;
  	let shrinkStyle = {
  		marginLeft:'80px',
  		transition: 'all 0.3s ease-out',

  	};
  	let expandStyle = {
  		marginLeft:'185px',
  		transition: 'all 0.3s ease-out'
  	};
  	return (
  		<Router>
  			{this.state.pageLoaded === '/login'
  				?
  				<Route path="/login/" component={Login} /> 
  				:
  				<div className="container">
  					<Sidebar toggleMain={this.shrinkExpandMain} />
  					<div className="main" style={shrinkMain ? shrinkStyle:expandStyle}>
  						{
  							RolesPermission.isAdmin() === 'ADMIN' ?
  								<div className="wrapper">
  									<Route path="/manage-requests" exact component={ManageRequests} />
  									<Route path="/seed-data/" component={SeedData} />
									  <Route path="/lite-seed-data/" component={LiteSeedData} />
  									<Route path="/settings/" component={AppSettings} />
  									<Route path="/dashboard/" component={Dashboard} />
  									<Route path="/users/" component={UserManagement} />
  									<Route path="/request-log/" component={RequestLog} />
										<Route path="/donate-notification/" 
										component={DonateNotification}/>
										<Route path="/workbench" component={Workbench}/>
										<Route path="/similar-requests" component={SimilarRequests}/>
										<Route path="/notifications" component={Notifications}/>
										<Route path="/lsd-request-log" component={LSDRequestLog}/>
  								</div>
  								: 
  								<div className="wrapper">
  									<Header 
  										style={{width: '100%'}} 
  										leftPos={shrinkMain?'80px':'200px'} 
  										flexG={shrinkMain? '0.926':'0.8'}
  									/>

  									<Route path="/seed-data/" component={SeedData} />
  								</div>
  						}
              
  					</div>
  				</div>
  			}
          
  		</Router>
  	);
  }
}


App.propTypes = {
}

export default App;
