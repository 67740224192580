import React, { Component, lazy, Suspense } from 'react';

import {
  ListItem,
  List,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Checkbox,
  FormControlLabel,
  Switch,
  Snackbar,
  Box,
  Menu,
  MenuItem,
  SnackbarContent,
  InputLabel,
  FormControl,
  Select
} from '@material-ui/core';

import Moment from 'react-moment';
import PreviewImage from '../preview-image';
import ReactPlayer from 'react-player';
import Api from '../../services/api';
import ReactPaginate from 'react-paginate';
import LazyLoad from 'react-lazyload';
// import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import PropTypes from 'prop-types';

import constants from '../../services/constants';
import './style.css';

const moment = require('moment');

const InfiniteCalendar = lazy(() => import('react-infinite-calendar'));

class Workbench extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 1,
      data: [],
      showPreview: false,
      currentIndex: '',
      customMessageWrapper: false,
      selectedImage: {},
      showImageModal: false,
      activeRequest: '',
      loader: false,
      rejectionTemplate: [],
      requestCount: 0,
      rejectionReason: '',
      pageNum: 1,
      pageSize: 20,
      numOfRequest: 0,
      dataToDisplay: [],
      overlayLoader: false,
      tempData: [],
      offset: 200,
      filterBy: 'none',
      showCalendar: false,
      openSnackbar: false,
      snackbarMessage: '',
      snackbarClass: '',
      showCalendarFor: '',
      showFilters: false,
      checkedBulkItem: [],
      filters: {
        date: {
          status: false,
          fromDate: '',
          toDate: ''
        },
        lang: {
          status: false,
          en: false,
          hi: false
        },
        sort: {
          status: false,
        },
        searchKeyword: '',
        bookmarked: true,
      },
      sortAnchorEl: 1,
    };
  }

  hideImageModal = () => {
    this.setState({ showImageModal: false });
  }

  handleBookMarkIcon = (item, e) => {
    e.stopPropagation();
    const bookmarkStatus = item[0].bookmarked === true ? 0 : 1;
    Api.setRequestBookmark(item[0]._id, bookmarkStatus).then(res => {
      return res;
    }).then(data => {
      if (data.success) this.applyFilters(true)
    });
  }

  componentDidMount() {
    this.applyFilters();
    Api.getAppSettings()
      .then(res => {
        return res.json();
      }).then(res => {
        if (res.data.length) {
          let rejectionTemplate = res.data[0].rejectionTemplate;
          this.setState({ rejectionTemplate });
        }
      });
    if (!('IntersectionObserver' in window) &&
      !('IntersectionObserverEntry' in window) &&
      !('intersectionRatio' in window)) {
      // load polyfill for lazyload video
      alert('Upgrade your browser or switch to google chrome.');
    } else {
    }
  }

  lazy = async () => {

    // document.addEventListener('DOMContentLoaded', function() {
    let lazyLoadVidObserver = new IntersectionObserver((entries, obvserver) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {

          let vidElement = entry.target;
          let source = entry.target.getAttribute('data-src');

          vidElement.childNodes[0].src = source;
          vidElement.classList.remove('lazy-vid');
          lazyLoadVidObserver.unobserve(vidElement);
        }
      });
    });

    var lazyVids = await document.getElementsByClassName('lazy-vid');
    let counter = 0;
    while (counter !== lazyVids.length) {
      let item = lazyVids.item(counter);
      counter++;
      lazyLoadVidObserver.observe(item);
    }
    // });
  }

  lazyloadVideo = (url, index) => {
    return (
      <ReactPlayer
        style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
        key={index}
        // url={url} 
        playing={false}
        controls={true}
        width='400px'
        height='250px' />
    );
  }

  showDetails = (index) => {
    this.lazy();

    this.setState({ overlayLoader: true, showPreview: false });
    let currentData = this.state.dataToDisplay[index];
    let showPreview = true;
    let customMessageWrapper = [];
    customMessageWrapper = Array(currentData.length).fill(false);
    this.setState({
      currentData,
      customMessageWrapper,
      currentIndex: index,
      activeRequest: index
    }, () => this.setState({ overlayLoader: false, showPreview }));
  }

  toggleCustomMessageWrapper = (index) => {
    let customMessageWrapper = [...this.state.customMessageWrapper];
    customMessageWrapper[index] = customMessageWrapper[index] ? false : true;
    this.setState({ customMessageWrapper });
  }

  acceptRequest = (item, index) => {

    this.setState({ overlayLoader: true });
    Api.acceptRequest(item._id)
      .then(response => {
        return response.json();
      }).then(res => {
        if (res.success) {
          let currentIndex = this.state.currentIndex;
          let allData = [...this.state.dataToDisplay];
          let filterArr = allData[currentIndex].filter(data => data._id !== item._id);
          allData[currentIndex] = filterArr;
          // if grouped array contains empty after deleting elements from above section
          //  pop that array from this.state.data
          if (!allData[currentIndex].length) {
            this.setState({ showPreview: false });
            allData = allData.filter(data => data.length !== 0);
          }


          this.setState({ dataToDisplay: allData, overlayLoader: false });
        }
      });
  }

  rejectRequest = (item, rejectionReason, index) => {
    this.setState({ overlayLoader: true });
    Api.rejectRequest(item._id, rejectionReason)
      .then(response => {
        return response.json();
      }).then(res => {
        if (res.success) {
          let currentIndex = this.state.currentIndex;
          let allData = [...this.state.dataToDisplay];
          let filterArr = allData[currentIndex].filter(data => data._id !== item._id);
          allData[currentIndex] = filterArr;
          // if grouped array contains empty after deleting elements from above section
          //  pop that array from this.state.data
          if (!allData[currentIndex].length) {
            this.setState({ showPreview: false });
            allData = allData.filter(data => data.length !== 0);
          }
          this.setState({ dataToDisplay: allData, overlayLoader: false });
          this.setState({ customMessageWrapper: false });
        }
      });
  }

  renderPager = () => {

    let pages = this.state.numOfRequest / this.state.pageSize;
    return <ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={this.changePage}
      containerClassName={'pagination'}
      subContainerClassName={'paginate'}
      activeClassName={'active'}
      initialPage={0}
    />;
  }

  changePage = (e) => {

    let selectedPage = e.selected + 1;
    this.setState({
      pageNum: selectedPage,
      // dataToDisplay: this.state.data.slice(firstPage, lastPage),
      showPreview: false
    }, () => {
      this.applyFilters()
      this.handleCheckedBulkItem()
    });

  }

  searchManageRequests = (e) => {
    let { filters } = this.state;
    this.setState({ showPreview: false });
    if (filters.searchKeyword.length >= 1 && e.nativeEvent.key === 'Enter') {
      this.applyFilters();
    }
    if (filters.searchKeyword.length === 0) {
      this.clearSearch();
    }
  }

  clearSearch = () => {
    this.applyFilters();
  }

  onSearchKeywordChange = (e) => {
    let searchKeyword = e.target.value.toLowerCase();
    let { filters } = this.state;
    filters.searchKeyword = searchKeyword;
    this.setState({ filters });
  }

  handleDateFilter = () => {
    let { filters } = this.state;
    filters.date.status = !filters.date.status;
    this.setState({ filters });
  }

  applyFilters = async (asyncLoad = false) => {

    let { filters, pageSize, pageNum } = this.state;
    // filter validations
    let fromDate = moment(filters.date.fromDate);
    let toDate = moment(filters.date.toDate);
    let numDays = fromDate.diff(toDate, 'days');
    let validation = 0;

    if (filters.date.status) {
      if (numDays >= 0) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : End date should be greater than start date',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
      if (isNaN(numDays)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : Choose Dates',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
    }
    if (filters.lang.status) {
      if (!(filters.lang.en || filters.lang.hi)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : Atleast choose one language',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
    }

    if (validation === 0) {

      if (!asyncLoad)
        this.setState({ dataToDisplay: [] });
      // status = Pending or accepted

      let res = await Api.getFilteredRequests(constants.PENDING_REQUESTS, filters, pageSize, pageNum);
      let data = res.data;
      if (res.data.length) {
        let groupedArray = [];
        for (let i = 0; i < data.length; i++) {
          let tempArr = [];
          tempArr.push(data[i]);
          groupedArray.push(tempArr);
        }

        this.setState({
          data: groupedArray,
          tempData: groupedArray,
          numOfRequest: res.numRecords,
          dataToDisplay: groupedArray.slice(0, this.state.pageSize),
          showPreview: false,
          requestCount: res.numRecords
        });
      } else {
        this.setState({
          data: [],
          tempData: [],
          numOfRequest: res.numRecords,
          dataToDisplay: [],
          showPreview: false,
          requestCount: res.numRecords,
          openSnackbar: true,
          snackbarMessage: 'No bookmarked requests',
          loader: false,
          snackbarClass: 'danger-bg-color'
        });
      }
    }
  }

  clearFilters = () => {
    let filters = {
      date: {
        status: false,
        fromDate: '',
        toDate: ''
      },
      lang: {
        status: false,
        en: false,
        hi: false
      },
      sort: {
        status: false,
      },
      searchKeyword: '',
      bookmarked: true
    };
    let pageNum = 1;
    this.setState({
      filters,
      sortAnchorEl: 1,
      pageNum
    }, () => this.applyFilters());
  }

  handleLangFilter = () => {
    let { filters } = this.state;
    filters.lang.status = !filters.lang.status;
    this.setState({ filters });
  }

  handleSortFilter = () => {
    const { filters } = this.state;
    const sortfilter = { ...filters.sort };
    sortfilter.status = !filters.sort.status;
    this.setState({ filters: { ...this.state.filters, sort: sortfilter } })
  }

  updateSortByFilterStatus = (e) => {
    this.setState({
      sortAnchorEl: !Number(e.target.value) ? Number(e.target.value) : 1,
      filters: {
        ...this.state.filters,
        sortByCreatedAt: !Number(e.target.value) ? true : false
      },
    })
  }



  handleFilterCheckbox = (e) => {

    let lang = e.target.value;
    let { filters } = this.state;
    filters.lang[lang] = !filters.lang[lang];
    this.setState({ filters });
  }

  renderFilters = () => {

    const { filters, sortAnchorEl } = this.state;
    return (
      <div className='filter-wrapper'>
        <div className='date-filter'>
          <div>
            <FormControlLabel
              control={<Switch
                checked={filters.date.status}
                onChange={this.handleDateFilter}
                value="checkedA"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Date'
            />
          </div>
          <div>
            <input
              disabled={!filters.date.status}
              style={{ cursor: filters.date.status ? 'default' : 'not-allowed' }}
              type='text'
              onChange={() => console.log('')}
              onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'fromDate' })}
              placeholder='from'
              value={filters.date.fromDate !== '' ? moment(filters.date.fromDate).format('DD/MM/YYYY') : ''}
            />
          </div>
          <div>
            <input
              disabled={!filters.date.status}
              style={{ cursor: filters.date.status ? 'default' : 'not-allowed' }}
              type='text'
              onChange={() => console.log('')}
              onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'toDate' })}
              placeholder='to'
              value={filters.date.toDate !== '' ? moment(filters.date.toDate).format('DD/MM/YYYY') : ''}
            />
          </div>
        </div>
        <div className='lang-filter'>
          <div>
            <FormControlLabel
              control={<Switch
                checked={filters.lang.status}
                onChange={this.handleLangFilter}
                value="checkedA"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Language'
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox
                disabled={!this.state.filters.lang.status}
                color='primary'
                checked={this.state.filters.lang.en}
                onChange={this.handleFilterCheckbox}
                value='en'
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='English'
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox
                disabled={!this.state.filters.lang.status}
                color='primary'
                checked={this.state.filters.lang.hi}
                onChange={this.handleFilterCheckbox}
                value='hi'
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Hindi'
            />
          </div>
        </div>
        <div className="sort-filter">
          <FormControlLabel
            control={<Switch
              checked={filters.sort.status}
              onChange={this.handleSortFilter}
              value="checkedA"
              inputProps={{
                'aria-label': 'sort checkbox',
              }}
            />}
            label='Sort By'
          />
          <FormControlLabel
            control={
              <Box>
                <FormControl variant="outlined" style={{ minWidth: '120px' }}>
                  <InputLabel htmlFor="outlined-sort-native-simple">
                    Sort By
                  </InputLabel>
                  <Select
                    native
                    disabled={!filters.sort.status}
                    value={sortAnchorEl}
                    onChange={(e) => this.updateSortByFilterStatus(e)}
                    inputProps={{
                      name: 'sort',
                      id: 'outlined-sort-native-simple',
                    }}
                  >
                    <option value={1}>Updated At</option>
                    <option value={0}>Created At</option>
                  </Select>
                </FormControl>
              </Box>
            }
          />
        </div>
        <div>
          <Button
            disabled={!(this.state.filters.date.status || this.state.filters.lang.status || this.state.filters.sort.status)}
            style={{ marginRight: '10px' }}
            onClick={this.clearFilters}
            variant='contained'
            color='default'>
            Clear All
        </Button>
          <Button
            disabled={!(this.state.filters.date.status || this.state.filters.lang.status || this.state.filters.sort.status)}
            onClick={this.applyFilters}
            variant='contained'
            color='primary'>
            Apply
        </Button>
        </div>
      </div>
    );
  }

  hideCalendar = (e) => {

    if (e.target.className === 'calendar-wrapper') {
      this.setState({ showCalendar: false });
    }
  };

  renderCalendar = () => {
    let { showCalendarFor } = this.state;
    return (
      <div className='calendar-wrapper' onClick={(e) => this.hideCalendar(e)}>
        <Suspense fallback={<p>Loading Calendar...</p>}>
          <InfiniteCalendar
            selected={false}
            onSelect={(e) => { this.dateSelected(e, showCalendarFor); }}
          />
        </Suspense>
      </div>
    );
  }

  dateSelected = (date, showCalendarFor) => {

    const { filters } = this.state;
    date = new Date(date).toISOString();

    if (showCalendarFor === 'fromDate') {

      filters.date.fromDate = date;
      this.setState({ filters, showCalendar: true });
    } else if (showCalendarFor === 'toDate') {

      this.setState({ toDate: date, showCalendar: true });
      filters.date.toDate = date;
      this.setState({ filters, showCalendar: true });
    } else {
      // nothing

    }
  }

  handleListItemCheckbox = (e) => {
    e.stopPropagation();
    const _id = e.target.getAttribute('aria-label');
    const data = this.state.checkedBulkItem.find(item => item.id === _id)
    if (data !== undefined) {
      const id = data.hasOwnProperty('id') ? data.id : undefined
      const newData = this.state.checkedBulkItem.filter(item => item.id !== id)
      this.setState({
        checkedBulkItem: newData
      })
    } else {
      this.setState({
        checkedBulkItem: [
          ...this.state.checkedBulkItem,
          { id: _id, status: true }
        ]
      })
    }
  }

  handleAcceptBulkAction = () => {
    Api.sendAcceptBulkAction(this.state.checkedBulkItem, constants.ACCEPTED_REQUESTS).then(response => {
      return response.json();
    }).then(res => {
      if (res.success) {
        this.handleCheckedBulkItem()
        this.applyFilters(true);
      }
      else{

      }
    }).catch(err => {
    });
  }

  handleRejectBulkAction = (str = '') => {
    Api.sendRejectBulkAction(this.state.checkedBulkItem, str).then(response => {
      return response.json();
    }).then(res => {
      if (res.success) {
        this.handleCheckedBulkItem()
        this.applyFilters(true);
      }
      else{

      }    
    }).catch(err => {
    });
  }

  handleRejectMenuClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleRejectMenuClose = (e) => {
    this.setState({
      anchorEl: null
    })
    if (e.textContent) {
      const string = this.state.rejectionTemplate.filter(item => {
        return item.body.includes(e.textContent)
      })
      this.handleRejectBulkAction(string[0])
    }
  };

  handleCheckedBulkItem = () => {
    this.setState({
      checkedBulkItem: []
    })
  }

  render() {
    let {
      currentTab,
      currentIndex,
      activeRequest,
      loader,
      rejectionTemplate,
      showFilters,
      checkedBulkItem
    } = this.state;
    return (
      <div className="workbench-container" style={{ marginBottom: '150px' }}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnackbar}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSnackbar: false })}>
          <SnackbarContent
            className={this.state.snackbarClass}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className=''>
                {this.state.snackbarMessage}
              </span>
            }
          />
        </Snackbar>

        {
          loader ?
            <div className="" style={{ textAlign: 'center', marginTop: '70px' }}>
              <CircularProgress color="secondary" />
            </div>
            :
            <div>
              {
                <div className="tab-content">
                  <div className="requestCount">
                    <div
                      className={showFilters ? 'show-filters active' : 'show-filters'}
                      onClick={() => this.setState({ showFilters: !showFilters })}>
                      <span>
                        <i style={{ verticalAlign: 'middle' }} className='material-icons'>filter_list</i>
                        <span style={{ verticalAlign: 'middle' }} >Filters </span>
                      </span>
                    </div>
                    <Button
                      variant='outlined'
                      onClick={() => this.clearFilters()}
                      style={{ color: '#777', marginRight: '10px' }}>
                      <img
                        style={{
                          width: '25px',
                          height: '25px',
                          cursor: 'pointer',
                          marginRight: '3px'
                        }}
                        src={require('../../assets/icons/reload.png')}
                        alt=''
                      />
                      Refresh
  									</Button>
                    <div className='custom-input-field-wrapper'>
                      <input
                        onChange={this.onSearchKeywordChange}
                        value={this.state.filters.searchKeyword}
                        onKeyUp={(e) => this.searchManageRequests(e)}
                        className='custom-input-field'
                        placeholder='Type keyword & press return to search'
                      />
                      {
                        this.state.filters.searchKeyword ?
                          <Button
                            variant="contained"
                            onClick={this.clearSearchField}
                            style={{ height: '45px', marginLeft: '10px', alignSelf: 'center' }}
                            onClick={this.clearSearch}>clear</Button>
                          : null
                      }
                    </div>

                    <div style={{ marginLeft: 'auto' }}>
                      Requests: {this.state.requestCount}
                    </div>
                    {
                      showFilters ?
                        this.renderFilters()
                        :
                        null
                    }

                  </div>

                  <div className="flex-wrapper">
                    <div className="wb-list-wrapper">
                      {checkedBulkItem.length > 0 ?
                        <Box className="bulk-action-container">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleAcceptBulkAction}
                          >
                            Accept
                          </Button>
                          <Box>
                            <Button aria-controls="simple-menu" aria-haspopup="true"
                              variant="contained"
                              color="secondary"
                              onClick={this.handleRejectMenuClick}>
                              Reject
                          </Button>
                            <Menu
                              id="reject-button-menu"
                              anchorEl={this.state.anchorEl}
                              keepMounted
                              open={Boolean(this.state.anchorEl)}
                              onClose={this.handleRejectMenuClose}
                            >
                              {rejectionTemplate.map((item, index) => <MenuItem key={index} onClick={(e) => this.handleRejectMenuClose(e.target)}>{item.body}</MenuItem>)}
                            </Menu>
                          </Box>
                          <Button
                            variant="contained"
                            onClick={this.handleCheckedBulkItem}
                          >
                            Clear
                          </Button>
                        </Box>
                        : ''
                      }
                      {
                        this.state.dataToDisplay.length ?
                          <List>
                            {this.state.dataToDisplay.map((item, index) =>
                              <div
                                key={index}
                                className="item-wrapper"
                                style={{ backgroundColor: activeRequest === index ? '#e8eaf6' : '' }}
                              >
                                <LazyLoad
                                  scroll={true}
                                  placeholder={<CircularProgress />}
                                  scrollContainer='.wb-list-wrapper'
                                >
                                  <ListItem className={`list--${index}`} style={{ paddingLeft: '0px' }} onClick={() => this.showDetails(index)}>
                                    <Checkbox
                                      checked={checkedBulkItem.length > 0 ? checkedBulkItem.find((itemId) => itemId.id === item[0]._id ? true : false) : false
                                      }
                                      onChange={(e) => this.handleListItemCheckbox(e)}
                                      onClick={(e) => e.stopPropagation()}
                                      value={`checkedItem${index}`}
                                      color="primary"
                                      inputProps={{
                                        'aria-label': item[0]._id,
                                      }}
                                    />
                                    <div>
                                      {
                                        item[0].images.length ?
                                          (<img
                                            src={item[0].images[0].path}
                                            alt=''
                                            className="image_in_list"
                                          />)
                                          :
                                          <ReactPlayer
                                            style={{ display: 'inline-block', backgroundColor: '#222' }}
                                            url={item[0].videos[0].path}
                                            playing={false}
                                            width='100px'
                                            height='40px' />
                                      }
                                    </div>
                                    <div className="title">
                                      <div style={{ marginLeft: '10px', display: 'inline-block' }}>
                                        {
                                          item[0].description.length ?
                                            <span>
                                              {item[0].description.substr(0, 50)}
                                            </span>
                                            :
                                            <span
                                              style={{
                                                color: '#f44336',
                                                fontStyle: 'italic',
                                                verticalAlign: 'middle'
                                              }}>
                                              This request has no text!
                                        </span>
                                        }
                                      </div>
                                    </div>
                                    <IconButton className="bookmark-button" color="primary" onClick={(e) => this.handleBookMarkIcon(item, e)} aria-label="add to bookmark">
                                      <div className={`icon--${item[0].bookmarked ? 'bookmarked' : 'bookmark'}`} />
                                    </IconButton>
                                    <div className="badges-wrapper">
                                      <span>
                                        <Moment format="DD MMM YYYY" >{item[0].createdAt}</Moment>
                                        <br />
                                        <Moment format="hh:mm:ss a" >{item[0].createdAt}</Moment>
                                        <br />
                                        <span style={{ fontWeight: 'normal' }}>{item[0].key} </span>
                                      </span>
                                    </div>
                                  </ListItem>
                                </LazyLoad>
                              </div>
                            )}
                          </List>
                          :
                          <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex' }}>
                            <h2 className='empty-list'>
                              <i className='material-icons'>error</i>
                              No bookmarked Requests
                            </h2>
                          </div>
                      }
                    </div>
                    {this.state.showPreview ?
                      <div className="preview-wrapper"
                        style={{
                          height: '583px',
                          border: '1px solid #efefef'
                        }}>

                        {
                          this.state.overlayLoader ?
                            <div className='overlay-loader'>
                              <CircularProgress color='secondary' />
                            </div>
                            : null
                        }
                        {
                          this.state.dataToDisplay.length ?
                            this.state.dataToDisplay[currentIndex].map((item, index) => {
                              return (
                                <div className="preview" key={index}>
                                  <div className="actions-wrapper">
                                    <Chip
                                      label={item.preferredLanguage === 'en' ? 'ENGLISH' : 'HINDI'}
                                      color="primary"
                                      style={{ float: 'left' }} />
                                    <Button
                                      color="primary"
                                      onClick={() => this.acceptRequest(item, index)}
                                    >
                                      <i className="material-icons">check</i>Accept
  															</Button>
                                    <Button
                                      color="secondary"
                                      onClick={(e) => this.toggleCustomMessageWrapper(index, e)}
                                    >
                                      <i className="material-icons">close</i>Reject
  															</Button>
                                    <Button onClick={() => this.setState({ showPreview: false })}>
                                      <i className="material-icons red-text">cancel</i>
                                    </Button>
                                  </div>
                                  {
                                    item.preferredLanguage === 'en'
                                      ?
                                      this.state.customMessageWrapper[index] ?
                                        (<div className="custom-message-wrapper">
                                          {rejectionTemplate.map((tempalte, idx) =>
                                            <div key={idx}>
                                              <input
                                                id={index + '-' + idx}
                                                type="radio"
                                                name="rejection-reason"
                                                onClick={() => this.setState({ rejectionReason: tempalte.body })}
                                              />
                                              <label htmlFor={index + '-' + idx}>{tempalte.body}</label>
                                            </div>
                                          )}
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                              () =>
                                                this.rejectRequest(item, this.state.rejectionReason, index)
                                            }
                                          >
                                            Submit
                                    </Button>
                                        </div>)
                                        : null
                                      :
                                      this.state.customMessageWrapper[index] ?
                                        (<div className="custom-message-wrapper">
                                          {rejectionTemplate.map((tempalte, idx) =>
                                            <div key={idx}>
                                              <input
                                                id={index + '-' + idx}
                                                type="radio"
                                                name="rejection-reason"
                                                onClick={() => this.setState({ rejectionReason: tempalte.bodyHi })}
                                              />
                                              <label htmlFor={index + '-' + idx}>{tempalte.bodyHi}</label>
                                            </div>
                                          )}
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                              () =>
                                                this.rejectRequest(item, this.state.rejectionReason, index)
                                            }
                                          >
                                            Submit
                                    </Button>
                                        </div>)
                                        : null
                                  }

                                  <h3 style={{ fontWeight: '300', color: '#333', wordBreak: 'break-all' }}>{item.description}</h3>
                                  <h4 style={{ fontWeight: '300', color: '#555', marginTop: '5px' }}>
                                    <Moment format="DD MMM YYYY">{item.createdAt}</Moment>
                                  </h4>
                                  <h4 style={{ fontWeight: '300', color: '#555', marginTop: '5px' }}>
                                    <Moment format="h:mm:ss a">{item.createdAt}</Moment>
                                  </h4>
                                  {item.images.length ?
                                    item.images.map((img, index) =>
                                      <div
                                        key={index}
                                        className="img-wrapper pointer"
                                        onClick={() => this.setState({ selectedImage: img.path, showImageModal: true })}
                                      >
                                        <img
                                          src={img.path}
                                          alt='' />
                                      </div>
                                    )
                                    : null}

                                  {item.videos.length ?
                                    item.videos.map((vid, index) =>
                                      <div
                                        id={vid._id}
                                        data-src={vid.path}
                                        key={index}
                                        // className='lazy-vid' 
                                        style={{ position: 'relative' }}>
                                        {/* {
                                    this.lazyloadVideo(vid.path, index)
                                  } */}
                                        <ReactPlayer
                                          data-src={vid.path}
                                          className='lazy-vid'
                                          style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
                                          key={index}
                                          url={require('../../assets/vid-placeholder.png')}
                                          playing={false}
                                          controls={true}
                                          width='400px'
                                          height='250px' />
                                      </div>
                                    )
                                    :
                                    null
                                  }

                                </div>
                              )
                            }
                            )
                            : null}

                      </div> : null
                    }
                  </div>
                </div>
              }
              {currentTab === 2 && <div className="tab-content">Description</div>}
              {currentTab === 3 && <div className="tab-content">Source URL</div>}

              <PreviewImage
                hideImageModal={this.hideImageModal}
                showImageModal={this.state.showImageModal}
                imgSrc={this.state.selectedImage}
              />
            </div>
        }
        <div
          className='pagination-wrapper'
          style={{ padding: '0 20px' }}>
          {
            this.renderPager()
          }
        </div>
        {
          this.state.showCalendar ?
            <div>
              {this.renderCalendar()}
            </div>
            :
            null
        }
      </div>
    );
  }
}

Workbench.propTypes = {
  props: PropTypes.object
}

export default Workbench;

