import React, { Component } from 'react';
import './style.css';
import SimpleForm from '../simple-form';
import Api from './../../services/api'
import {
	Tabs,
	Tab,
	ListItem,
	List,
	Button,
	CircularProgress,
	Chip,
	Radio,
	TextField,
	Box,
} from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import Avatar from '@material-ui/core/Avatar';
import Moment from 'react-moment';
import ReactPlayer from 'react-player';
import MergedFC from '../merged-fc';


class LiteSeedData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			currentTab: 0,
			currentIndex: 0,
			preview: false,
			showEdit: false,
			listLoader: false,
			searchKeyword: '',
			showClearButton: false,
			pageSize: 20,
			numOfRequest: 0,
			dataToDisplay: [],
			initialPage: 0,
			showPager: true,
			fc_hash: [],
			checkedBulkItem: [],
			loading_Fc_Hash: false,
			showMergedFC: false,
			openSnackbar: false,
			showSimilarRequestFcModal: false,
			snackbarMessage: '',
			snackbarClass: '',
			currentRequest: [],
			similarRequestBlock: [],
		};
	}

	listSeed = () => {
		this.setState({ listLoader: true });
		Api.getLiteSeedList()
			.then(res => {
				return res;
			})
			.then(res => {
				let data = res.data;
				this.setState({
					data: data,
					dataToDisplay: data.slice(0, 20),
					listLoader: false,
					currentTab: 1,
					numOfRequest: data.length,
					showPager: true
				});
			});
	}

	componentDidMount() {
		this.listSeed();
	}

	updateData = () => {
		this.listSeed();
	}

	showPreview = (index) => {
		this.setState({ currentIndex: index, preview: true, showEdit: false });
		this.getFcHash(this.state.dataToDisplay[index]._id);
	}

	getFcHash = (fc_id) => {
		this.setState({ loading_Fc_Hash: true });
		Api.getFcHashOfAFC(fc_id)
			.then(res => {
				return res.json();
			})
			.then(fc_hash => {
				this.setState({ fc_hash: fc_hash.data, loading_Fc_Hash: false });
			});
	}

	handleEdit = () => {
		this.setState({ showEdit: true, preview: false });
	}

	handleListItemCheckbox = (e) => {
		e.stopPropagation();
		this.setState({ checkedBulkItem: [{ id: e.target.value, status: true }] })
	}

	handleCheckedBulkItem = () => {
		this.setState({ checkedBulkItem: [] });
	}

	handleAcceptBulkAction = () => {
		const { checkedBulkItem } = this.state;
		Api.searchLeftOutRequests(checkedBulkItem).then(res => {
			this.setSnackBarStatus(true, res.mssg, 'success-bg-color');
		});
	}

	searchSeedData = (e) => {
		let searchKeyword = e.target.value;
		this.setState({ searchKeyword });
	}

	onSearchKeyUp = (e) => {
		let keyCode = e.keyCode;
		if (keyCode === 13) {
			let keyword = e.target.value;
			if (e.target.value.length > 0) {
				let keywords = [keyword];
				this.setState({
					listLoader: true,
					data: [],
					dataToDisplay: [],
					preview: false,
					numOfRequest: 0,
					showPager: false
				});
				Api.getFactchecksByKeywords(keywords, true, false).then(res => {
					this.setState({
						data: res.data,
						dataToDisplay: res.data.slice(0, 20),
						numOfRequest: res.data.length,
						listLoader: false,
						initialPage: 0,
						showClearButton: true,
						showPager: true
					});
				});
			}
		}
	}

	clearSearchBar = () => {
		let searchKeyword = '';
		let showClearButton = false;
		this.setState({ searchKeyword, showClearButton, numOfRequest: 0, showPager: false });
		this.listSeed();
	}


	showPreview = (index) => {
		this.setState({ currentIndex: index, preview: true, showEdit: false });
		this.getFcHash(this.state.dataToDisplay[index]._id);
	}

	hideMergedFC = () => {
		this.setState({ showMergedFC: false });
	}

	getFcHash = (fc_id) => {
		this.setState({ loading_Fc_Hash: true });
		Api.getFcHashOfAFC(fc_id)
			.then(res => {
				return res.json();
			})
			.then(fc_hash => {
				this.setState({ fc_hash: fc_hash.data, loading_Fc_Hash: false });
			});
	}

	actionsBar = () => {
		return (
			<div
				className='action-bar-wrapper'
			>
				<TextField
					style={{ width: '50%', marginLeft: '5px' }}
					id="outlined-search"
					label={<i style={{ lineHeight: '18px' }} className="material-icons">search</i>}
					variant="outlined"
					placeholder="Type & Press Return to Search"
					onChange={this.searchSeedData}
					onKeyUp={this.onSearchKeyUp}
					value={this.state.searchKeyword}
				/>
				{this.state.showClearButton ?
					<Button
						style={{ left: '-75px', margin: '5px', lineHeight: '33px' }}
						onClick={this.clearSearchBar}>
						clear
          </Button>
					: null
				}
				{this.state.currentTab === 1 ?
					<div
						className='pagination-wrapper'
						style={{ padding: '0px 16px', marginTop: '10px' }}>
						{
							this.state.showPager ?
								this.renderPager()
								: null
						}
					</div>
					: null
				}
			</div>
		);
	}


	clearSearchBar = () => {
		let searchKeyword = '';
		let showClearButton = false;
		this.setState({ searchKeyword, showClearButton, numOfRequest: 0, showPager: false });
		this.listSeed();
	}

	renderPager = () => {
		let pages = this.state.numOfRequest / this.state.pageSize;
		return <ReactPaginate
			disableInitialCallback={true}
			previousLabel={'<'}
			nextLabel={'>'}
			breakLabel={'...'}
			breakClassName={'break-me'}
			pageCount={pages}
			marginPagesDisplayed={2}
			pageRangeDisplayed={5}
			onPageChange={this.changePage}
			containerClassName={'pagination'}
			subContainerClassName={'paginate'}
			activeClassName={'active'}
			initialPage={this.state.initialPage}
		/>;
	}


	changePage = (e) => {
		this.setState({ preview: false });
		let selectedPage = e.selected + 1;
		let lastPage = selectedPage;
		let pageSize = this.state.pageSize;
		lastPage = pageSize * lastPage;
		let firstPage = lastPage - pageSize;
		this.setState({
			pageNum: selectedPage,
			dataToDisplay: this.state.data.slice(firstPage, lastPage)
		});
	}


	handleCancel = () => {
		this.setState({ showEdit: false })
	}

	renderENChip = (item) =>{
		if(item.en.title.length > 0){
			return(
			<Chip
				label="EN"
				color="primary"
			/>)
		}
	}

	renderHIChip = (item) =>{
		if(item.hi.title.length > 0) {
		return(
		<Chip
			label="HI"
			color="secondary"
		/>)
		}
	}

	render() {
		const {
			currentTab,
			checkedBulkItem,
			currentIndex,
			preview,
			data,
			showEdit,
			listLoader,
			dataToDisplay,

		} = this.state;
		return (
			<div >
				<Tabs
					value={currentTab}
					style={{
						backgroundColor: '#efefef',
						top: '0px',
						position: 'fixed',
						width: '100%',
						zIndex: '20',
						boxShadow: '0px 2px 4px rgba(0,0,0,0.09)'
					}}
				>
					<Tab
						onClick={() => this.setState({ currentTab: 0 })}
						label="Add Seed"
					/>

					<Tab
						onClick={() => this.listSeed()}
						label={<span>
							List Seed
              <i style={{ verticalAlign: 'middle', lineHeight: '0px' }} className="material-icons">refresh</i>
						</span>
						}
					/>
				</Tabs>
				{
					currentTab === 0 &&
					<SimpleForm liteSeedData={data} />
				}

				{
					currentTab === 1 &&
					<div className="seed-data-wrapper">
						{this.actionsBar()}
						<div className={showEdit === true ? "hide" : "list"}>
							{
								listLoader ?
									<div style={{ textAlign: 'center', padding: '10px' }}>
										<CircularProgress color="secondary" />
									</div>
									:
									<List>
										{
											this.state.dataToDisplay.length ?
												this.state.dataToDisplay.map((item, index) =>
													<ListItem
														className='auto-height-list-item'
														style={{
															backgroundColor: index === currentIndex ? '#e8eaf6' : '',
															height: 'auto !important',
															overflow: 'hidden',
															textOverflow: 'ellipsis'
														}}
														key={index}
														onClick={() => this.showPreview(index)}
													>
														<div style={{ marginRight: '10px' }}>
															{
																item.allImages.length ?
																	<img src={item.allImages[0].path} style={{ width: '100px', height: '40px' }} />
																	:
																	item.videos.length ?
																		<ReactPlayer
																			style={{ backgroundColor: '#222' }}
																			url={item.videos[0].path}
																			width='100px'
																			height='40px' />
																		:
																		<span
																			style={{
																				color: 'red',
																				width: '100px',
																				display: 'inline-block',
																				textAlign: 'center',
																				fontWeight: 'bolder'
																			}}>
																			No Media
          											</span>
															}
														</div>
														<div style={{ flex: 1 }} >
															<div style={{ display: 'block', width: '100%', marginBottom: '5px' }}>
																{item.en.title}
															</div>
															<div style={{ display: 'block', width: '100%' }}>
																{item.hi.title}
															</div>
														</div>
														<div>
															<Moment
																style={{ fontWeight: 'bold', fontSize: '13px' }}
																format='DD MMM YYYY'>
																{item.createdAt}
															</Moment>
															<br />
															<Moment
																style={{ fontWeight: 'bold', fontSize: '13px' }}
																format='hh:mm:ss a'>
																{item.createdAt}
															</Moment>
														</div>
														<div >						
															<div style={{margin: '0px 0px 10px 30px'}}>{this.renderENChip(item)}</div>
															<div style={{marginLeft: '30px'}}>{this.renderHIChip(item)}</div>
														</div>
													</ListItem>)
												: <p style={{ textAlign: 'center' }}>"No matching seed data found"</p>
										}
									</List>
							}
						</div>
						{
							preview ?
								<div className={showEdit === true ? "expand" : "preview-wrapper"}>
									<div className="preview">
										<div className='action-button-wrapper' style={{ display: 'flex', flex: 1 }}>
											<Button
												color="primary"
												onClick={() => this.setState({ showMergedFC: true })}
												className='fc_hash_count_wrapper'
											>
												<div className='fc_hash_count_wrapper-div'>
													<div className='fc_hash_count_icon'>
														{
															this.state.loading_Fc_Hash ? <CircularProgress size={13} className='fc_hash_loading' color="secondary" />
																: <i className="material-icons"> call_merge	</i>
														}
													</div>
													<div> FC-HASH -> </div>
													<div className='fc_hash_count_length'>
														{!this.state.loading_Fc_Hash ?
															this.state.fc_hash.length
															: ' '}
													</div>
												</div>
											</Button>
											<Button color="secondary" onClick={() => this.handleEdit()} className='edit-button-wrapper'>
												<div className='edit-button-wrappr-div edit-button-wrappr-div-text'>
													<i className="material-icons">edit</i>
												Edit
											</div>
											</Button>
											<Button style={{ position: 'absolute', right: 0 }} color="secondary" onClick={() => this.setState({ preview: false })}>
												<i className="material-icons">cancel</i>
											</Button>
										</div>
										<div className="preview-content">
											<h4> {this.state.dataToDisplay[currentIndex].en.title} </h4>
											<h4>	{this.state.dataToDisplay[currentIndex].hi.title}	</h4>
											<h5 style={{ margin: '5px 0', color: '#555', fontWeight: '300' }}>
												created at:
												<Moment format='DD MMM YYYY'>
													{dataToDisplay[currentIndex].createdAt}
												</Moment>
											</h5>
											<h5 style={{ margin: '5px 0', color: '#555', fontWeight: '300' }}>
												updated at:
												<Moment format="DD MMM YYYY">
													{dataToDisplay[currentIndex].updatedAt}
												</Moment>
											</h5>
											{
												dataToDisplay[currentIndex].allImages.length ?
												dataToDisplay[currentIndex].allImages.map((image, index) =>
													<div key={index} className="img-wrapper">
														<img src={image.path} alt='' />
													</div>)
													: null
											}
											{
												dataToDisplay[currentIndex].videos.length ?
													<div className="videos">
														<h5>VIDEOS</h5>
														{dataToDisplay[currentIndex].videos.length &&
															dataToDisplay[currentIndex].videos.map((video, index) =>
																<ReactPlayer
																	key={index}
																	url={video.path}
																	playing={false}
																	controls={true}
																	width="550px"
																/>
															)}
													</div> : null
											}
											<p> {dataToDisplay[currentIndex].summary} </p>
											{
												dataToDisplay[currentIndex].en.links.length || data[currentIndex].hi.links.length ?
													<h5>Links</h5> : null
											}
											{dataToDisplay[currentIndex].length &&
												dataToDisplay[currentIndex].en.links.map((link, index) =>
													<a
														key={index}
														className="break-word"
														href={link.url}
													>
														{link.url}
													</a>
												)}
											{dataToDisplay[currentIndex].length &&
												dataToDisplay[currentIndex].hi.links.map((link, index) =>
													<a
														key={index}
														className="break-word"
														href={link.url}
													>
														{link.url}
													</a>)
											}
											<div>
												<div>
													<h5>	English:	</h5>
													<a href={dataToDisplay[currentIndex].en.article_url} target="_blank">
														{dataToDisplay[currentIndex].en.article_url}
													</a>
												</div>
												<div>
													<h5>	Hindi:	</h5>
													<a href={dataToDisplay[currentIndex].hi.article_url} target="_blank">
														{dataToDisplay[currentIndex].hi.article_url}
													</a>
												</div>
											</div>
											<div className='keywords-wrapper'>
												<div className='keywords-wrapper-en'>
													{
														dataToDisplay[currentIndex].length &&
														dataToDisplay[currentIndex].en.keywords.map(keyword =>
															<Chip
																avatar={<Avatar>En</Avatar>}
																label={keyword}
																color="secondary"
																variant="outlined"
																key={keyword}
															/>
														)
													}
												</div>
												<div className='keywords-wrapper-hi'>
													{
														dataToDisplay[currentIndex].length &&
														dataToDisplay[currentIndex].hi.keywords.map(keyword =>
															<Chip
																avatar={<Avatar>Hi</Avatar>}
																label={keyword}
																color="primary"
																variant="outlined"
																key={keyword}
															/>
														)
													}
												</div>
											</div>
										</div>
									</div>
								</div>
								:
								null
						}

						{
							showEdit ?
								<div className={showEdit === false ? "preview-wrapper" : "expand"}>
									<div className="preview">
										<div style={{ textAlign: 'right' }}>
											<i
												style={{ cursor: 'pointer' }}
												className="material-icons secondary-color"
												onClick={() => this.handleCancel()}
											>
												cancel
          					</i>
										</div>
										<SimpleForm
											marTop="0px"
											onUpdate={this.updateData}
											close={this.handleCancel}
											currentData={dataToDisplay[currentIndex]}
											liteSeedData={data}
										/>
									</div>
								</div>
								:
								null
						}

						{
							this.state.showMergedFC
								? <MergedFC closeModal={this.hideMergedFC} hash={this.state.fc_hash} />
								: null
						}
					</div>
				}
			</div>
		);
	}
}

export default LiteSeedData;
