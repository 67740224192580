import React, { Component } from 'react';
import './style.css';
import {
  Tab,
  Tabs,
  List,
  ListItem,
  CircularProgress,
  TextField,
  Chip,
  Button
} from '@material-ui/core'
import Api from '../../services/api'
import Moment from 'react-moment'
import ReactPaginate from 'react-paginate'
import InfiniteCalendar from 'react-infinite-calendar';
import * as Utils from '../../utils';

const moment = require('moment');

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bordcastLogData: [],
      fcQueryData: [],
      pageNum: 1,
      pageSize: 20,
      filters: {
        date: {
          status: false,
          fromDate: '',
          toDate: ''
        },
        lang: {
          status: false,
          en: false,
          hi: false
        },
        sort: {
          status: false,
        },
        type: {
          status: false,
          sortByImage: false,
          sortByVideo: false,
        }
      },
      listLoader: false,
      sortByCreatedAt: false,
      bSearchKeyword: '',
      fSearchKeyword: '',
      showCalendar: false,
      showCalendarFor: false,
      currentTab: 0
    }
  }

  componentDidMount() {
    this.listNotifications();
    this.changeTabStatus(0);
  }

  hideCalendar = (e) => {
    const { filters } = this.state;
    if (e.target.className === 'calendar-wrapper') {
      this.setState({ showCalendar: false });
    }
    if (filters.date.fromDate.length > 0 && filters.date.toDate.length > 0) {
      this.setState({
        submitButtondisabled: false
      })
    }
  };

  dateSelected = (date, showCalendarFor) => {
    const { filters } = this.state;
    date = new Date(date).toISOString();
    if (showCalendarFor === 'fromDate') {
      filters.date.fromDate = date;
      this.setState({ filters, showCalendar: true });
    } else if (showCalendarFor === 'toDate') {
      this.setState({ toDate: date, showCalendar: true });
      filters.date.toDate = date;
      this.setState({ filters, showCalendar: true });
    }
  }

  renderCalendar = () => {
    const { 
      showCalendarFor 
    } = this.state;
    return (
      <div className='calendar-wrapper' onClick={(e) => this.hideCalendar(e)}>
        <InfiniteCalendar
          selected={false}
          onSelect={(e) => { this.dateSelected(e, showCalendarFor); }}
        />
      </div>
    );
  }

  renderPager = () => {
    let pages = this.state.numOfRequest / this.state.pageSize;
    return (<ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={this.changePage}
      containerClassName={'pagination'}
      subContainerClassName={'paginate'}
      activeClassName={'active'}
      initialPage={0}
    />);
  }

  changePage = (e) => {
    let selectedPage = e.selected + 1;
    let lastPage = selectedPage;
    let pageSize = this.state.pageSize;
    lastPage = pageSize * lastPage;
    this.setState({
      pageNum: selectedPage,
    });
  }

  listNotifications = (pageNum = 1, pageSize = 20, type = "broadcast") => {
    const filterBody = {
      "filter": {
        "type": type,
        "date": {"from": "","to": ""}
    }}
    this.setState({ listLoader: true });
    Api.getNotificationsType(
      pageNum,
      pageSize, filterBody).then(res => res)
      .then(res => {
        if (res.data && res.data.length > 0 && res.data[0].type === "broadcast")
          this.setState({
            bordcastLogData: res.data,
            listLoader: false
          })
        else
          this.setState({
            fcQueryData: res.data ? res.data : [],
            listLoader: false
          })
      })
  }

  changeTabStatus = (status) => {
    const toggleView = status ? "fc_query_notif" : "broadcast";
    this.setState({
      currentTab: status,
      filters: {
        ...this.state.filters,
        date: {
          status: false,
          fromDate: '',
          toDate: ''
        }
      }
    }, () => {
      this.listNotifications(this.state.pageNum, this.state.pageSize, toggleView)
    })
  }

  updateSearchKeyword = Utils.debounce((e, type = "broadcast") => {
    const { pageNum, pageSize, currentTab } = this.state;
    const updateSearchQuery = (val) => {
      Api.getSearchValue(val, pageSize, pageNum)
        .then(res => res)
        .then(res => {
          if (res.data && res.data.length > 0 && res.data[0].type === "broadcast")
            this.setState({
              bordcastLogData: res.data ? res.data : [],
              listLoader: false
            })
          else if (res.data && res.data.length > 0 && res.data[0].type === "fc_query_notif") {
            this.setState({
              fcQueryData: res.data ? res.data : [],
              listLoader: false
            })
          } else {
            this.setState({
              bordcastLogData: [],
              fcQueryData: [],
              listLoader: false
            })
          }
        });
    }
    if (type === "broadcast" && e) {
      this.setState({
        bSearchKeyword: e ? e : ''
      }, () => {updateSearchQuery(this.state.bSearchKeyword)})
    } else if(type === "fc_query_notif" && e) {
      this.setState({
        fSearchKeyword: e ? e : ''
      }, () => {updateSearchQuery(this.state.fSearchKeyword)})
    } else if (!e && currentTab === 0) {
      this.listNotifications();
    } else if (!e && currentTab === 1) {
      this.listNotifications(pageNum, 
        pageSize,"fc_query_notif")
    }
  },500)

  updateFilter = (pageNum = 1, pageSize = 20, type = "broadcast") => {
    const { filters } = this.state
    const filterBody = {
      "filter": {
        "type": type,
        "date": {
          "from": filters.date.fromDate,
          "to": filters.date.toDate
        }
      }
    }
    Api.getNotificationsType(pageNum, pageSize, filterBody)
      .then(res => res)
      .then(res => {
        if (res.data && res.data.length > 0 && res.data[0].type === "broadcast")
          this.setState({
            bordcastLogData: res.data,
            listLoader: false
          })
        else if (res.data && res.data.length > 0 && res.data[0].type === "fc_query_notif") {
          this.setState({
            fcQueryData: res.data ? res.data : [],
            listLoader: false
          })
        } else {
          this.setState({
            bordcastLogData: [],
            fcQueryData: [],
            listLoader: false
          })
        }
      })
  }

  render() {
    const {
      currentTab,
      listLoader,
      bordcastLogData,
      fcQueryData,
      filters,
      searchKeyword
    } = this.state;
    return (
      <div className="notifications-log-wrapper" style={{ position: 'relative' }}>
        <Tabs
          value={currentTab || 0}
          style={{
            backgroundColor: '#efefef',
            top: '0px',
            position: 'fixed',
            width: '100%',
            zIndex: '20',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.09)'
          }}
        >
          <Tab
            onClick={() => this.changeTabStatus(0)}
            label="Broadcast Notifications"
            className="broadcast-notifications"
          />
          <Tab
            onClick={() => this.changeTabStatus(1)}
            className="fc-query-notifications"
            label="Query Notifications"
          />
        </Tabs>
        {currentTab === 0 &&
          <div className="broadcast-notifications-wrapper">
            <div className="broadcast-action-wrapper">
            <TextField
              style={{ width: '50%', marginLeft: '5px' }}
              id="outlined-search"
              label={<i style={{ lineHeight: '18px' }} 
              className="material-icons">search</i>}
              variant="outlined"
              placeholder="Type & Press Return to Search"
              onChange={(e)=>this.updateSearchKeyword(e.target.value, "broadcast")}
              value={searchKeyword}
            />
            <div className='date-filter'>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '0 10px' }}>From:</label>
                <input
                  type='text'
                  onChange={() => console.log('')}
                  onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'fromDate' })}
                  placeholder='from'
                  value={filters.date.fromDate !== '' ? moment(filters.date.fromDate).format('DD/MMM/YYYY') : ''}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '0 10px' }}>To:</label>
                <input
                  type='text'
                  onChange={() => console.log('')}
                  onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'toDate' })}
                  placeholder='to'
                  value={filters.date.toDate !== '' ? moment(filters.date.toDate).format('DD/MMM/YYYY') : ''}
                />
              </div>
              </div>
              <div className="search-button">
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this.updateFilter(1, 20, "broadcast")}
              >
                <i className="material-icons">check</i>Filter
  						</Button>
              </div>
            </div>
            {listLoader ?
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <CircularProgress color="secondary" />
              </div> :
              <List className="query-notifications-list">
                {bordcastLogData.length > 0 ? bordcastLogData.map((item, index) => (
                  <ListItem className="item-wrapper" key={index}>
                    <div className="title" style={{ marginLeft: '10px', display: 'inline-block' }}>
                      {
                        item.fc_id ?
                          (<React.Fragment><span className="main-text">
                            {item.fc_id.en.title}
                          </span>
                            <div className="sub-text">
                              {item.fc_id.hi.title}
                            </div></React.Fragment>)
                          :
                          <span
                            style={{
                              color: '#f44336',
                              fontStyle: 'italic',
                              verticalAlign: 'middle'
                            }}>
                            This request has no text!
                      </span>
                      }
                    </div>
                    <div className="badges-wrapper">
                      <span>
                        <Moment format="DD MMM YYYY" >{item.fc_id ? item.fc_id.createdAt : ''}</Moment>
                        <br />
                        <Moment format="hh:mm:ss a" >{item.fc_id ? item.fc_id.createdAt : ''}</Moment>
                        <br />
                      </span>
                    </div>
                    <div className="chip-wrapper">
                      {
                        item.fc_id ? item.fc_id.en ?
                          <Chip
                            label={item.fc_id.en.title !== "" ? 'EN' : 'HI'}
                            color={item.fc_id.en.title !== "" ? 'primary' : 'secondary'}
                            style={{ float: 'left' }} />
                          : '' : ''
                      }
                    </div>
                  </ListItem>)) : <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex' }}>
                    <h2 className='empty-list'>
                      <i className='material-icons'>error</i>
                      No Broadcast Notification
                  </h2>
                  </div>}
              </List>}
          </div>
        }
        {currentTab === 1 &&
          <div className="fc-query-notifications-wrapper">
            <div className="broadcast-action-wrapper">
            <TextField
              style={{ width: '50%', marginLeft: '5px' }}
              id="outlined-search"
              label={<i style={{ lineHeight: '18px' }} className="material-icons">search</i>}
              variant="outlined"
              placeholder="Type & Press Return to Search"
              onChange={(e)=>this.updateSearchKeyword(e.target.value, "fc_query_notif")}
              value={searchKeyword}
            />
            <div className='date-filter'>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '0 10px' }}>From:</label>
                <input
                  type='text'
                  onChange={() => console.log('')}
                  onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'fromDate' })}
                  placeholder='from'
                  value={filters.date.fromDate !== '' ? moment(filters.date.fromDate).format('DD/MMM/YYYY') : ''}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '0 10px' }}>To:</label>
                <input
                  type='text'
                  onChange={() => console.log('')}
                  onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'toDate' })}
                  placeholder='to'
                  value={filters.date.toDate !== '' ? moment(filters.date.toDate).format('DD/MMM/YYYY') : ''}
                />
              </div>
              <div className="search-button">
              <Button
                color="primary"
                variant="outlined"
                onClick={
                () => this.updateFilter(1, 20, "fc_query_notif")}>
                <i className="material-icons">check</i>Filter
  						</Button>
              </div>
              </div>
            </div>
            {listLoader ?
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <CircularProgress color="secondary" />
              </div>
              : <List className="notification-log-list query-notifications-list" >
                {fcQueryData.length > 0 ? fcQueryData.map((item, index) => (
                  <ListItem className="item-wrapper" key={index}>
                    <div className="title" style={{ marginLeft: '10px', display: 'inline-block' }}>
                      {
                        item.request_id ?
                          (<React.Fragment>
                            <span className="main-text">
                              {item.request_id.description}
                            </span></React.Fragment>)
                          :
                          <span
                            style={{
                              color: '#f44336',
                              fontStyle: 'italic',
                              verticalAlign: 'middle'
                            }}>
                            This request has no text!
                        </span>
                      }
                    </div>
                    <div className="badges-wrapper">
                      <span>
                        <Moment format="DD MMM YYYY" >{item.request_id ? item.request_id.createdAt : null}</Moment>
                        <br />
                        <Moment format="hh:mm:ss a" >{item.request_id ? item.request_id.createdAt : null}</Moment>
                        <br />
                      </span>
                    </div>
                    <div className="chip-wrapper">
                      {
                        item.fc_id ? item.fc_id.en ?
                          <Chip
                            label={item.fc_id.en.title !== "" ? 'EN' : 'HI'}
                            color={item.fc_id.en.title !== "" ? 'primary' : 'secondary'}
                            style={{ float: 'left' }} />
                          : null : null
                      }
                    </div>
                  </ListItem>
                )) : <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex' }}>
                    <h2 className='empty-list'>
                      <i className='material-icons'>error</i>
                      No Query Notifications
                    </h2>
                  </div>}
              </List>
            }
          </div>
        }
        {
            this.state.showCalendar ?
              <div>
                {this.renderCalendar()}
              </div>
              :
              null
          }
      </div>
    )
  }
}

export default Notification