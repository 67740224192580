import React, { Component, lazy, Suspense } from 'react';

import {
  ListItem,
  List,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Checkbox,
  FormControlLabel,
  Switch,
  Snackbar,
  Menu,
  MenuItem,
  Box,
  Paper,
  SnackbarContent,
  Radio,
  RadioGroup,
  InputLabel,
  FormControl,
  Select
} from '@material-ui/core'
import Moment from 'react-moment';
import PreviewImage from '../preview-image';
import './style.css';
import ReactPlayer from 'react-player';
import Api from '../../services/api';
import ReactPaginate from 'react-paginate';
import LazyLoad from 'react-lazyload';
// import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import { Motion, spring } from 'react-motion';
import constants from '../../services/constants';
import LSD from './../LSD/index'
const moment = require('moment');

const InfiniteCalendar = lazy(() => import('react-infinite-calendar'));

const Fade = ({ children }) => (
  <Motion
    defaultStyle={{ opacity: 0 }}
    style={{ opacity: spring(1, { stiffness: 100, damping: 12 }) }}
  >
    {interpolatingStyle =>
      <div style={{ opacity: interpolatingStyle.opacity, width: '99%' }}>{children}</div>
    }
  </Motion>
);

class ManageRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 1,
      data: [],
      showPreview: false,
      currentIndex: '',
      customMessageWrapper: false,
      selectedImage: {},
      showImageModal: false,
      activeRequest: '',
      loader: false,
      rejectionTemplate: [],
      requestCount: 0,
      rejectionReason: '',
      pageNum: 1,
      pageSize: 20,
      numOfRequest: 0,
      dataToDisplay: [],
      overlayLoader: false,
      tempData: [],
      offset: 200,
      filterBy: 'none',
      showCalendar: false,
      openSnackbar: false,
      snackbarMessage: '',
      snackbarClass: '',
      showCalendarFor: '',
      showFilters: false,
      checkedBulkItem: [],
      filters: {
        date: {
          status: false,
          fromDate: '',
          toDate: ''
        },
        lang: {
          status: false,
          en: false,
          hi: false
        },
        sort: {
          status: false,
        },
        type: {
          status: false,
          sortByImage: false,
          sortByVideo: false,
        },
        sortByCreatedAt: false,
        searchKeyword: ''
      },
      showSimilarRequestModel: false,
      currentRequest: [],
      showRejectionTemplate: false,
      sortAnchorEl: 1,
      anchorEl: null,
      liteSeedDataActive: false,
      showLiteSeedData: false
    };
  }

  hideImageModal = () => {
    this.setState({ showImageModal: false });
  }

  handleSortFilter = () => {
    const { filters } = this.state;
    const sortfilter = { ...filters.sort };
    sortfilter.status = !filters.sort.status;
    this.setState({ filters: { ...this.state.filters, sort: sortfilter } })
  }

  handleBookMarkIcon = (item, e) => {
    e.stopPropagation();
    const bookmarkStatus = item[0].bookmarked === true ? 0 : 1;
    Api.setRequestBookmark(item[0]._id, bookmarkStatus).then(res => {
      return res;
    }).then(data => {
      if (data.success) {
        const data = this.state.data.map((singleItem) => {
          if (item[0]._id === singleItem[0]._id) {
            singleItem[0].bookmarked = bookmarkStatus ? true : false
            return singleItem
          }
          else
            return singleItem
        })
        this.setState({
          data: data
        })
      }
    });
  }

  componentDidMount() {
    this.applyFilters();
    Api.getAppSettings()
      .then(res => {
        return res.json();
      }).then(res => {
        if (res.data.length) {
          let rejectionTemplate = res.data[0].rejectionTemplate;
          this.setState({ rejectionTemplate });
        }
      });
    if (!'IntersectionObserver' in window &&
      !'IntersectionObserverEntry' in window &&
      !'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
      // load polyfill for lazyload video
      alert('Upgrade your browser or switch to google chrome.');
    } else {
    }
  }

  lazy = async () => {

    // document.addEventListener('DOMContentLoaded', function() {
    let lazyLoadVidObserver = new IntersectionObserver((entries, obvserver) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {

          let vidElement = entry.target;
          let source = entry.target.getAttribute('data-src');

          vidElement.childNodes[0].src = source;
          vidElement.classList.remove('lazy-vid');
          lazyLoadVidObserver.unobserve(vidElement);
        }
      });
    });

    var lazyVids = await document.getElementsByClassName('lazy-vid');
    let counter = 0;
    while (counter != lazyVids.length) {
      let item = lazyVids.item(counter);
      counter++;
      lazyLoadVidObserver.observe(item);
    }
    // });
  }

  lazyloadVideo = (url, index) => {
    return (
      <ReactPlayer
        style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
        key={index}
        // url={url} 
        playing={false}
        controls={true}
        width='400px'
        height='250px' />
    );
  }

  showDetails = (index) => {
    this.lazy();

    this.setState({ overlayLoader: true, showPreview: false });
    let currentData = this.state.dataToDisplay[index];
    let showPreview = true;
    let customMessageWrapper = [];
    customMessageWrapper = Array(currentData.length).fill(false);
    this.setState({
      currentData,
      customMessageWrapper,
      currentIndex: index,
      activeRequest: index
    }, () => this.setState({ overlayLoader: false, showPreview }));
  }

  toggleCustomMessageWrapper = (index) => {
    let customMessageWrapper = [...this.state.customMessageWrapper];
    customMessageWrapper[index] = customMessageWrapper[index] ? false : true;
    this.setState({ customMessageWrapper });
  }

  acceptRequest = (item, index) => {

    this.setState({ overlayLoader: true });
    Api.acceptRequest(item._id)
      .then(response => {
        return response.json();
      }).then(res => {
        if (res.success) {
          let currentIndex = this.state.currentIndex;
          let allData = [...this.state.dataToDisplay];
          let filterArr = allData[currentIndex].filter(data => data._id !== item._id);
          allData[currentIndex] = filterArr;
          // if grouped array contains empty after deleting elements from above section
          //  pop that array from this.state.data
          if (!allData[currentIndex].length) {
            this.setState({ showPreview: false });
            allData = allData.filter(data => data.length !== 0);
          }
          this.setState({ dataToDisplay: allData, overlayLoader: false });
        }
      });
  }

  rejectRequest = (item, rejectionReason, index) => {
    this.setState({ overlayLoader: true });
    Api.rejectRequest(item._id, rejectionReason)
      .then(response => {
        return response.json();
      }).then(res => {
        if (res.success) {
          let currentIndex = this.state.currentIndex;
          let allData = [...this.state.dataToDisplay];
          let filterArr = allData[currentIndex].filter(data => data._id != item._id);
          allData[currentIndex] = filterArr;
          // if grouped array contains empty after deleting elements from above section
          //  pop that array from this.state.data
          if (!allData[currentIndex].length) {
            this.setState({ showPreview: false });
            allData = allData.filter(data => data.length != 0);
          }
          this.setState({ dataToDisplay: allData, overlayLoader: false });
          this.setState({ customMessageWrapper: false });
        }
      });
  }

  renderPager = () => {

    let pages = this.state.numOfRequest / this.state.pageSize;
    return <ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={this.changePage}
      containerClassName={'pagination'}
      subContainerClassName={'paginate'}
      activeClassName={'active'}
      initialPage={0}
    />;
  }

  changePage = (e) => {

    let selectedPage = e.selected + 1;
    this.setState({
      pageNum: selectedPage,
      // dataToDisplay: this.state.data.slice(firstPage, lastPage),
      showPreview: false
    }, () => {
      this.applyFilters()
      this.handleCheckedBulkItem()
    });

  }

  searchManageRequests = (e) => {
    let { filters } = this.state;
    this.setState({ showPreview: false });
    if (filters.searchKeyword.length >= 1 && e.nativeEvent.key === 'Enter') {
      this.applyFilters();
    }
    if (filters.searchKeyword.length === 0) {
      this.clearSearch();
    }
  }

  clearSearch = () => {
    let { filters } = this.state;
    filters.searchKeyword = '';
    this.setState({ filters });
    this.applyFilters();
  }

  onSearchKeywordChange = (e) => {
    let searchKeyword = e.target.value.toLowerCase();
    let { filters } = this.state;
    filters.searchKeyword = searchKeyword;
    this.setState({ filters });
  }

  handleDateFilter = () => {
    let { filters } = this.state;
    filters.date.status = !filters.date.status;
    if (filters.date.status === false) {
      this.setState({
        filters: {
          date: {
            status: filters.date.status,
            fromDate: "",
            toDate: ""
          },
          lang: filters.lang,
          sort: {
            ...this.state.filters.sort
          },
          type: {
            ...this.state.filters.type
          },
          sortByCreatedAt: this.state.filters.sortByCreatedAt,
          searchKeyword: filters.searchKeyword
        }
      });
    } else {
      this.setState({ filters });
    }
  }

  applyFilters = async (asyncLoad = false) => {

    let { filters, pageSize, pageNum } = this.state;
    // filter validations
    let fromDate = moment(filters.date.fromDate);
    let toDate = moment(filters.date.toDate);
    let numDays = fromDate.diff(toDate, 'days');
    let validation = 0;

    if (filters.date.status) {
      if (numDays >= 0) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : End date should be greater than start date',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
      if (isNaN(numDays)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : Choose Dates',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
    }
    if (filters.lang.status) {
      if (!(filters.lang.en || filters.lang.hi)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : Atleast choose one language',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
    }
    if (filters.type.status) {
      if (!(filters.type.sortByImage || filters.type.sortByVideo)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : Atleast choose one type',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
    }

    if (validation === 0) {

      if (!asyncLoad)
        this.setState({ dataToDisplay: [] });
      // status = Pending or accepted
      let res = await Api.getFilteredRequests(constants.PENDING_REQUESTS, filters, pageSize, pageNum);
      let data = res.data;

      let groupedArray = [];
      for (let i = 0; i < data.length; i++) {
        let tempArr = [];
        tempArr.push(data[i]);
        groupedArray.push(tempArr);
      }

      this.setState({
        loader: false,
        data: groupedArray,
        tempData: groupedArray,
        numOfRequest: res.numRecords,
        dataToDisplay: groupedArray.slice(0, this.state.pageSize),
        showPreview: false,
        requestCount: res.numRecords
      });
    }
  }

  clearFilters = () => {
    const filters = {
      date: {
        status: false,
        fromDate: '',
        toDate: ''
      },
      lang: {
        status: false,
        en: false,
        hi: false
      },
      sort: {
        status: false,
      },
      type: {
        status: false,
        sortByImage: false,
        sortByVideo: false,
      },
      searchKeyword: '',
      sortByCreatedAt: false,
    };
    let pageNum = 1;
    this.setState({
      filters,
      sortAnchorEl: 1,
      pageNum
    }, () => this.applyFilters());
  }

  handleLangFilter = () => {
    let { filters } = this.state;
    filters.lang.status = !filters.lang.status;
    if (filters.lang.status === false) {
      this.setState({
        filters
          : {
          lang: {
            status: filters.lang.status,
            en: false,
            hi: false
          },
          date: filters.date,
          sort: {
            ...this.state.filters.sort
          },
          type: {
            ...this.state.filters.type
          },
          sortByCreatedAt: this.state.filters.sortByCreatedAt,
          searchKeyword: filters.searchKeyword
        }
      });
    } else {
      this.setState({ filters });
    }
  }

  handleTypeFilter = () => {
    let { filters } = this.state;
    filters.type.status = !filters.type.status;
    if (!filters.type.status) {
      filters.type.sortByImage = false;
      filters.type.sortByVideo = false;
    }
    this.setState({ filters })
  }

  handleFilterCheckbox = (e) => {

    let lang = e.target.value;
    let { filters } = this.state;
    filters.lang[lang] = !filters.lang[lang];

    this.setState({ filters });
  }

  handleChangeTypeFilter = (e) => {
    let { filters } = this.state;
    if (e.target.value === "video") {
      filters.type.sortByVideo = filters.type.sortByVideo ? false : true
      this.setState({ filters });
    } else
      filters.type.sortByImage = filters.type.sortByImage ? false : true
    this.setState({ filters });
  }

  renderFilters = () => {

    let { filters, sortAnchorEl } = this.state;
    return (
      <div className='filter-wrapper'>
        <div className='date-filter'>
          <div>
            <FormControlLabel
              control={<Switch
                checked={this.state.filters.date.status}
                onChange={this.handleDateFilter}
                value="checkedA"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Date'
            />
          </div>
          <div>
            <input
              disabled={!filters.date.status}
              style={{ cursor: filters.date.status ? 'default' : 'not-allowed' }}
              type='text'
              onChange={() => console.log('')}
              onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'fromDate' })}
              placeholder='from'
              value={this.state.filters.date.fromDate !== '' ? moment(this.state.filters.date.fromDate).format('DD/MM/YYYY') : ''}
            />
          </div>
          <div>
            <input
              disabled={!filters.date.status}
              style={{ cursor: filters.date.status ? 'default' : 'not-allowed' }}
              type='text'
              onChange={() => console.log('')}
              onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'toDate' })}
              placeholder='to'
              value={this.state.filters.date.toDate !== '' ? moment(this.state.filters.date.toDate).format('DD/MM/YYYY') : ''}
            />
          </div>
        </div>
        <div className='lang-filter'>
          <div>
            <FormControlLabel
              control={<Switch
                checked={this.state.filters.lang.status}
                onChange={this.handleLangFilter}
                value="checkedA"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Language'
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox
                disabled={!this.state.filters.lang.status}
                color='primary'
                checked={this.state.filters.lang.en}
                onChange={this.handleFilterCheckbox}
                value='en'
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='English'
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox
                disabled={!this.state.filters.lang.status}
                color='primary'
                checked={this.state.filters.lang.hi}
                onChange={this.handleFilterCheckbox}
                value='hi'
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Hindi'
            />
          </div>
        </div>
        <div className="sort-filter">
          <FormControlLabel
            control={<Switch
              checked={filters.sort.status}
              onChange={this.handleSortFilter}
              value="checkedA"
              inputProps={{
                'aria-label': 'sort checkbox',
              }}
            />}
            label='Sort By'
          />
          <FormControlLabel
            control={
              <Box>
                <FormControl variant="outlined" style={{ minWidth: '120px' }}>
                  <InputLabel htmlFor="outlined-sort-native-simple">
                    Sort By
                  </InputLabel>
                  <Select
                    native
                    disabled={!filters.sort.status}
                    value={sortAnchorEl}
                    onChange={(e) => this.updateSortByFilterStatus(e)}
                    inputProps={{
                      name: 'sort',
                      id: 'outlined-sort-native-simple',
                    }}
                  >
                    <option value={1}>Updated At</option>
                    <option value={0}>Created At</option>
                  </Select>
                </FormControl>
              </Box>
            }
          />
        </div>
        <div className="type-filter" style={{ display: 'flex' }}>
          <div>
            <FormControlLabel
              control={<Switch
                checked={filters.type.status}
                onChange={this.handleTypeFilter}
                value="checkedA"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Type'
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <FormControlLabel
                control={<Checkbox
                  disabled={!filters.type.status}
                  color='primary'
                  checked={filters.type.sortByVideo}
                  onChange={(e) => this.handleChangeTypeFilter(e)}
                  value='video'
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />}
                label='Video'
              />
            </div>
            <div>
              <FormControlLabel
                control={<Checkbox
                  disabled={!filters.type.status}
                  color='primary'
                  checked={filters.type.sortByImage}
                  onChange={(e) => this.handleChangeTypeFilter(e)}
                  value='image'
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />}
                label='Image'
              />
            </div>
          </div>
        </div>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            onClick={this.clearFilters}
            variant='contained'
            color='default'>
            Clear All
        </Button>
          <Button
            disabled={!(this.state.filters.date.status || this.state.filters.lang.status || this.state.filters.sort.status || this.state.filters.type.status)}
            onClick={this.applyFilters}
            variant='contained'
            color='primary'>
            Apply
        </Button>
        </div>
      </div>
    );
  }

  hideCalendar = (e) => {

    if (e.target.className === 'calendar-wrapper') {
      this.setState({ showCalendar: false });
    }
  };

  renderCalendar = () => {
    let { showCalendarFor } = this.state;
    let today = new Date();
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return (
      <div className='calendar-wrapper' onClick={(e) => this.hideCalendar(e)}>
        <Suspense fallback={<p>Loading Calendar...</p>}>
          <InfiniteCalendar
            selected={false}
            onSelect={(e) => { this.dateSelected(e, showCalendarFor); }}
          />
        </Suspense>
      </div>
    );
  }

  dateSelected = (date, showCalendarFor) => {

    let { filters } = this.state;
    date = new Date(date).toISOString();

    if (showCalendarFor === 'fromDate') {

      filters.date.fromDate = date;
      this.setState({ filters, showCalendar: true });
    } else if (showCalendarFor === 'toDate') {

      this.setState({ toDate: date, showCalendar: true });
      filters.date.toDate = date;
      this.setState({ filters, showCalendar: true });
    } else {
      // nothing

    }
  }

  handleListItemCheckbox = (e) => {
    e.stopPropagation();
    const _id = e.target.getAttribute('aria-label');
    const data = this.state.checkedBulkItem.find(item => item.id === _id);
    if (data != undefined) {
      const id = data.hasOwnProperty('id') ? data.id : undefined;
      const newData = this.state.checkedBulkItem.filter(item => item.id != id);
      this.setState({
        checkedBulkItem: newData
      })
    } else {
      this.setState({
        checkedBulkItem: [
          ...this.state.checkedBulkItem,
          { id: _id, status: true }
        ]
      })
    }
  }

  handleAcceptBulkAction = () => {
    Api.sendAcceptBulkAction(this.state.checkedBulkItem, constants.ACCEPTED_REQUESTS).then(response => {
      return response.json();
    }).then(res => {
      if (res.success) {
        this.handleCheckedBulkItem()
        this.applyFilters(true);
      }
      else{

      }
    }).catch(err => {
    });
  }

  handleRejectBulkAction = (str = '') => {
    Api.sendRejectBulkAction(this.state.checkedBulkItem, str).then(response => {
      return response.json();
    }).then(res => {
      if (res.success) {
        this.handleCheckedBulkItem()
        this.applyFilters(true);
      }
      else{

      }
    }).catch(err => {
    });
  }

  handleRejectMenuClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleMenuClose = () =>{
    this.setState({
      anchorEl: null
    })
  }
  handleRejectMenuClose = (id) => {
    this.setState({
      anchorEl: null
    })
      this.handleRejectBulkAction(this.state.rejectionTemplate[id])
  };

  handleCheckedBulkItem = () => {
    this.setState({
      checkedBulkItem: []
    })
  }

  handleReasonChange = (e) => {
    let value = e.target.value;
    let body = value.split('/')[0];
    let bodyHi = value.split('/')[1];
    let { currentRequest } = this.state;
    let ids = currentRequest.map(request => { return { id: request._id } });
    let reason = { body, bodyHi };
    this.setState({ showRejectionTemplate: false, showSimilarRequestModel: false });

    Api
      .sendRejectBulkAction(ids, reason)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          this.setState({
            snackbarOpen: true,
            snackbarContent: {
              variant: 'success-bg-color',
              message: res.mssg
            },
            inProgress: false
          });
          this.applyFilters();
        }
      });
  }

  acceptSimilarRequest = () => {
    this.setState({ showRejectionTemplate: false });
    let { currentRequest } = this.state;
    let ids = currentRequest.map(request => { return { id: request._id } });
    Api
      .sendAcceptBulkAction(ids, constants.ACCEPTED_REQUESTS)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          this.setState({
            snackbarOpen: true,
            snackbarContent: {
              variant: 'success-bg-color',
              message: res.mssg
            },
            showRejectionTemplate: false,
            showSimilarRequestModel: false
          });
        } else {
          this.setState({
            snackbarOpen: true,
            snackbarContent: {
              variant: 'danger-bg-color',
              message: 'Something went wrong'
            },
            showSimilarRequestModel: false
          });
        }
        this.applyFilters();
      });
  }

  removeDifferentRequest = (id) => {
    const { currentRequest } = this.state;
    const filteredRequest = currentRequest.filter(item => item._id !== id);
    this.setState({ currentRequest: filteredRequest })
  }

  addLiteSeedData = () => {
    this.setState({ liteSeedDataActive: !this.state.liteSeedDataActive })
  }

  renderSimilarRequestDetailedView = () => {
    const { currentRequest } = this.state;
    return (
      <div className='detailed-view-wrapper'>
        <Paper className='detailed-view'>
          <div className='detailed-view-content-wrapper'>
            <div className='detailed-view-content'>
              <div style={{width: '30%', fontSize: '24px'}}>Detailed View</div>
              <div className='detailed-view-buttons'>
              {
                currentRequest.length > 0 ?
                  <React.Fragment>
                    <Button
                      color='primary'
                      className="liteSeedData-button"
                      onClick={() => this.addLiteSeedData()}>
                      Add Lite Seed Data
                </Button>
                    <Button
                      color='primary'
                      onClick={this.acceptSimilarRequest}>
                      Accept
                </Button>
                    <Button
                      color='secondary'
                      onClick={() => this.setState({ showRejectionTemplate: true })}>
                      Reject
                </Button>
                  </React.Fragment> : ''
              }
              </div>
            </div>
            <Button onClick={() => this.setState({ showSimilarRequestModel: false })}>
              <i className='material-icons' >
                cancel
              </i>
            </Button>
          </div>

          <div className='detailed-view-images-wrapper'>
            {currentRequest && currentRequest.length > 0 ?
              currentRequest.map((request, index) =>
                <Card key={request._id} className={`detailed-view-images`}>
                  <i
                    className='material-icons'
                    style={{ zIndex: 3 }}
                    onClick={() => this.removeDifferentRequest(request._id)}>
                    cancel
                </i>
                  {request.images && request.images.length > 0 ?
                    <CardMedia
                      style={{
                        height: 0,
                        paddingTop: '56.25%',
                      }}
                      image={request.images[0].path}
                    />
                    :
                    <ReactPlayer
                      className='lazy-vid'
                      style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
                      key={index}
                      url={request.videos[0].path}
                      playing={false}
                      controls={true}
                      width='100%'
                      height='250px' />
                  }
                  <Chip
                    className='bottom-left-chip'
                    style={{ zIndex: 3 }}
                    label={<Moment format='DD MMM YYYY'>
                      {request.createdAt}
                    </Moment>}
                    color='secondary'>
                  </Chip>
                  <Chip
                    className="bottom-right-chip"
                    label={`${request.preferredLanguage === "en" ? "EN" : "HI"}`}
                    color={`${request.preferredLanguage === "en" ? "primary" : "secondary"}`}
                  />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {request.description ? request.description : ''}
                    </Typography>
                  </CardContent>
                </Card>
              ) :
              <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex', width: '100%' }}>
                <h2 className='empty-list'>
                  <i className='material-icons'>error</i>
                No Data Found To Show
              </h2>
              </div>
            }
          </div>
        </Paper>
        {this.state.liteSeedDataActive === true &&
          <LSD
            currentRequest={this.state.currentRequest}
            addLiteSeedData={this.addLiteSeedData}
            showSelectedRequest = {this.state.showSelectedRequest}
          />
        }
      </div>
    )
  }


  showSimilarRequest = (imageDetail, videoDetail) => {
    const isImage = imageDetail.length > 0 ? 1 : 0;
    const paHashOrMD5 = isImage ? imageDetail[0].pHash : videoDetail[0].md5;
    const typeOfMedia = imageDetail.length > 0 ? 'image' : videoDetail.length > 0 ? 'video' : undefined;
    if (paHashOrMD5)
      Api.getSimilarRequestHash(paHashOrMD5, constants.PENDING_REQUESTS, typeOfMedia).then(res => {
        if (res.success) {
          const data = res.similarRequests ? res.similarRequests : res.data;
          this.setState({
            currentRequest: data,
            showSimilarRequestModel: true
          })
        } else {
        }
      });
    else {
      this.setState({
        currentRequest: [],
        showSimilarRequestModel: true
      })
    }
  }

  updateSortByFilterStatus = (e) => {
    this.setState({
      sortAnchorEl: !Number(e.target.value) ? Number(e.target.value) : 1,
      filters: {
        ...this.state.filters,
        sortByCreatedAt: !Number(e.target.value) ? true : false
      },
    })

  }

  render() {
    const {
      currentTab,
      currentIndex,
      activeRequest,
      loader,
      rejectionTemplate,
      showFilters,
      checkedBulkItem,
      showSimilarRequestModel,
      showRejectionTemplate
    } = this.state;
    return (
      <div style={{ marginBottom: '150px' }} className="manage-request-wrapper">
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnackbar}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSnackbar: false })}>
          <SnackbarContent
            className={this.state.snackbarClass}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className=''>
                {this.state.snackbarMessage}
              </span>
            }
          />
        </Snackbar>
        {
          loader ?
            <div style={{ textAlign: 'center', marginTop: '70px' }}>
              <CircularProgress color="secondary" />
            </div>
            :
            <div>
              {
                <div className="tab-content">
                  <div className="requestCount">
                    <div
                      className={showFilters ? 'show-filters active' : 'show-filters'}
                      onClick={() => this.setState({ showFilters: !showFilters })}>
                      <span>
                        <i style={{ verticalAlign: 'middle' }} className='material-icons'>filter_list</i>
                        <span style={{ verticalAlign: 'middle' }} >Filters </span>
                      </span>
                    </div>
                    <Button
                      variant='outlined'
                      onClick={() => this.clearFilters()}
                      style={{ color: '#777', marginRight: '10px' }}>
                      <img
                        style={{
                          width: '25px',
                          height: '25px',
                          cursor: 'pointer',
                          marginRight: '3px'
                        }}
                        src={require('../../assets/icons/reload.png')}
                        alt=''
                      />
                      Refresh
  									</Button>
                    <div className='custom-input-field-wrapper'>
                      <input
                        onChange={this.onSearchKeywordChange}
                        value={this.state.filters.searchKeyword}
                        onKeyUp={(e) => this.searchManageRequests(e)}
                        className='custom-input-field'
                        placeholder='Type keyword & press return to search'
                      />
                      {
                        this.state.filters.searchKeyword ?
                          <Button
                            variant="contained"
                            style={{ height: '45px', marginLeft: '10px', alignSelf: 'center' }}
                            onClick={this.clearSearch}>clear</Button>
                          : null
                      }
                    </div>

                    <div style={{ marginLeft: 'auto' }}>
                      Requests: {this.state.requestCount}
                    </div>
                    {
                      showFilters ?
                        this.renderFilters()
                        :
                        null
                    }

                  </div>

                  <div className={showFilters ? "withFilterWrapper" : "mr-flex-wrapper"}>
                    <div className="mr-list-wrapper">
                      {checkedBulkItem.length > 0 ?
                        <Box className="bulk-action-container">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleAcceptBulkAction}
                          >
                            Accept
                          </Button>
                          <Box>
                            <Button aria-controls="simple-menu" aria-haspopup="true"
                              variant="contained"
                              color="secondary"
                              onClick={this.handleRejectMenuClick}>
                              Reject
                          </Button>
                            <Menu
                              id="reject-button-menu"
                              anchorEl={this.state.anchorEl}
                              keepMounted
                              open={Boolean(this.state.anchorEl)}
                              onClose={this.handleMenuClose}
                            >
                            <div style={{ display: 'flex',alignItems: 'center' }}>  
                              <div
                                style={{ display: 'flex', backgroundColor: '#eee',width: '95%'}}>
                                <div
                                  style={{
                                    borderRight: '1px solid #ccc',
                                    padding: '16px',
                                    width: '50%',
                                    textAlign: 'center'
                                  }}>
                                  English Variant
                              </div>
                                <div 
                                  style={{ 
                                    padding: '16px',
                                    width: '50%',
                                    textAlign: 'center' 
                                  }}>
                                  Hindi Variant
                                </div>
                              </div>
                              <Button 
                                color="secondary"
                                onClick={this.handleMenuClose}
                              >
                                <i className="material-icons">close</i>
                              </Button>
                            </div>  
                              {rejectionTemplate.map((item, index) => 
                                <div className="reject-template-wrapper">
                                  <MenuItem key={index}
                                    onClick={(e) => this.handleRejectMenuClose(index)}
                                    style={{ width: '100%', display: 'flex', marginLeft: '30px' }}
                                  >
                                    <div style={{ width: '50%'}} >{item.body}</div>
                                    <div style={{ width: '50%'}}>{item.bodyHi}</div>
                                  </MenuItem>
                                </div>
                              )}
                            </Menu>
                          </Box>
                          <Button
                            variant="contained"
                            onClick={this.handleCheckedBulkItem}
                          >
                            Clear
                          </Button>
                        </Box>
                        : ''
                      }
                      
                      {
                        this.state.dataToDisplay.length ?
                          <List>
                            {this.state.dataToDisplay.map((item, index) =>
                              <div
                                key={item._id}
                                className="item-wrapper"
                                style={{ backgroundColor: activeRequest === index ? '#e8eaf6' : '' }}
                              >
                                <LazyLoad
                                  scroll={true}
                                  placeholder={<CircularProgress />}
                                  scrollContainer='.mr-list-wrapper'
                                >
                                  <ListItem style={{ paddingLeft: '0px' }} onClick={() => this.showDetails(index)}>
                                    <Checkbox
                                      checked={checkedBulkItem.length > 0 ? checkedBulkItem.find((itemId) => itemId.id === item[0]._id ? true : false) : false
                                      }
                                      onChange={(e) => this.handleListItemCheckbox(e)}
                                      onClick={(e) => e.stopPropagation()}
                                      value={`checkedItem${index}`}
                                      color="primary"
                                      inputProps={{
                                        'aria-label': item[0]._id,
                                      }}
                                    />
                                    <div>
                                      {
                                        item[0].images.length ?
                                          (<img
                                            src={item[0].images[0].path}
                                            alt=''
                                            className="image_in_list"
                                          />)
                                          :
                                          <ReactPlayer
                                            style={{ display: 'inline-block', backgroundColor: '#222' }}
                                            url={item[0].videos[0] ? item[0].videos[0].path : ''}
                                            playing={false}
                                            width='100px'
                                            height='40px'
                                          />
                                      }
                                    </div>
                                    <div className="title">
                                      <div style={{ marginLeft: '10px', display: 'inline-block' }}>
                                        {
                                          item[0].description && item[0].description.length ?
                                            <span>
                                              {item[0].description.substr(0, 50)}
                                            </span>
                                            :
                                            <span
                                              style={{
                                                color: '#f44336',
                                                fontStyle: 'italic',
                                                verticalAlign: 'middle'
                                              }}>
                                              This request has no text!
                                        </span>
                                        }
                                      </div>
                                    </div>
                                    <IconButton className="bookmark-button" color="primary" onClick={(e) => this.handleBookMarkIcon(item, e)} aria-label="add to bookmark">
                                      <div className={`icon--${item[0].bookmarked ? 'bookmarked' : 'bookmark'}`} />
                                    </IconButton>
                                    <div className="badges-wrapper">
                                      <span>
                                        CreatedAt: <Moment format="DD MMM YYYY" >{item[0].createdAt}</Moment>   {"  "}
                                        <Moment format="hh:mm:ss a" >{item[0].createdAt}</Moment>
                                        <br />
                                        UpdatedAt: <Moment format="DD MMM YYYY" >{item[0].updatedAt}</Moment>   {"  "}
                                        <Moment format="hh:mm:ss a" >{item[0].updatedAt}</Moment>
                                        <br />
                                        <span style={{ fontWeight: 'normal' }}>{item[0].key} </span>
                                      </span>
                                    </div>
                                    <div className="chip-wrapper">
                                      {
                                        item[0].preferredLanguage ?
                                          <Chip
                                            label={item[0].preferredLanguage === 'en' ? 'EN' : 'HI'}
                                            color={item[0].preferredLanguage === 'en' ? 'primary' : 'secondary'}
                                            style={{ float: 'left' }} />
                                          : null
                                      }
                                    </div>
                                  </ListItem>
                                </LazyLoad>
                              </div>
                            )}
                          </List>
                          :
                          <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex' }}>
                            <h2 className='empty-list'>
                              <i className='material-icons'>error</i>
                              No requests found
                            </h2>
                          </div>
                      }
                    </div>
                    {this.state.showPreview ?
                      <div className="preview-wrapper"
                        style={{
                          height: '583px',
                          border: '1px solid #efefef'
                        }}>

                        {
                          this.state.overlayLoader ?
                            <div className='overlay-loader'>
                              <CircularProgress color='secondary' />
                            </div>
                            : null
                        }
                        {
                          this.state.dataToDisplay.length ?
                            this.state.dataToDisplay[currentIndex].map((item, index) => {
                              let counter = index;
                              return (
                                <div className="preview" key={index}>
                                  <div className="actions-wrapper" style={{ position: 'relative' }}>
                                    <Chip
                                      label={item.preferredLanguage === 'en' ? 'ENGLISH' : 'HINDI'}
                                      color="primary"
                                      style={{ float: 'left' }} />
                                    <Box style={{ display: 'flex', paddingRight: '60px', flexWrap: 'wrap' }}>
                                      <Button
                                        color="secondary"
                                        onClick={() => this.showSimilarRequest(item.images, item.videos)}
                                      >
                                        <i className="material-icons">search</i>Similar Request
  															      </Button>
                                      <Button
                                        color="primary"
                                        onClick={() => this.acceptRequest(item, index)}
                                      >
                                        <i className="material-icons">check</i>Accept
  															      </Button>
                                      <Button
                                        color="secondary"
                                        onClick={(e) => this.toggleCustomMessageWrapper(index, e)}
                                      >
                                        <i className="material-icons">close</i>Reject
  															      </Button>
                                      <Button style={{ position: 'absolute', right: 0 }} onClick={() => this.setState({ showPreview: false })}>
                                        <i className="material-icons red-text">cancel</i>
                                      </Button>
                                    </Box>
                                  </div>
                                  {
                                    item.preferredLanguage === 'en'
                                      ?
                                      this.state.customMessageWrapper[index] ?
                                        (<div className="custom-message-wrapper">
                                          {rejectionTemplate.map((tempalte, idx) =>
                                            <div key={idx}>
                                              <input
                                                id={index + '-' + idx}
                                                type="radio"
                                                name="rejection-reason"
                                                onClick={() => this.setState({ rejectionReason: tempalte.body })}
                                              />
                                              <label htmlFor={index + '-' + idx}>{tempalte.body}</label>
                                            </div>
                                          )}
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                              () =>
                                                this.rejectRequest(item, this.state.rejectionReason, index)
                                            }
                                          >
                                            Submit
                                          </Button>
                                        </div>)
                                        : null
                                      :
                                      this.state.customMessageWrapper[index] ?
                                        (<div className="custom-message-wrapper">
                                          {rejectionTemplate.map((tempalte, idx) =>
                                            <div key={idx}>
                                              <input
                                                id={index + '-' + idx}
                                                type="radio"
                                                name="rejection-reason"
                                                onClick={() => this.setState({ rejectionReason: tempalte.bodyHi })}
                                              />
                                              <label htmlFor={index + '-' + idx}>{tempalte.bodyHi}</label>
                                            </div>
                                          )}
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                              () =>
                                                this.rejectRequest(item, this.state.rejectionReason, index)
                                            }
                                          >
                                            Submit
                                    </Button>
                                        </div>)
                                        : null
                                  }

                                  <h3 style={{ fontWeight: '300', color: '#333', wordBreak: 'break-all' }}>{item.description}</h3>
                                  <h4 style={{ fontWeight: '300', color: '#555', marginTop: '5px' }}>
                                    <Moment format="DD MMM YYYY">{item.createdAt}</Moment>
                                  </h4>
                                  <h4 style={{ fontWeight: '300', color: '#555', marginTop: '5px' }}>
                                    <Moment format="h:mm:ss a">{item.createdAt}</Moment>
                                  </h4>
                                  {item.images.length ?
                                    item.images.map((img, index) =>
                                      <div
                                        key={index}
                                        className="img-wrapper pointer"
                                        onClick={() => this.setState({ selectedImage: img.path, showImageModal: true })}
                                      >
                                        <img
                                          src={img.path}
                                          alt='' />
                                      </div>
                                    )
                                    : null}

                                  {item.videos.length ?
                                    item.videos.map((vid, index) =>
                                      <div
                                        id={vid._id}
                                        data-src={vid.path}
                                        key={index}
                                        // className='lazy-vid' 
                                        style={{ position: 'relative' }}>
                                        {/* {
                                    this.lazyloadVideo(vid.path, index)
                                  } */}
                                        <ReactPlayer
                                          data-src={vid.path}
                                          className='lazy-vid'
                                          style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
                                          key={index}
                                          url={require('../../assets/vid-placeholder.png')}
                                          playing={false}
                                          controls={true}
                                          width='400px'
                                          height='250px' />
                                      </div>
                                    )
                                    :
                                    null
                                  }
                                </div>
                              )
                            }
                            )
                            : null}

                      </div> : null
                    }
                  </div>
                </div>
              }
              {currentTab === 2 && <div className="tab-content">Description</div>}
              {currentTab === 3 && <div className="tab-content">Source URL</div>}

              <PreviewImage
                hideImageModal={this.hideImageModal}
                showImageModal={this.state.showImageModal}
                imgSrc={this.state.selectedImage}
              />
            </div>
        }
        <div
          className='pagination-wrapper'
          style={{ padding: '0 20px' }}>
          {
            this.renderPager()
          }
        </div>
        {
          this.state.showCalendar ?
            <div>
              {this.renderCalendar()}
            </div>
            :
            null
        }
        {
          showSimilarRequestModel ?
            this.renderSimilarRequestDetailedView() : ''
        }
        {
          showRejectionTemplate ?
            <div className='rejection-template-wrapper'>
              <Paper className='rejection-template-card'>
                <div className='action-bar'>
                  <h3>Rejection Reason</h3>
                  <Button color='secondary' onClick={() => this.setState({ showRejectionTemplate: false })}>
                    <i className='material-icons'>cancel</i>
                  </Button>
                </div>
                <RadioGroup style={{ display: 'flex' }} name="rejection-reason" onChange={e => this.handleReasonChange(e)}>
                  {
                    rejectionTemplate.map((template, index) =>
                      <FormControlLabel
                        key={index}
                        value={template.body + '/' + template.bodyHi}
                        control={<Radio />}
                        label={<p>{template.body} <br /> {template.bodyHi}</p>}
                      />
                    )
                  }
                </RadioGroup>
              </Paper>

            </div>
            : null
        }
      </div>
    );
  }
}

export default ManageRequests;
