import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CookieManager from '../../services/cookie-manager';

class Header extends Component {
  state = {
  	anchorEl: null
  };

  handleClick = event => {
  	this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
  	this.setState({ anchorEl: null });
  };

  logout = () => {
  	CookieManager.deleteCookie('_data');
  	window.location = '/login';
  }

  render() {
  	const { anchorEl } = this.state;
  	return (
  		<AppBar
  			position="fixed"
  			style={{
  				left: this.props.leftPos, 
  				zIndex:'20', 
  				transition: 'all 0.3s ease-out',
  				width: 'auto'
  			}}
  			elevation={0}
  		>
  			<Toolbar>
  				<Typography
  					variant="h6"
  					color="inherit"
  					style={{flexGrow: this.props.flexG, transition: 'all 0.3s ease-out'}}>
            Fact Check
  				</Typography>
  				<div
  					style={{textDecoration:'none', color: '#fff'}}
  					onClick={this.logout}
  				>
  					<Button  color="inherit">Logout</Button>
  				</div>
  			</Toolbar>
        
  		</AppBar>
  	);
  }
}

export default Header;

// <Button color="inherit">Logout</Button>
