import React, { Component } from 'react';
import './style.css';
import ReactPlayer from 'react-player';

class PreviewImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPreview : '',
			imgSrc : ''
		};
	}

	closeModal = () => {
		this.props.hideImageModal();
	}
	
	render() { 
		return ( 
			(this.props.showImageModal) ?
				(<div className="image-modal-wrapper">
					<div style={{alignSelf:'center'}}>
						<div className="image-modal-action">
							<i className="material-icons pointer" onClick={() => this.closeModal()}>cancel</i>
						</div>
						<div className="img-wrapper">
              {
                this.props.imgSrc.split(/\.(?=[^\.]+$)/)[1] === 'mp4' ?

                <div style={{background: '#222'}}>
                  <ReactPlayer url={this.props.imgSrc} playing={false} controls={true} width="400px"/>
                </div>

              :
							<img
								src={this.props.imgSrc}
								alt = ''
							/>
              }
						</div>
					</div>  
				</div>)
				: null
		);
	}
}

export default PreviewImage;
