import React, { Component, lazy, Suspense } from 'react';

import './style.css';
import 'react-infinite-calendar/styles.css';
import noImage from '../../assets/no-image.png'
import Tabs from '@material-ui/core/Tabs'; 
import  Tab  from '@material-ui/core/Tab';
import  Button from '@material-ui/core/Button';
import  CircularProgress from '@material-ui/core/CircularProgress';
import  Snackbar from '@material-ui/core/Snackbar';
import  SnackbarContent from '@material-ui/core/SnackbarContent';
import  Paper from '@material-ui/core/Paper';
import  Chip from '@material-ui/core/Chip';
import  RadioGroup from '@material-ui/core/RadioGroup';
 import FormControlLabel from '@material-ui/core/FormControlLabel';
import  Radio from '@material-ui/core/Radio';
import  Card from '@material-ui/core/Card';
import  CardMedia from '@material-ui/core/CardMedia';
import  CardContent from '@material-ui/core/CardContent';
import  Typography from '@material-ui/core/Typography';

import Api from '../../services/api';
import Moment from 'react-moment';
import constants from '../../services/constants';

const moment = require('moment');

const InfiniteCalendar = lazy(() => import('react-infinite-calendar'));

class SimilarRequests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      creating: false,
      loader: false,
      snackbarOpen: false,
      snackbarContent: {
        variant: '',
        message: ''
      },
      filters: {
        date: {
          fromDate: '',
          toDate: ''
        },
      },
      submitButtondisabled: true,
      similarRequests: [],
      expandRequest: false,
      currentIndex: 0,
      rejectionTemplate: [],
      showRejectionTemplate: false,
      inProgress: false
    };
  }
  componentDidMount() {
    this.getSimilarRequest();
    this.getRejectionTemplate();
  }

  getRejectionTemplate = () => {
    Api.getAppSettings().then(res => {
      return res.json();
    }).then(res => {
      this.setState({ 
        rejectionTemplate: res.data[0].rejectionTemplate
       });
    });
  }

  hideCalendar = (e) => {
    const { filters } = this.state;
    if (e.target.className === 'calendar-wrapper') {
      this.setState({ showCalendar: false });
    }
    if (filters.date.fromDate.length > 0 && filters.date.toDate.length > 0) {
      this.setState({
        submitButtondisabled: false
      })
    }
  };

  dateSelected = (date, showCalendarFor) => {

    let { filters } = this.state;
    date = new Date(date).toISOString();

    if (showCalendarFor === 'fromDate') {

      filters.date.fromDate = date;
      this.setState({ filters, showCalendar: true });
    } else if (showCalendarFor === 'toDate') {

      this.setState({ toDate: date, showCalendar: true });
      filters.date.toDate = date;
      this.setState({ filters, showCalendar: true });
    } else {
      // nothing

    }
  }

  renderCalendar = () => {
    const { showCalendarFor } = this.state;
    return (
      <div className='calendar-wrapper' onClick={(e) => this.hideCalendar(e)}>
        <Suspense fallback={<p>Loading Calendar...</p>}>
          <InfiniteCalendar
            selected={false}
            onSelect={(e) => { this.dateSelected(e, showCalendarFor); }}
          />
        </Suspense>
      </div>
    );
  }

  groupSimilarRequests = () => {
    this.setState({
      creating: true
    });
    Api
      .groupSimilarRequests(this.state.filters)
      .then(res => {
        if (res) {
          this.setState({
            creating: false,
            snackbarOpen: true,
            snackbarContent: {
              variant: 'success-bg-color',
              message: res.mssg
            }
          });
        }
      });
  }

  closeSnackbar = () => {
    this.setState({ snackbarOpen: false });
  }

    getSimilarRequest = () => {
    this.setState({ activeTab: 1, loader: true });
    Api
    .showSimilarRequests()
    .then(res => {
      if (res.success) {

        let data = res.data.filter(data => data.length);
        this.setState({ 
          similarRequests: data, 
          loader: false
        });
      } else {

        this.setState({ 
          similarRequests: res.data, 
          loader: false,
          snackbarOpen: true,
          snackbarContent: {
            variant: 'danger-bg-color',
            message: res.mssg
          }
        });
      }
    });
  }

  handleDetailedView = (index) => {
    this.setState({ expandRequest: true, currentIndex: index });
  }

  removeDifferentRequest = (id) => {
    let { similarRequests, currentIndex } = this.state;
    similarRequests[currentIndex] = similarRequests[currentIndex].filter(req => req._id !== id);
    this.setState({ similarRequests });
  }

  detailedView = (index) => {
    let { similarRequests } = this.state;
    let currentRequest = similarRequests[index];

    return(
      <div className='detailed-view-wrapper'>
        <Paper className='detailed-view'>
          <div className='action-bar'>
            <h2>Detailed View</h2>
            {
              currentRequest.length > 0 ? 
              <React.Fragment>
                <Button 
                  color='primary'
                  onClick={() => this.acceptRequest(index)}>
                  Accept
                </Button>
                <Button 
                  color='secondary'
                  onClick={() => this.setState({ showRejectionTemplate: true })}>
                  Reject
                </Button>
              </React.Fragment>
              : ''
            }
            <Button onClick={() => this.setState({ expandRequest:false })}>
            <i className='material-icons' >
              cancel
            </i>
            </Button>
          </div> 
          <div className='detailed-view-images-wrapper'>
            { currentRequest.length ?
              currentRequest.map((request, index) => 
              <Card key={request._id} className='detailed-view-images'>
                <i 
                  className='material-icons' 
                  onClick={() => this.removeDifferentRequest(request._id)}>
                  cancel
                </i>
                <CardMedia 
                style={{height: 0,
                  paddingTop: '56.25%',}}
                image={request.images[0].path}
                 />
                <Chip 
                  className='bottom-left-chip'
                  label={<Moment format='DD MMM YYYY'> 
                    { request.createdAt }
                    </Moment>} 
                  color='secondary'>
                </Chip>
                <Chip
										className="bottom-right-chip"
										label= {`${request.preferredLanguage === "en" ?  "EN" : "HI"}`}
										color={`${request.preferredLanguage === "en" ?  "primary" : "secondary" }`}
									/>
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {request.description ? request.description : '' }
                  </Typography>
                </CardContent>
              </Card>
              )
              : 
							<div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex', width: '100%' }}>
								<h2 className='empty-list'>
									<i className='material-icons'>error</i>
									No Data Found To Show
              </h2>
							</div>
            }
          </div>
        </Paper>  
      </div>
    );
  }

  handleReasonChange = (e) => {
    let value = e.target.value;
    let body = value.split('/')[0];
    let bodyHi = value.split('/')[1];
    let { similarRequests, currentIndex } = this.state;
    let ids = similarRequests[currentIndex]
              .map(request => {
                return {id: request._id}
              });
    let reason = { body, bodyHi };
    this.setState({ showRejectionTemplate: false, expandRequest: false, inProgress: true });

    Api
    .sendRejectBulkAction(ids, reason)
    .then(res => res.json())
    .then(res => {
      if(res.success) {
        this.setState({
          snackbarOpen: true,
          snackbarContent: {
            variant: 'success-bg-color',
            message: res.mssg
          },
          inProgress: false
        });
        this.getSimilarRequest();
      }
    });
  }

  acceptRequest = (index) => {
    this.setState({ inProgress: true, showRejectionTemplate: false });
    let { similarRequests } = this.state;
    let ids = similarRequests[index]
              .map(request => {
                return {id: request._id}
              });

    Api
    .sendAcceptBulkAction(ids, constants.ACCEPTED_REQUESTS)
    .then(res => res.json())
    .then(res => {
      if(res.success) {
        this.setState({
          snackbarOpen: true,
          snackbarContent: {
            variant: 'success-bg-color',
            message: res.mssg
          },
          inProgress: false,
          showRejectionTemplate: false,
          expandRequest: false
        });
        this.getSimilarRequest();
      } else {
        this.setState({
          snackbarOpen: true,
          snackbarContent: {
            variant: 'danger-bg-color',
            message: 'Something went wrong'
          },
          inProgress: false,
          expandRequest: false
        });
        this.getSimilarRequest();
      }
    });
  }

  render() { 
    let { activeTab,
          loader, 
          snackbarOpen, 
          snackbarContent, 
          similarRequests, 
          creating, 
          expandRequest, 
          currentIndex,
          showRejectionTemplate,
          rejectionTemplate,
          submitButtondisabled
        } = this.state;
    return ( 
      <div className="similar-request-container">
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => this.closeSnackbar()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SnackbarContent
            className={snackbarContent.variant}
            message={snackbarContent.message} />
        </Snackbar>
        <Tabs
          className='fixed-tabs'
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChange}>
          <Tab label="Create" onClick={() => this.setState({ activeTab: 0 })}/>
          <Tab label="Show Similar Requests" onClick={this.getSimilarRequest}/>
        </Tabs>
        <div style={{marginTop: '40px', height: '90vh',overflow: 'scroll'}}>
          {
            activeTab === 0 ? (
              <div className='group-similar-requests'>
                <div className='date-filter'>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <label style={{margin: '0 10px'}}>From:</label>
                    <input
                      type='text'
                      onChange={() => console.log('')}
                      onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'fromDate' })}
                      placeholder='from'
                      value={this.state.filters.date.fromDate !== '' ? moment(this.state.filters.date.fromDate).format('DD/MMM/YYYY') : ''}
                    />
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                  <label style={{margin: '0 10px'}}>To:</label>
                    <input
                      type='text'
                      onChange={() => console.log('')}
                      onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'toDate' })}
                      placeholder='to'
                      value={this.state.filters.date.toDate !== '' ? moment(this.state.filters.date.toDate).format('DD/MMM/YYYY') : ''}
                    />
                  </div>
                </div>
                <Button
                  variant='contained'
                  onClick={this.groupSimilarRequests}
                  color='primary'
                  disabled={submitButtondisabled || creating}
                  className='big-btn'>
                  {creating ?
                    <div><CircularProgress /> <p>Grouping...</p> </div>
                    :
                    'Group Similar Requests'
                  }
                </Button>
              </div>
            ) : null
          }
                     
          {
            activeTab === 1 
            ?
					  <div className='similar-request-wrapper' >
						{ 
              (loader=== false) ? 
              similarRequests.length ?
              similarRequests.map((arr, index) => 
                arr.length ? 
                (
                  <div className='blocks' key={index}>
                    <div className='card-action-bar'>
                      <Button 
                        color='primary'
                        onClick={() => this.acceptRequest(index)}>Accept</Button>
                      <Button 
                        color='secondary'
                        onClick={() => this.setState({ showRejectionTemplate: true, currentIndex: index })}>
                        Reject
                      </Button>
                      <Button onClick={() => this.handleDetailedView(index)}>Requests: {arr.length} </Button>
                    </div>
                    <div className='overlapped'>
                    {
                      arr.length > 0 ?
                      <img src={ arr[0].images[0].path} />
                      : null
                    }
                    </div>
                  </div>
                )
                : 
                null
              )
              : 
              <h2 className='empty-list'>
                <i className='material-icons'>error</i>
                Nothing Here
              </h2>
              : 
              <h2 className='empty-list'>
                <i className='material-icons'>error</i>
                Nothing Here
              </h2>
            }
            </div>
            : 
            null
        }
          
          {
            this.state.showCalendar ?
              <div>
                {this.renderCalendar()}
              </div>
              :
              null
          }
        </div>
        {
          expandRequest ? this.detailedView(currentIndex) : null
        }
        {
          showRejectionTemplate ? 
          <div className='rejection-template-wrapper'>
              <Paper className='rejection-template-card'>
                <div className='action-bar'>
                  <h3>Rejection Reason</h3>
                  <Button color='secondary' onClick={() => this.setState({ showRejectionTemplate: false })}>
                    <i className='material-icons'>cancel</i>
                  </Button>
                </div>
                <RadioGroup style={{display: 'flex'}} name="rejection-reason" onChange={this.handleReasonChange}>
                  {
                    rejectionTemplate.map((template, index) => 
                      <FormControlLabel
                       key={index} 
                       value={template.body+'/'+template.bodyHi} 
                       control={<Radio />} 
                       label={<p>{template.body} <br/> {template.bodyHi}</p>} 
                      />
                    )
                  }
                </RadioGroup>  
              </Paper>

          </div>  
          :
          null
        }
        {
          this.state.inProgress ?
          <div className='overlay-loader' style={{position: 'fixed'}}>
            <CircularProgress />
          </div>
          :
          null
        }
      </div> 
    );
  }
}

export default SimilarRequests;