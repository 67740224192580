import React, { Component, lazy, Suspense } from 'react';
import './style.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Moment from 'react-moment';
import Api from '../../services/api';
import ReactPlayer from 'react-player';
import SuggestedFactchecks from '../suggested-factchecks';
import FcByKeywords from '../fcByKeyWord';
import SelectedReqBulkMerge from '../selectedReqBulkMerge';
import ReactPaginate from 'react-paginate';
import PreviewImage from '../preview-image';
import constants from '../../services/constants';
import ConfirmationPage from '../confirmation-page';
import LSD from './../LSD/index';
const moment = require('moment');
const InfiniteCalendar = lazy(() => import('react-infinite-calendar'));

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentIndex: 0,
      preview: false,
      imageStatus: 'loading',
      listLoader: false,
      showSuggestedFactchecks: false,
      searchFactcheckByKeywords: false,
      mergeStatus: false,
      openSnackbar: false,
      snackbarMessage: '',
      snackbarClass: '',
      dataToDisplay: [],
      pageSize: 20,
      showFilters: false,
      numOfRequest: 0,
      showImageModal: false,
      selectedImage: '',
      tempData: [],
      rejectionTemplate: [],
      filterBy: 'none',
      filters: {
        date: {
          status: false,
          fromDate: '',
          toDate: ''
        },
        lang: {
          status: false,
          en: false,
          hi: false
        },
        sort: {
          status: false,
        },
        type: {
          status: false,
          sortByImage: false,
          sortByVideo: false,
        },
        sortByCreatedAt: false,
        searchKeyword: '',
      },
      anchorEl: null,
      checkedBulkItem: [],
      showSimilarRequestModel: false,
      showSelectedRequest : false,
      selectedRequest : [],
      requestType: '',
      currentRequest: [],
      sortAnchorEl: 1,
      bulkDialogBox: false,
      bulkCurrentArticle: [],
      isBulk: false,
      liteSeedDataActive: false,
    };
  }

  updateCurrentArticle = (currentArticleData) => {
    this.setState({
      bulkCurrentArticle: currentArticleData
    })
  }

  hideImageModal = () => {
    this.setState({ showImageModal: false });
  }

  handleImageLoad = () => {
    this.setState({ imageStatus: 'loaded' });
  }

  componentDidMount() {
    this.getAcceptedRequests();
    Api.getAppSettings()
      .then(res => {
        return res.json();
      }).then(res => {
        if (res.data.length) {
          let rejectionTemplate = res.data[0].rejectionTemplate;
          this.setState({ rejectionTemplate });
        }
      });
    if (!'IntersectionObserver' in window &&
      !'IntersectionObserverEntry' in window &&
      !'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
      // load polyfill for lazyload video
      alert('Upgrade your browser or switch to google chrome.');
    } else {
    }
  }

  getAcceptedRequests = () => {
    this.setState({ listLoader: true, preview: false });
    this.applyFilters();
  }

  handleLangFilter = () => {
    let { filters } = this.state;
    filters.lang.status = !filters.lang.status;
    if (!filters.lang.status) {
      filters.lang.en = false
      filters.lang.hi = false
    }
    this.setState({ filters });
  }

  handleTypeFilter = () => {
    let { filters } = this.state;
    filters.type.status = !filters.type.status;
    if (!filters.type.status) {
      filters.type.sortByImage = false;
      filters.type.sortByVideo = false;
    }
    this.setState({ filters })
  }

  handleSortFilter = () => {
    const { filters } = this.state;
    const sortfilter = { ...filters.sort };
    sortfilter.status = !filters.sort.status;
    this.setState({ filters: { ...this.state.filters, sort: sortfilter } })
  }

  handleDateFilter = () => {
    let { filters } = this.state;
    filters.date.status = !filters.date.status;
    this.setState({ filters });
  }

  showPreview = (index) => {
    this.setState({ currentIndex: index, preview: true, imageStatus: 'loading' });
  }

  closeSuggestedFactchecks = () => {
    this.setState({ showSuggestedFactchecks: false });
  }

  closeSearchFactcheckByKeywords = () => {
    this.setState({ searchFactcheckByKeywords: false });
  }

  setMergeStatus = (status, msg, classname) => {
    let { snackbarClass, snackbarMessage } = this.state;
    if (status) {
      snackbarClass = classname ? classname : 'custom-snackbar-success';
      snackbarMessage = msg ? msg : 'Merge Success';
    } else {
      snackbarClass = classname ? classname : 'custom-snackbar-danger';
      snackbarMessage = msg ? msg : 'Merge Failed';
    }
    this.setState({ snackbarClass, snackbarMessage, openSnackbar: true });
    this.getAcceptedRequests();
  }

  closeSnackbar = () => {
    this.setState({ openSnackbar: false, mergeStatus: '' });
  }

  renderCalendar = () => {
    let { showCalendarFor } = this.state;
    let today = new Date();
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return (
      <div className='calendar-wrapper' onClick={(e) => this.hideCalendar(e)}>
        <Suspense fallback={<p>Loading Calendar...</p>}>
          <InfiniteCalendar
            selected={false}
            onSelect={(e) => { this.dateSelected(e, showCalendarFor); }}
          />
        </Suspense>
      </div>
    );
  }

  clearFilters = () => {
    const filters = {
      date: {
        status: false,
        fromDate: '',
        toDate: ''
      },
      lang: {
        status: false,
        en: false,
        hi: false
      },
      sort: {
        status: false,
      },
      type: {
        status: false,
        sortByImage: false,
        sortByVideo: false
      },
      sortByCreatedAt: false,
      searchKeyword: ''
    };
    let pageNum = 1;
    this.setState({
      filters,
      sortAnchorEl: 1,
      pageNum
    }, () => this.getAcceptedRequests());
  }

  hideCalendar = (e) => {
    if (e.target.className === 'calendar-wrapper') {
      this.setState({ showCalendar: false });
    }
  };

  handleFilterCheckbox = (e) => {
    let lang = e.target.value;
    let { filters } = this.state;
    filters.lang[lang] = !filters.lang[lang];
    this.setState({ filters });
  }

  handleChangeTypeFilter = (e) => {
    let { filters } = this.state;
    if (e.target.value === "video")
      filters.type.sortByVideo = filters.type.sortByVideo ? false : true
    else
      filters.type.sortByImage = filters.type.sortByImage ? false : true
    this.setState({
      filters
    })
  }

  renderFilters = () => {
    let { filterBy, filters, sortAnchorEl } = this.state;
    return (
      <div className='filter-wrapper'>
        <div className='date-filter'>
          <div>
            <FormControlLabel
              control={<Switch
                checked={this.state.filters.date.status}
                onChange={this.handleDateFilter}
                value="checkedA"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Date'
            />
          </div>
          <div>
            <input
              disabled={!filters.date.status}
              style={{ cursor: filters.date.status ? 'default' : 'not-allowed' }}
              type='text'
              onChange={() => console.log('')}
              onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'fromDate' })}
              placeholder='from'
              value={this.state.filters.date.fromDate !== '' ? moment(this.state.filters.date.fromDate).format('DD/MM/YYYY') : ''}
            />
          </div>
          <div>
            <input
              disabled={!filters.date.status}
              style={{ cursor: filters.date.status ? 'default' : 'not-allowed' }}
              type='text'
              onChange={() => console.log('')}
              onClick={() => this.setState({ showCalendar: true, showCalendarFor: 'toDate' })}
              placeholder='to'
              value={this.state.filters.date.toDate !== '' ? moment(this.state.filters.date.toDate).format('DD/MM/YYYY') : ''}
            />
          </div>
        </div>
        <div className='lang-filter'>
          <div>
            <FormControlLabel
              control={<Switch
                checked={this.state.filters.lang.status}
                onChange={this.handleLangFilter}
                value="checkedA"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Language'
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox
                disabled={!this.state.filters.lang.status}
                color='primary'
                checked={this.state.filters.lang.en}
                onChange={this.handleFilterCheckbox}
                value='en'
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='English'
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox
                disabled={!this.state.filters.lang.status}
                color='primary'
                checked={this.state.filters.lang.hi}
                onChange={this.handleFilterCheckbox}
                value='hi'
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Hindi'
            />
          </div>
        </div>
        <div className="sort-filter">
          <FormControlLabel
            control={<Switch
              checked={filters.sort.status}
              onChange={this.handleSortFilter}
              value="checkedA"
              inputProps={{
                'aria-label': 'sort checkbox',
              }}
            />}
            label='Sort By'
          />
          <FormControlLabel
            control={
              <Box>
                <FormControl variant="outlined" style={{ minWidth: '120px' }}>
                  <InputLabel htmlFor="outlined-sort-native-simple">
                    Sort By
                  </InputLabel>
                  <Select
                    native
                    disabled={!filters.sort.status}
                    value={sortAnchorEl}
                    onChange={(e) => this.updateSortByFilterStatus(e)}
                    inputProps={{
                      name: 'sort',
                      id: 'outlined-sort-native-simple',
                    }}
                  >
                    <option value={1}>Updated At</option>
                    <option value={0}>Created At</option>
                  </Select>
                </FormControl>
              </Box>
            }
          />
        </div>
        <div className="type-filter" style={{ display: 'flex' }}>
          <div>
            <FormControlLabel
              control={<Switch
                checked={filters.type.status}
                onChange={this.handleTypeFilter}
                value="checkedA"
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />}
              label='Type'
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <FormControlLabel
                control={<Checkbox
                  disabled={!filters.type.status}
                  color='primary'
                  checked={filters.type.sortByVideo}
                  onChange={(e) => this.handleChangeTypeFilter(e)}
                  value='video'
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />}
                label='Video'
              />
            </div>
            <div>
              <FormControlLabel
                control={<Checkbox
                  disabled={!filters.type.status}
                  color='primary'
                  checked={filters.type.sortByImage}
                  onChange={(e) => this.handleChangeTypeFilter(e)}
                  value='image'
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />}
                label='Image'
              />
            </div>
          </div>
        </div>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            onClick={this.clearFilters}
            variant='contained'
            color='default'>
            Clear All
          </Button>
          <Button
            disabled={!(this.state.filters.date.status || this.state.filters.lang.status || this.state.filters.sort.status || this.state.filters.type.status)}
            onClick={this.applyFilters}
            variant='contained'
            color='primary'>
            Apply
          </Button>
        </div>
      </div>
    );
  }

  renderPager = () => {
    let pages = this.state.numOfRequest / this.state.pageSize;
    return (<ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={this.changePage}
      containerClassName={'pagination'}
      subContainerClassName={'paginate'}
      activeClassName={'active'}
      initialPage={0}
    />);
  }

  changePage = (e) => {
    let selectedPage = e.selected + 1;
    let lastPage = selectedPage;
    let pageSize = this.state.pageSize;
    lastPage = pageSize * lastPage;
    let firstPage = lastPage - pageSize;
    this.setState({
      pageNum: selectedPage,
    }, () => {
      this.getAcceptedRequests()
      this.handleCheckedBulkItem()
    });
  }

  onSearchKeywordChange = (e) => {
    let searchKeyword = e.target.value.toLowerCase();
    let { filters } = this.state;
    filters.searchKeyword = searchKeyword;
    this.setState({ searchKeyword });
  }

  searchDashboardRequests = (e) => {
    let { searchKeyword } = this.state;
    let { filters } = this.state;

    if (filters.searchKeyword.length >= 1 && e.nativeEvent.key === 'Enter') {
      this.getAcceptedRequests();
    }
    if (searchKeyword.length === 0) {
      this.clearSearchField();
    }
  }

  clearSearchField = () => {
    let { filters } = this.state;
    filters.searchKeyword = '';
    this.setState({ filters });
    this.getAcceptedRequests();
  }

  moveToManageRequest = () => {
    let { dataToDisplay, currentIndex, data } = this.state;
    Api
      .moveToManageRequest(dataToDisplay[currentIndex]._id, '')
      .then(response => {
        return response.json();
      })
      .then(res => {
        if (res.success) {
          let filterDataToDisplay = dataToDisplay.filter(item => item._id !== dataToDisplay[currentIndex]._id, '');
          let filterData = data.filter(item => item._id !== dataToDisplay[currentIndex]._id, '');

          this.setState({
            dataToDisplay: filterDataToDisplay,
            preview: false,
            data: filterData,
            numOfRequest: filterData.length
          });
        }
      });
  }

  applyFilters = async (asyncLoad = false) => {
    let { filters, pageSize, pageNum } = this.state;
    // filter validations
    let fromDate = moment(filters.date.fromDate);
    let toDate = moment(filters.date.toDate);
    let numDays = fromDate.diff(toDate, 'days');
    let validation = 0;

    if (filters.date.status) {
      if (numDays >= 0) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : End date should be greater than start date',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
      if (isNaN(numDays)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : Choose Dates',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
    }
    if (filters.lang.status) {
      if (!(filters.lang.en || filters.lang.hi)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: 'Error : Atleast choose one language',
          snackbarClass: 'custom-snackbar-danger'
        });
        ++validation;
      }
    }

    if (validation === 0) {
      if (!asyncLoad)
        this.setState({ dataToDisplay: [] });
      // status = Pending or accepted
      let res = await Api.getFilteredRequests(constants.ACCEPTED_REQUESTS, filters, pageSize, pageNum);

      this.setState({
        data: res.data,
        tempData: res.data,
        numOfRequest: res.numRecords,
        dataToDisplay: res.data,
        preview: false,
        requestCount: res.numRecords,
        listLoader: false
      });
    }
  }

  dateSelected = (date, showCalendarFor) => {

    let { filters } = this.state;
    date = new Date(date).toISOString();

    if (showCalendarFor === 'fromDate') {

      filters.date.fromDate = date;
      this.setState({ filters, showCalendar: true });
    } else if (showCalendarFor === 'toDate') {

      this.setState({ toDate: date, showCalendar: true });
      filters.date.toDate = date;
      this.setState({ filters, showCalendar: true });
    } else {
      // nothing

    }
  }

  handleRejectMenuClose = () => {
    Api.sendAcceptBulkAction(this.state.checkedBulkItem, constants.PENDING_REQUESTS).then(response => {
      return response.json();
    }).then(res => {
      if (res.success) {
        this.handleCheckedBulkItem()
        this.applyFilters(true);
      }
      else{
      }
    }).catch(err => {
    });
  }

  handleBulkMergeRequest = (checkedBulkItem) => {
    this.setState({ 
      showSelectedRequest : !this.state.showSelectedRequest,
    })

    let selectedReq = [];
    this.state.dataToDisplay.map((item, index)=>{
      checkedBulkItem.map((req,index)=>{
        if(req.id === item._id)
        selectedReq.push(item)
      })
    })
    this.setState({ 
      selectedRequest: selectedReq 
     })

    }


  closeRequest = (id) => {
    const { selectedRequest } = this.state;
    const filteredRequest = selectedRequest.filter(item => item._id !== id);
    this.setState({ selectedRequest: filteredRequest})
  
  }

  renderSelectedRequestDetailedView = () => {

    return (
      <div className='detailed-view-wrapper'>
        <div className='detailed-view' style={{ backgroundColor: '#fff', margin: '40px', borderRadius: '5px', height: 'auto' }}>
          <div className='action-bar'>
            <div style={{ width: '30%', fontSize: '24px' }}>Detailed View</div>
            {
              this.state.selectedRequest.length > 0 ?
                <div style={{ width: '50%' }}>
                  {
                    this.state.selectedRequest ?
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          color='primary'
                          style={{ width: '36%' }}
                          onClick={() => this.addLiteSeedData()}>
                          Add Lite Seed Data
                        </Button>
                        <Button
                          color='primary'
                          style={{ lineHeight: 'normal', width: '18%' }}

                          onClick={() => this.setState({
                            searchFactcheckByKeywords: true,
                            showSelectedRequest : false
                          })}
                        >
                          Next
                        </Button>
                      </div>
                      : this.state.selectedRequest.status.toLowerCase() === "pending" ?
                        <Button
                          color='primary'
                          style={{ lineHeight: 'normal' }}
                          variant="contained"
                          onClick={this.acceptSimilarRequest}
                        >
                          Accept
                        </Button>
                        : ''
                  }
                </div>
                : ''
            }
            <Button onClick={() => this.setState({ showSelectedRequest: false })}>
              <i className='material-icons' >
                cancel
              </i>
            </Button>
          </div>
          <Paper className='detailed-view-images-wrapper' style={{ boxShadow: 'none', height: '70vh', overflow: 'auto' }}>
            {
              this.state.selectedRequest.length > 0 ?
                this.state.selectedRequest.map((request, index) =>
                  <Card key={request._id} className="detailed-view-images">
                    <i
                      className='material-icons'
                      style={{ zIndex: 3 }}
                      onClick={() => this.closeRequest(request._id)}>
                      cancel
                  </i>
                    {
                      request.images.length > 0 ?
                        <CardMedia
                          style={{
                            height: 0,
                            paddingTop: '56.25%',
                          }}
                          image={request.images[0].path}
                        />
                        :
                        <ReactPlayer
                          className='lazy-vid'
                          style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
                          key={index}
                          url={request.videos[0].path}
                          playing={false}
                          controls={true}
                          width='100%'
                          height='250px'
                        />
                    }
                    <Chip
                      className='bottom-left-chip'
                      style={{ zIndex: 3 }}
                      label={<Moment format='DD MMM YYYY'>
                        {request.createdAt}
                      </Moment>}
                      color='secondary'
                    />
                    <Chip
                      className="bottom-right-chip"
                      label={`${request.preferredLanguage === "en" ? "EN" : "HI"}`}
                      color={`${request.preferredLanguage === "en" ? "primary" : "secondary"}`}
                    />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {request.description ? request.description : ''}
                      </Typography>
                    </CardContent>
                  </Card>)
                :
                <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex', width: '100%' }}>
                  <h2 className='empty-list'>
                    <i className='material-icons'>error</i>
                  No Data Found To Show
                </h2>
                </div>
            }
          </Paper>
        </div>
        {
          this.state.liteSeedDataActive === true &&
          <LSD currentRequest={this.state.selectedRequest}
               addLiteSeedData={this.addLiteSeedData}
               showSelectedRequest = {this.state.showSelectedRequest}
          />
        }
      </div>
    )
  }
  
  handleListItemCheckbox = (e) => {
    e.stopPropagation();
    const _id = e.target.getAttribute('aria-label');
    const data = this.state.checkedBulkItem.find(item => {
      return item.id === _id
    })
    if (data !== undefined) {
      const id = data.hasOwnProperty('id') ? data.id : undefined
      const newData = this.state.checkedBulkItem.filter(item => item.id !== id)
      this.setState({
        checkedBulkItem: newData
      })
    } else {
      this.setState({
        checkedBulkItem: [
          ...this.state.checkedBulkItem,
          { id: _id }
        ]
      })
    }
  }

  handleCheckedBulkItem = () => {
    this.setState({
      checkedBulkItem: []
    })
  }


  removeDifferentRequest = (id) => {
    const { currentRequest } = this.state;
    const filteredRequest = currentRequest.filter(item => item._id !== id);
    this.setState({ currentRequest: filteredRequest })
  }

  acceptSimilarRequest = () => {
    this.setState({ showRejectionTemplate: false });
    const { currentRequest } = this.state;
    const ids = currentRequest.map(request => { return { id: request._id } });
    Api
      .sendAcceptBulkAction(ids, constants.ACCEPTED_REQUESTS)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          this.setState({
            snackbarOpen: true,
            snackbarContent: {
              variant: 'success-bg-color',
              message: res.mssg
            },
            searchFactcheckByKeywords: true,
            showRejectionTemplate: false,
            showSimilarRequestModel: false
          }, this.applyFilters);
        } else {
          this.setState({
            snackbarOpen: true,
            snackbarContent: {
              variant: 'danger-bg-color',
              message: 'Something went wrong'
            },
            showSimilarRequestModel: false
          }, this.applyFilters);
        }
      });
  }

  addLiteSeedData = () => {
    this.setState({ liteSeedDataActive: !this.state.liteSeedDataActive })
  }

  renderSimilarRequestDetailedView = () => {
    const { currentRequest } = this.state;
    return (
      <div className='detailed-view-wrapper'>
        <div className='detailed-view' style={{ backgroundColor: '#fff', margin: '40px', borderRadius: '5px', height: 'auto' }}>
          <div className='action-bar'>
            <div style={{ width: '30%', fontSize: '24px' }}>Detailed View</div>
            {
              currentRequest.length > 0 ?
                <div style={{ width: '50%' }}>
                  {
                    currentRequest[0].status.toLowerCase() === "accepted" ?
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          color='primary'
                          style={{ width: '36%' }}

                          onClick={() => this.addLiteSeedData()}>
                          Add Lite Seed Data
                        </Button>
                        <Button
                          color='primary'
                          style={{ lineHeight: 'normal', width: '18%' }}

                          onClick={() => this.setState({
                            showSimilarRequestModel: false,
                            searchFactcheckByKeywords: true
                          })}
                        >
                          Next
                  </Button>
                      </div>
                      : currentRequest[0].status.toLowerCase() === "pending" ?
                        <Button
                          color='primary'
                          style={{ lineHeight: 'normal' }}
                          variant="contained"
                          onClick={this.acceptSimilarRequest}
                        >Accept
                  </Button>
                        : ''
                  }
                </div>
                : ''
            }
            <Button onClick={() => this.setState({ showSimilarRequestModel: false })}>
              <i className='material-icons' >
                cancel
              </i>
            </Button>
          </div>
          <Paper className='detailed-view-images-wrapper' style={{ boxShadow: 'none', height: '70vh', overflow: 'auto' }}>
            {
              currentRequest.length > 0 ?
                currentRequest.map((request, index) =>
                  <Card key={request._id} className="detailed-view-images">
                    <i
                      className='material-icons'
                      style={{ zIndex: 3 }}
                      onClick={() => this.removeDifferentRequest(request._id)}>
                      cancel
                  </i>
                    {
                      request.images.length > 0 ?
                        <CardMedia
                          style={{
                            height: 0,
                            paddingTop: '56.25%',
                          }}
                          image={request.images[0].path}
                        />
                        :
                        <ReactPlayer
                          className='lazy-vid'
                          style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
                          key={index}
                          url={request.videos[0].path}
                          playing={false}
                          controls={true}
                          width='100%'
                          height='250px'
                        />
                    }
                    <Chip
                      className='bottom-left-chip'
                      style={{ zIndex: 3 }}
                      label={<Moment format='DD MMM YYYY'>
                        {request.createdAt}
                      </Moment>}
                      color='secondary'
                    />
                    <Chip
                      className="bottom-right-chip"
                      label={`${request.preferredLanguage === "en" ? "EN" : "HI"}`}
                      color={`${request.preferredLanguage === "en" ? "primary" : "secondary"}`}
                    />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {request.description ? request.description : ''}
                      </Typography>
                    </CardContent>
                  </Card>)
                :
                <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex', width: '100%' }}>
                  <h2 className='empty-list'>
                    <i className='material-icons'>error</i>
                  No Data Found To Show
                </h2>
                </div>
            }
          </Paper>
        </div>
        {
          this.state.liteSeedDataActive === true &&
          <LSD currentRequest={this.state.currentRequest}
              addLiteSeedData={this.addLiteSeedData}
          />
        }
      </div>
    )
  }

  showSimilarRequest = (imageDetail, videoDetail, requestType) => {
    const isImage = imageDetail.length > 0 ? 1 : 0;
    const paHashOrMD5 = isImage ? imageDetail[0].pHash : videoDetail[0].md5;
    const typeOfMedia = imageDetail.length > 0 ? 'image' : videoDetail.length > 0 ? 'video' : undefined;
    const reqType = requestType.toLowerCase() === "pending" ? constants.PENDING_REQUESTS : constants.ACCEPTED_REQUESTS;
    if (paHashOrMD5)
      Api.getSimilarRequestHash(paHashOrMD5, reqType, typeOfMedia).then(res => {
        if (res.success) {
          const data = res.similarRequests ? res.similarRequests : res.data;
          this.setState({
            anchorEl: null,
            currentRequest: data,
            showSimilarRequestModel: true
          })
        } else {
          console.error('Error Fetching Request Model Similar Request');
        }
      });
    else {
      this.setState({
        anchorEl: null,
        currentRequest: [],
        showSimilarRequestModel: true
      })
    }
  }

  updateSimilarRequestDropdownStatus = (e) => {
    this.setState({
      anchorEl: e ? e.currentTarget ? e.currentTarget : null : null,
      isBulk: true
    })
  }

  updateSortByFilterStatus = (e) => {
    this.setState({
      sortAnchorEl: !Number(e.target.value) ? Number(e.target.value) : 1,
      filters: {
        ...this.state.filters,
        sortByCreatedAt: !Number(e.target.value) ? true : false
      },
    })
  }

  openBulkConfirmationDialog = (val) => {
    this.setState({
      bulkDialogBox: val,
      searchFactcheckByKeywords: false
    })
  }

  render() {
    const {
      currentIndex,
      preview,
      dataToDisplay,
      showFilters,
      checkedBulkItem,
      currentRequest,
      bulkDialogBox,
      searchFactcheckByKeywords,
      bulkCurrentArticle,
      anchorEl,
      showSimilarRequestModel,
      isBulk,
      showSelectedRequest
    } = this.state;

    return (
      <ClickAwayListener onClickAway={() => this.updateSimilarRequestDropdownStatus(null)}>
        <div className="dashboard-wrapper">
          <div className='dashboard-action-wrapper'>
            <div style={{ flex: 1 }}>
              <div className="top-container">
                <div
                  className={showFilters ? 'show-filters active' : 'show-filters'}
                  onClick={() => this.setState({ showFilters: !showFilters })}
                >
                  <span>
                    <i style={{ verticalAlign: 'middle' }} className='material-icons'>filter_list</i>
                    <span style={{ verticalAlign: 'middle' }} >Filters </span>
                  </span>
                </div>
                <TextField
                  label='Type keyword & press return to search'
                  variant='outlined'
                  className='dashboard-search-field'
                  value={this.state.filters.searchKeyword}
                  onChange={this.onSearchKeywordChange}
                  onKeyUp={this.searchDashboardRequests}
                  placeholder='Type keyword and press return'
                />
                {
                  this.state.filters.searchKeyword.length ?
                    <Button
                      variant="contained"
                      onClick={this.clearSearchField}
                      style={{ height: '45px', marginLeft: '10px', alignSelf: 'center' }}
                    >
                      Clear
                    </Button>
                    : null
                }
                <div
                  className='pagination-wrapper'
                  style={{ padding: '15px 0px', alignSelf: 'flex-end' }}
                >
                  {this.renderPager()}
                </div>
                <div style={{ padding: '10px', lineHeight: '40px' }}> Requests: {this.state.numOfRequest} </div>
              </div>
              <div className="filter-section bottom-container">
                {
                  showFilters ? this.renderFilters() : null
                }
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.openSnackbar}
            autoHideDuration={6000}
            onClose={this.closeSnackbar}
          >
            <SnackbarContent
              className={this.state.snackbarClass}
              message={this.state.snackbarMessage}
            />
          </Snackbar>
          {
            this.state.listLoader ?
              <div style={{ textAlign: 'center', padding: '10px', width: '100%' }}>
                <CircularProgress color="secondary" />
              </div>
              :
              <div className="list-wrapper">
                {
                  checkedBulkItem.length > 0 ?
                    <Box className="bulk-action-container">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: 'white', fontSize: '10px' }}
                        onClick={this.handleRejectMenuClose}
                      >
                        <i className="material-icons">autorenew</i>
                        {' '}
                        Move Back
  								    </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: 'white', fontSize: '10px' }}
                        onClick={() =>this.handleBulkMergeRequest(checkedBulkItem)}
                      >
                        {' '}
                        Bulk Merge
  								    </Button>
                      <Button
                        variant="contained"
                        onClick={this.handleCheckedBulkItem}
                      >
                        Clear
                      </Button>
                    </Box>
                    : ''
                }
                <div className={showFilters ? "withFilterWrapper" : "withoutFilterWrapper"} >
                  <List>
                    {this.state.dataToDisplay.map((item, index) => {
                      return (
                        <ListItem
                          key={index}
                          style={{ backgroundColor: index === currentIndex ? '#e8eaf6' : '' }}
                          onClick={() => this.showPreview(index)}>
                          <Checkbox
                            checked={checkedBulkItem.length > 0 ? checkedBulkItem.find((itemId) => itemId.id === item._id ? true : false) : false}
                            onChange={(e) => this.handleListItemCheckbox(e)}
                            onClick={(e) => e.stopPropagation()}
                            value={`checkedItem${index}`}
                            color="primary"
                            inputProps={{
                              'aria-label': item._id,
                            }}
                          />
                          {
                            item.images ?
                              item.images.length ?
                                <img
                                  src={item.images[0].path}
                                  style={{ width: '100px', height: '40px', marginRight: '10px' }}
                                />
                                :
                                item.videos.length ?
                                  <ReactPlayer
                                    url={item.videos[0].path}
                                    width='100px'
                                    height='40px'
                                    style={{ marginRight: '10px', backgroundColor: '#222' }} />
                                  : null
                              : ''
                          }
                          {
                            item.description ? item.description.length ?
                              <div style={{ flex: 1 }} > {item.description.substr(0, 100) + '...'} </div> :
                              <div style={{ color: 'red', fontStyle: 'italic', flex: 1 }} >
                                This request has no text!
                            </div>
                              : <div style={{ color: 'red', fontStyle: 'italic', flex: 1 }} >
                                This request has no text!
                            </div>
                          }
                          <div className="badges-wrapper">
                            <span>
                              CreatedAt: <Moment format="DD MMM YYYY" >{item.createdAt}</Moment>   {"  "}
                              <Moment format="hh:mm:ss a" >{item.createdAt}</Moment>
                              <br />
                              UpdatedAt: <Moment format="DD MMM YYYY" >{item.updatedAt}</Moment>   {"  "}
                              <Moment format="hh:mm:ss a" >{item.updatedAt}</Moment>
                              <br />
                              <span style={{ fontWeight: 'normal' }}>{item.key} </span>
                            </span>
                          </div>
                          <div className="chip-wrapper">
                            {
                              item.preferredLanguage ?
                                <Chip
                                  label={item.preferredLanguage === 'en' ? 'EN' : 'HI'}
                                  color={item.preferredLanguage === 'en' ? 'primary' : 'secondary'}
                                  style={{ float: 'left' }} />
                                : null
                            }
                          </div>
                        </ListItem>)
                    })
                    }
                  </List>
                </div>
              </div>
          }
          {
            preview && dataToDisplay.length ?
              <div className="preview-wrapper">
                <div className="preview">
                  <div className="actions-wrapper">
                    <Box style={{ display: 'flex' }}>
                      <Button
                        style={{ color: 'tomato', fontSize: '10px' }}
                        onClick={() => this.moveToManageRequest()}
                      >
                        <i className="material-icons">autorenew</i>
                        {' '}
                        Move Back
  								    </Button>
                      <Button
                        color="secondary"
                        style={{ fontSize: '10px' }}
                        aria-controls="show-similar-request-menu"
                        aria-haspopup="true"
                        onClick={(e) => this.updateSimilarRequestDropdownStatus(e)}
                      >
                        <i className="material-icons"> search </i>
                        Show Similar Request From
  										</Button>
                      <Menu
                        id="show-similar-request-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.updateSimilarRequestDropdownStatus}
                      >
                        <MenuItem onClick={() => this.showSimilarRequest(dataToDisplay[currentIndex].images, dataToDisplay[currentIndex].videos, "accepted")}>Dashboard</MenuItem>
                        <MenuItem onClick={() => this.showSimilarRequest(dataToDisplay[currentIndex].images, dataToDisplay[currentIndex].videos, "pending")}>Manage Request</MenuItem>
                      </Menu>
                      <Button
                        style={{ fontSize: '10px' }}
                        color="secondary"
                        onClick={() => this.setState({
                          searchFactcheckByKeywords: true,
                        })}
                      >
                        <i className="material-icons">search</i>
                        {' '}
                        Search Fact Checks
  								    </Button>
                      {
                        (!dataToDisplay[currentIndex].videos.length) ?
                          <Button
                            style={{ fontSize: '10px' }}
                            color="primary"
                            onClick={() => this.setState({ showSuggestedFactchecks: true })}
                          >
                            <i className="material-icons">info</i>
                            {' '}
                            Suggested Fact Checks
  										    </Button>
                          : null
                      }
                      <Button onClick={() => this.setState({ preview: false })}>
                        <i className="material-icons danger-text">cancel</i>
                      </Button>
                    </Box>
                  </div>
                  <div className="preview-content">
                    <h2>{dataToDisplay[currentIndex].description}</h2>
                    <h5>
                      Created At:
  									  <Moment format="DD MMM YYYY">
                        {dataToDisplay[currentIndex].createdAt}
                      </Moment>
                    </h5>
                    <h5>
                      Updated At:
  									  <Moment format="DD MMM YYYY">
                        {dataToDisplay[currentIndex].updatedAt}
                      </Moment>
                    </h5>
                    {dataToDisplay[currentIndex].images.map(image =>
                      <div className="img-wrapper" key={image._id}>
                        <img
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({ showImageModal: true, selectedImage: image.path })}
                          src={image.path} alt='' />
                      </div>
                    )}
                    {dataToDisplay[currentIndex].videos.map(vid =>
                      <div className="vid-wrapper" key={vid._id}>
                        <ReactPlayer url={vid.path} playing={false} controls={true} width="550px" />
                      </div>
                    )}
                    <p>{dataToDisplay[currentIndex].sourceUrl}</p>
                  </div>
                </div>
              </div>
              :
              null
          }
          {
            this.state.showSuggestedFactchecks ?
              (<div className="suggested-fc-overlay">
                <div style={{ backgroundColor: '#fff', margin: '40px', borderRadius: '5px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 15px 5px ' }}>
                    <span style={{ fontSize: '25px' }}> Suggested Factchecks </span>
                    <i
                      style={{ fontSize: '30px' }}
                      className="material-icons secondary-color icon-action"
                      onClick={() => this.setState({ showSuggestedFactchecks: false })}
                    >
                      cancel
  								  </i>
                  </div>
                  <SuggestedFactchecks
                    closeModal={this.closeSuggestedFactchecks}
                    dataToCheck={dataToDisplay[currentIndex]}
                    mergeStatus={this.setMergeStatus}
                  />
                </div>
              </div>)
              : null
          }
          {
            searchFactcheckByKeywords ? (
              <div className="suggested-fc-overlay">
                <div style={{ backgroundColor: '#fff', margin: '40px', borderRadius: '5px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 15px 5px ' }}>
                    <span style={{ fontSize: '25px' }}>Search Fact Checks</span>
                    <i
                      style={{ fontSize: '30px' }}
                      className="material-icons secondary-color icon-action"
                      onClick={() => this.setState({
                        searchFactcheckByKeywords: false,
                        isBulk: false
                      })}
                    >
                      cancel
  								</i>
                  </div>
                  {
                    this.state.selectedRequest.length > 1  ?
                    <SelectedReqBulkMerge
                      closeModal={this.closeSearchFactcheckByKeywords}
                      dataToCheck={this.state.selectedRequest}
                      mergeStatus={this.setMergeStatus}
                      openDialog={this.openBulkConfirmationDialog}
                      updateCurrentArticle={this.updateCurrentArticle}
                      isBulk={isBulk}
                    /> 
                    // <div>hey</div>
                    :
                    <div>
                    {
                      !bulkDialogBox ?
                      <FcByKeywords
                        closeModal={this.closeSearchFactcheckByKeywords}
                        dataToCheck={dataToDisplay[currentIndex]}
                        mergeStatus={this.setMergeStatus}
                        openDialog={this.openBulkConfirmationDialog}
                        updateCurrentArticle={this.updateCurrentArticle}
                        isBulk={isBulk}
                      /> : ''}
                      </div>
                  }
                </div>
              </div>
            ) : null
          }
          {
            bulkDialogBox ? <ConfirmationPage
              currentRequest={currentRequest}
              removeDifferentRequest={this.removeDifferentRequest}
              arcticleItem={bulkCurrentArticle}
              mergeStatus={this.setMergeStatus}
              openDialog={this.openBulkConfirmationDialog}
            /> : ''
          }
          <PreviewImage
            hideImageModal={this.hideImageModal}
            showImageModal={this.state.showImageModal}
            imgSrc={this.state.selectedImage}
          />
          {
            this.state.showCalendar ?
              <div>
                {this.renderCalendar()}
              </div>
              :
              null
          }
          {
            showSimilarRequestModel ?
              this.renderSimilarRequestDetailedView() : ''
          }
          {
            showSelectedRequest === true ?
              this.renderSelectedRequestDetailedView() : ''
          }
        </div>
      </ClickAwayListener>
    );
  }
}

export default Dashboard;