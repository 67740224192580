import constants from '../constants';
import axios from 'axios';
import RolesPermission from '../roles-permission';

let { BASE_URL } = constants;

async function verifyJwtToken(payload) {
	let url = BASE_URL + constants.VERIFY_JWT;
	try {
		let response = await
			axios.get(url,
				{
					headers: { 'Authorization': payload }
				}
			)
				.then(function (response) {
					return response;
				})
				.catch(function (error) {
				});
		return response;
	} catch (error) {
		console.error(error);
	}
}

const createSeedData = async (payload) => {

	let url = constants.BASE_URL + constants.CREATE_SEED_DATA;
	try {
		let response = await
			axios.post(url,
				payload,
				{
					headers: {
						'Content-type': 'multipart/form-data',
						'Authorization': RolesPermission.getToken()
					}
				}
			)
				.then(function (response) {
				})
				.catch(function (error) {
				});
		return response;
	} catch (error) {
		console.error(error);
	}
};

const updateSeedData = async (payload, id) => {
	let url = constants.BASE_URL + constants.CREATE_SEED_DATA + '/' + id;
	try {
		let response = await axios.put(url, payload,
			{
				headers: {
					'Authorization': RolesPermission.getToken()
				}
			})
			.then(function (response) {
				return response;
			})
			.catch(function (error) {
				return error;
			});
		return response;
	} catch (error) {
		console.error(error);
	}
};

const createUser = async (userData) => {
	let url = BASE_URL + constants.USER;

	let res = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(userData),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		}
	}).then(res => {
		return res.json();
	});
	return res;
};

const getUserData = async () => {
	let url = BASE_URL + constants.USER;
	let res = await fetch(url,
		{
			headers: {
				'Authorization': RolesPermission.getToken()
			}
		})
		.then(res => { return res.json(); });
	return res;
};

const changeUserStatus = async (userId, isActive) => {
	const url = BASE_URL + constants.USER + `/${userId}`;
	const res = await fetch(url, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		},
		body: JSON.stringify({
			isActive: isActive ? false : true
		})
	}).then(res => res.json())
	return res;
};

const getRequestLog = async (pageSize = 20, pageNum = 1) => {
	let url = `${BASE_URL}${constants.REQUEST_LOG}/${pageSize}/${pageNum}`;
	let res = await fetch(url,
		{
			headers: {
				'Authorization': RolesPermission.getToken()
			}
		});
	// .then(res => {return res.json();});
	return res;
};

const getSeedList = async () => {
	let url = BASE_URL + constants.CREATE_SEED_DATA;
	let res = await fetch(url,
		{
			headers: {
				'Authorization': RolesPermission.getToken()
			}
		})
		.then(res => {
			return res.json();
		});
	return res;
};

async function getRequests() {
	// let url = BASE_URL + constants.USER_REQUESTS + '?key=description';
	let url = BASE_URL + constants.USER_REQUESTS;
	let response = await fetch(url, {
		headers: {
			'Authorization': RolesPermission.getToken()
		}
	});
	return response;
}

async function getAppSettings() {
	let url = BASE_URL + constants.APP_SETTING;
	let response = await fetch(url, {
		headers: {
			'Authorization': RolesPermission.getToken()
		}
	});
	return response;
}

async function acceptRequest(item_id) {
	let url = BASE_URL + constants.USER_REQUESTS + '/' + item_id;
	let response = await fetch(url, {
		method: 'PUT',
		body: JSON.stringify({ status: 'accepted' }),
		headers: { 'Content-Type': 'application/json', 'Authorization': RolesPermission.getToken() },
	});
	return response;
}

async function rejectRequest(item_id, rejectionReason) {
	let url = BASE_URL + constants.USER_REQUESTS + '/' + item_id;
	let response = await fetch(url, {
		method: 'PUT',
		body: JSON.stringify({ status: 'rejected', rejectMessage: rejectionReason }),
		headers: { 'Content-Type': 'application/json', 'Authorization': RolesPermission.getToken() },
	});
	return response;
}

async function moveToManageRequest(item_id, rejectionReason) {
	let url = BASE_URL + constants.USER_REQUESTS + '/' + item_id;
	let response = await fetch(url, {
		method: 'PUT',
		body: JSON.stringify({ status: 'Pending', rejectMessage: rejectionReason }),
		headers: { 'Content-Type': 'application/json', 'Authorization': RolesPermission.getToken() },
	});
	return response;
}

async function updateAppSettings(settingsId, appSettings) {
	let url = BASE_URL + constants.APP_SETTING + '/' + settingsId;

	let response = await fetch(url, {
		method: 'PUT',
		body: JSON.stringify(appSettings),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		}
	});
	return response;
}

async function saveAppSettings(appSettings) {
	let url = BASE_URL + constants.APP_SETTING;
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(appSettings),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		}
	});
	return response;
}

async function login(payload) {
	let url = BASE_URL + constants.LOGIN;
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-type': 'application/json',
			'Authorization': RolesPermission.getToken()
		}
	});
	return response;
}

async function getSeedData() {
	let url = BASE_URL + constants.CREATE_SEED_DATA;
	let response = await fetch(url, {
		headers: {
			'Authorization': RolesPermission.getToken()
		}
	});
	return response;
}

const broadcastPushNotifcation = () => {
};

const getSuggesgtedFactchecks = async (images) => {
	let url = BASE_URL + constants.CREATE_SEED_DATA + '/' + constants.SUGGEST;
	let payload = {
		images
	};
	let res = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		}
	});
	let jsonRes = await res.json();
	return jsonRes;
};

const getFactchecksByKeywords = async (keywords, isLiteSeedData, isMergingReq) => {
	let payload = {
		keywords,
		isLiteSeedData: isLiteSeedData,
		isMergingReq: isMergingReq
	};
	let url = BASE_URL + constants.CREATE_SEED_DATA + '/' + constants.SEARCH_BY_KEYWORDS;
	let res = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		}
	});
	let jsonRes = await res.json();
	return jsonRes;

};

const bulkMergeWithFC = async (fc, requests) => {
	const payload = { fc, requests };
	const url = BASE_URL + constants.CREATE_SEED_DATA + '/' + constants.BULK_MERGE;
	const res = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		}
	});
	return res.json();
};

const mergeWithFC = async (dataToMerge, fc) => {
	let request = {
		_id: dataToMerge._id,
		images: dataToMerge.images,
		videos: dataToMerge.videos,
		token: dataToMerge.token,
		key: dataToMerge.key
	};

	// let fc = {
	//   _id: factCheck._id,
	//   en: {
	//     article_url: factCheck.en.article_url
	//   },
	//   hi: {
	//     article_url: factCheck.hi.article_url
	//   }
	// };
	// let payload = {fc, request};
	let payload = { fc, request };
	let url = BASE_URL + constants.CREATE_SEED_DATA + '/' + constants.MERGE;
	let res = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		}
	});
	let jsonRes = res.json();
	return jsonRes;
};

async function getFcHashOfAFC(fc_id) {

	let url = BASE_URL + constants.CREATE_SEED_DATA + '/' + constants.FC_HASH + '/' + fc_id;

	let response = await fetch(url, {
		headers: {
			'Authorization': RolesPermission.getToken()
		}
	});
	return response;
}

async function deleteMergedMedia(id) {

	let url = BASE_URL + constants.CREATE_SEED_DATA + '/' + constants.FC_HASH + '/' + id;

	let response = await fetch(url, {
		method: 'DELETE',
		headers: {
			'Authorization': RolesPermission.getToken()
		}
	});
	return response;
}

async function getFilteredRequests(status, filters, pageSize, pageNum) {
	let url = `${BASE_URL}${constants.USER_REQUESTS}/${status}/${constants.FILTERS}/${pageSize}/${pageNum}`;

	let response = await fetch(url, {
		method: 'POST',
		headers: {
			'Authorization': RolesPermission.getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(filters)
	});
	return response.json();
}

async function updateAppVersion(androidAppVersion, iosAppVersion, objectId) {
	let url = BASE_URL + constants.APP_SETTING + '/' + constants.APP_VERSION + '/' + objectId;
	let response = await fetch(url, {
		method: 'PUT',
		headers: {
			'Authorization': RolesPermission.getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ androidAppVersion: androidAppVersion, iosAppVersion: iosAppVersion})
	});

	return response.json();
}

export const setRequestBookmark = async (id, status) => {
	const url = `${BASE_URL}${constants.USER_REQUESTS}/${constants.BOOKMARK}/${id}/${status}`;
	let response = await fetch(url, {
		method: 'GET',
		headers: {
			'Authorization': RolesPermission.getToken(),
			'Content-Type': 'application/json'
		},
	});
	return response.json();
}

export const sendAcceptBulkAction = async (ids, process) => {
	let url = `${BASE_URL}${constants.USER_REQUESTS}/${constants.BULK}/`;
	if (process.toLowerCase() === constants.ACCEPTED_REQUESTS.toLowerCase())
		url += constants.ACCEPTED_REQUESTS;
	else if (process.toLowerCase() === constants.PENDING_REQUESTS.toLowerCase())
		url += constants.PENDING_REQUESTS;

	const data = ids.map(item => item.id);
	const payload = {
		objectIds: data
	};
	let response = await fetch(url, {
		method: 'POST',
		headers: {
			'Authorization': RolesPermission.getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	});
	return response;
}

export const sendRejectBulkAction = async (ids, strObj) => {
	const url = `${BASE_URL}${constants.USER_REQUESTS}/${constants.BULK}/${constants.REJECTED_REQUESTS}`;
	const data = ids.map(item => item.id)
	const payload = {
		objectIds: data,
		rejectionMessageEn: strObj.body,
		rejectionMessageHi: strObj.bodyHi
	};
	let response = await fetch(url, {
		method: 'POST',
		headers: {
			'Authorization': RolesPermission.getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	});
	return response;
}

const sendDonateNotifications = async (payload) => {
	let { BASE_URL, DONATE_NOTIFICATIONS, SEND } = constants;
	let url = `${BASE_URL}${DONATE_NOTIFICATIONS}/${SEND}`;
	let response = await fetch(url, {
		method: 'POST',
		headers: {
			'Authorization': RolesPermission.getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	});
	return response.json();
}

const groupSimilarRequests = async (filters) => {
	let { BASE_URL, GROUPED, SIMILAR, USER_REQUESTS } = constants;
	let url = `${BASE_URL}${USER_REQUESTS}/${GROUPED}/${SIMILAR}/create`;
	const payload = filters.date;
	let res = await fetch(url, {
		method: 'POST',
		headers: {
			'Authorization': RolesPermission.getToken(),
			'Content-type': 'application/json'
		},
		body: JSON.stringify(payload)
	});
	return res.json();
}

const showSimilarRequests = async () => {
	let { BASE_URL, GROUPED, SIMILAR, USER_REQUESTS } = constants;
	let url = `${BASE_URL}${USER_REQUESTS}/${GROUPED}/${SIMILAR}`;
	let res = await fetch(url, {
		method: 'GET',
		headers: {
			'Authorization': RolesPermission.getToken(),
		}
	});
	return res.json();
};

const getDonateNotificationLog = async (pageSize = 20, pageNum = 1) => {
	const url = `${BASE_URL}${constants.DONATE_NOTIFICATIONS}/${pageSize}/${pageNum}`;
	let response = await fetch(url, {
		method: 'GET',
		headers: {
			'Authorization': RolesPermission.getToken(),
		}
	});
	return response.json();
};

const getSimilarRequestHash = async (phashOrMD5, process, type = 'image') => {
	let status = '';
	let url = '';
	if (process.toLowerCase() === constants.ACCEPTED_REQUESTS.toLowerCase())
		status = constants.ACCEPTED_REQUESTS;
	else if (process.toLowerCase() === constants.PENDING_REQUESTS.toLowerCase())
		status += constants.PENDING_REQUESTS;
	url = `${BASE_URL}${constants.USER_REQUESTS}/match-by/`;
	if (type === 'image')
		url += `${constants.PHASH}/${status}/${phashOrMD5}`;
	else if (type === 'video')
		url += `${constants.MD5}/${status}/${phashOrMD5}`
	let response = await fetch(url, {
		method: 'GET',
		headers: {
			'Authorization': RolesPermission.getToken(),
		}
	});
	return response.json();
}

const getArticleDetailsOnAddSeed = async (articleLink, status = 0) => {
	let articleCount = articleLink.indexOf('hindi');
	if (articleCount > -1)
		articleCount += 6
	else if (articleCount === -1) {
		articleCount = articleLink.indexOf('.in/');
		articleCount += 4;
	}
	articleLink = articleLink.substr(articleCount);
	let url = '';
	if (status === 0) {
		url = `https://www.altnews.in/wp-json/wp/v2/posts?slug=${articleLink}`
	} else {
		url = `https://www.altnews.in/hindi/wp-json/wp/v2/posts?slug=${articleLink}`
	}
	if (articleCount !== -1 || articleCount !== 0) {
		let response = await fetch(url, {
			method: 'GET',
			headers: {
				'Authorization': RolesPermission.getToken(),
			}
		});
		return response;
	}
};

const searchLeftOutRequests = async (fcIds) => {
	const url = `${BASE_URL}${constants.CREATE_SEED_DATA}/${constants.LEFT_OUT}/requests`;
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-type': 'application/json',
			'Authorization': RolesPermission.getToken(),
		},
		body: JSON.stringify({ fcIds })
	});
	return response.json();
};

const getSimilarSeedDataRequest = async () => {
	const url = `${BASE_URL}${constants.LEFT_OUT}`
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Authorization': RolesPermission.getToken(),
		}
	})
	return response.json();
}

const deleteSimilarSeedData = async (_id) => {
	const url = `${BASE_URL}${constants.LEFT_OUT}/delete/${_id}`
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Authorization': RolesPermission.getToken(),
		}
	})
	return response.json();
}

const updateUserPassword = async (_id, pass) => {
	const url = `${BASE_URL}${constants.MODERATOR}/update-password/${_id}`;
	const res = await fetch(url, {
		method: 'PUT',
		headers: {
			'Content-Type': "application/json",
			'Authorization': RolesPermission.getToken(),
		},
		body: JSON.stringify({ password: pass })
	}).then(res => res.json());
	return res;
}

const updateUserDetails = async (userObj, userId) => {
	const url = BASE_URL + constants.USER + `/${userId}`;
	const res = await fetch(url, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		},
		body: JSON.stringify(userObj)
	}).then(res => res.json())
	return res;
}

const deleteUser = async (_id) => {
	const url = BASE_URL + constants.USER + `${_id}`;
	const res = await fetch(url, {
		method: 'DELETE',
		headers: {
			'Authorization': RolesPermission.getToken()
		},
	}).then(res => res.json());
	return res;
}

const getLiteSeedData = async (urltoscrape) => {
	let url = BASE_URL + constants.CREATE_SEED_DATA + '/' + constants.CREATE_LITE_SEED_DATA + '/' + constants.SCRAPE;
	let payload = {
		url: urltoscrape
	};
	let res = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': RolesPermission.getToken()
		}
	});
	let response = await res.json();
	return response;
}

const createLiteSeedData = async (payload) => {
	let url = constants.BASE_URL + constants.CREATE_SEED_DATA + '/' + constants.CREATE_LITE_SEED_DATA + '/' + constants.CREATE;
	try {
		let response = await
			axios.post(url,
				payload,
				{
					headers: {
						'Content-type': 'multipart/form-data',
						'Authorization': RolesPermission.getToken()
					}
				}
			)
				.then(function (response) {
				})
				.catch(function (error) {
				});

		return response;
	} catch (error) {
		console.error(error);
	}
};

const getLiteSeedList = async () => {
	let url = BASE_URL + constants.CREATE_SEED_DATA + '/' + constants.ALL_LITE_SEED_LIST;
	let res = await fetch(url,
		{
			method: 'GET',
			headers: {
				'Authorization': RolesPermission.getToken()
			}
		})
		.then(res => {
			return res.json();
		});
	return res;
}



const getNotificationsType = async (pageNum, pageSum, filterBody) => {
	const url = `${BASE_URL}notification-log/${pageNum}/${pageSum}`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Authorization': RolesPermission.getToken(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(filterBody)
	}).then(res => res.json());
	return res;
}

const getSearchValue = async (keyword, pageSize, pageNum) => {
	const url = `${BASE_URL}notification-log/search/${keyword}/${pageNum}/${pageSize}`;
	const res = await fetch(url, {
		method: 'GET',
		headers: {
			'Authorization': RolesPermission.getToken()
		}
	}).then(res => res.json());
	return res;
}

const deleteAllSimilarRequestSeedData = async () => {
	const url = `${BASE_URL}left-out/delete-all`;
	const res = await fetch(url, {
		method: 'DELETE',
		headers: {
			'Authorization': RolesPermission.getToken()
		}
	}).then(res => res.json());
	return res;
}

const deleteOldRequest = async (days) => {
	const url = `${BASE_URL}request/old-requests/delete/${days}`
	return await fetch(url, {
		method: 'DELETE',
		headers: {
			'Authorization': RolesPermission.getToken()
		}
	}).then(res =>
		res.json()
	);
}

const getLsdRequestLog = async (pageSize = 20, pageNum = 1) => {
	const url = `${BASE_URL}requestLog/lite/${pageSize}/${pageNum}`;
	return await fetch(url, {
		method: 'GET',
		headers: {
			'Authorization': RolesPermission.getToken()
		}
	})
}
const updateLiteSeedData = async (payload, id) => {

	let url = constants.BASE_URL + constants.CREATE_SEED_DATA + `/${constants.CREATE_LITE_SEED_DATA}/update/` + id;

	try {
		let response = await axios.put(url, payload,
			{
				headers: {
					'Authorization': RolesPermission.getToken()
				}
			})
			.then(function (response) {
				return response;
			})
			.catch(function (error) {
				return error;
			});
		return response;
	} catch (error) {
		console.error(error);
	}
};

const Api = {
	createSeedData,
	createUser,
	getUserData,
	changeUserStatus,
	updateSeedData,
	getRequests,
	getAppSettings,
	acceptRequest,
	rejectRequest,
	updateAppSettings,
	saveAppSettings,
	login,
	sendRejectBulkAction,
	sendAcceptBulkAction,
	getSeedData,
	getLiteSeedData,
	setRequestBookmark,
	broadcastPushNotifcation,
	getSuggesgtedFactchecks,
	getFactchecksByKeywords,
	mergeWithFC,
	verifyJwtToken,
	getRequestLog,
	getFcHashOfAFC,
	deleteMergedMedia,
	moveToManageRequest,
	getFilteredRequests,
	updateAppVersion,
	sendDonateNotifications,
	groupSimilarRequests,
	showSimilarRequests,
	getDonateNotificationLog,
	getSimilarRequestHash,
	getArticleDetailsOnAddSeed,
	bulkMergeWithFC,
	searchLeftOutRequests,
	getSimilarSeedDataRequest,
	deleteSimilarSeedData,
	updateUserPassword,
	updateUserDetails,
	deleteUser,
	getNotificationsType,
	getSearchValue,
	deleteAllSimilarRequestSeedData,
	getLsdRequestLog,
	deleteOldRequest,
	createLiteSeedData,
	getLiteSeedList,
	getSeedList,
	updateLiteSeedData
};
export default Api;
