import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CookieManager from '../../services/cookie-manager';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Api from '../../services/api';
import RolesPermission from '../../services/roles-permission';
import constants from '../../services/constants';
import PropTypes from 'prop-types'

import './style.css';

class Login extends Component {

  state = {
  	email: '',
  	password: '',
  	snackbarOpen: false,
  	snackbarContent: '',
  	snackbarType: ''
  }

  constructor(props) {
  	const {ADMIN, MODERATOR} = constants;
  	super(props);
  	if (RolesPermission.getToken() !== null) {
  		window.location = '/dashboard';
  	}
  }

  handleEmail = (event) => {
  	let email = event.target.value;
  	this.setState({ email });
  }

  handlePwd = (event) => {
  	let password = event.target.value; 
  	this.setState({ password });
  }

  login = () => {
  	let  { email, password } = this.state;
  	if(email === '' || password === '') {

  		this.setState({ snackbarContent: 'Email and Password are required fields',
  			snackbarOpen: true,
  			snackbarType: 'error'
  		});
  		return;
  	}    
  	let reset = {
  		email: '',
  		password: '',
  	};
  	let payload = {email, password};
  	Api.login(payload)
  		.then(res => {
  			return res.json();
  		}).then(res => {
  			if(typeof res.error !== 'undefined') {
  				this.setState(reset);
  				this.setState({snackbarOpen: true, snackbarContent: res.error, snackbarType: 'error' });
  			}else {
  				let data = res.user;
  				let {name,  email, _id, isAdmin} = data;
  				let token = res.token;
  				let userData = {name, email, _id, isAdmin, token};
  				let userString = (JSON.stringify(userData));

  				CookieManager.setCookie('_data', userString, 60);
  				isAdmin ? window.location = '/dashboard' : window.location = '/seed-data';
  			}
  		});
  }

  snackbarClose = () => {
  	let snackbarOpen = false;
  	this.setState({snackbarOpen});
  }

  render() {
  	return (
  		<div className="login-wrapper">
  			<Snackbar
  				anchorOrigin={{
  					vertical: 'bottom',
  					horizontal: 'left',
  				}}
  				open={this.state.snackbarOpen}
  				autoHideDuration={6000}
  				onClose={this.snackbarClose}
  				className={this.state.snackbarType}

  			>
  				<SnackbarContent
  					style={{backgroundColor: this.state.snackbarType === 'error' ? 'red':'green'}}
  					message={this.state.snackbarContent}
  				/>  
  			</Snackbar>
  			<Card >
  				<div className="card-content-wrapper">
  					<CardContent >
  						<Typography component="h5" variant="h5">
              Login to FactCheck
  						</Typography>
  						<div>
  							<TextField
  								id="outlined-name"
  								label="Email Id"
  								margin="normal"
  								variant="outlined"
  								type="email"
  								onChange={this.handleEmail}
  								value={this.state.email}
  							/>
  						</div>
  						<TextField
  							id="outlined-uncontrolled"
  							label="Password"
  							margin="normal"
  							variant="outlined"
  							type="password"
  							onChange={this.handlePwd}
  							value={this.state.password}
  						/>
  						<div>
  						</div>
  						<div>
  							<Button className="login-submit" variant="contained" size="large" color="primary" onClick={this.login}>
                Login
  							</Button>
  						</div>
  					</CardContent>
  				</div>
  			</Card>
  		</div>
  	);
  }
}

Login.propTypes = {
	props: PropTypes.object
}

export default Login;
