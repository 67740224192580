import React, { Component } from 'react';
import {
	Tabs,
	Tab,
	TextField,
	Typography,
	Button,
	SnackbarContent,
	Snackbar,
	Switch,
	Checkbox,
	CircularProgress,
	FormControlLabel
} from '@material-ui/core';
import './style.css';
import Api from '../../services/api';
import { Motion, spring } from 'react-motion';

class UserManagement extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentTab: 0,
			name: '',
			currentIndex: 0,
			userName: '',
			email: '',
			password: '',
			snackbarOpen: false,
			snackbarContent: '',
			currentUsersList: [],
			usersList: [],
			passBlock: {
				current: '',
				confirm: ''
			},
			checkedBulkItem: {},
			isAdmin: false,
			overlayLoader: false,
			showEditUserForm: false,
			switchModal: true,
		};
	}

	tabChanged = (event, value) => {
		let currentTab = value;
		this.setState({ currentTab });
	}

	handleName = (event) => {
		let name = event.target.value;
		this.setState({ name });
	}

	handleUserName = event => {
		let userName = event.target.value;
		this.setState({ userName });
	}

	handleEmail = event => {
		let email = event.target.value;
		this.setState({ email });
	}

	handlePassword = event => {
		let password = event.target.value;
		this.setState({ password });
	}

	createUser = () => {
		let reset = {
			name: '',
			userName: '',
			email: '',
			password: '',
			isAdmin: false
		};
		let { name, userName, email, password, isAdmin } = this.state;
		let userData = { name, userName, email, password, isAdmin };
		let response = Api.createUser(userData);
		response.then(data => {
			let snackbarContent = 'User Created Successfully';
			let snackbarOpen = true;
			this.setState({ snackbarContent, snackbarOpen });
			this.setState(reset);
			this.fetchUsers();
		});
	}

	handleSnackbarClose = () => {
		this.setState({ snackbarOpen: false });
	}

	fetchUsers = () => {
		let res = Api.getUserData();
		res.then(users => {
			let usersList = users.data;
			this.setState({
				usersList,
				currentUsersList: usersList
			});
		});
	}

	_handleModelView = () => {
		this.setState((prev) => {
			return { switchModal: prev.switchModal ? false : true }
		})
	}

	componentDidMount() {
		this.fetchUsers()
	}

	handleUserStatus = async (userId, isActive) => {
		const { usersList } = this.state;
		const updatedUserState = await Api.changeUserStatus(userId._id, isActive).then(data => data);
		const filterState = usersList.filter(item => item._id !== userId._id);
		this.setState({
			usersList: [
				...filterState,
				updatedUserState
			]
		}, () => {
			this.fetchUsers();
			this.setState({
				snackbarContent: `${userId.name} active state updated.`,
				snackbarOpen: true
			});
		})
	}

	handleIsAdmin = () => {
		let isAdmin = !this.state.isAdmin;
		this.setState({ isAdmin });
	}

	_handleUpdatePassword = async (e, type, _id) => {
		const { passBlock } = this.state;
		const filteredList = {}
		if (type === "submit")
			e.preventDefault();
		Object.assign(filteredList, passBlock);
		if (type === "current")
			Object.assign(filteredList, { ...filteredList, current: e.target.value })
		if (type === "confirm")
			Object.assign(filteredList, { ...filteredList, confirm: e.target.value })

		if (type !== "submit")
			this.setState({
				passBlock: {
					...passBlock,
					...filteredList
				}
			})
		if (passBlock.confirm === passBlock.current && type === "submit") {
			const res = await Api.updateUserPassword(_id, passBlock.confirm).then(data => data);
			this.setState({
				snackbarContent: res,
				snackbarOpen: true
			});
		}
	}

	_handleUpdateUserForm = async (e, type) => {
		const { usersList, currentIndex, checkedBulkItem } = this.state;
		const filteredList = {};
		if (type === "submit" && type === "checkbox")
			e.preventDefault();
		Object.assign(filteredList, usersList[currentIndex]);
		if (type === "name") {
			Object.assign(filteredList, { ...filteredList, name: e.target.value })
		} else if (type === "username") {
			Object.assign(filteredList, { ...filteredList, userName: e.target.value })
		} else if (type === "email") {
			Object.assign(filteredList, { ...filteredList, email: e.target.value })
		}
		if (type !== "submit")
			this.setState({
				usersList: [
					...usersList.filter(item => item._id !== filteredList._id),
					filteredList
				]
			})
		if (type === "submit") {
			this.setState({
				showEditUserForm: false
			})
			const res = await Api.updateUserDetails({
				...filteredList,
				isAdmin: checkedBulkItem.isAdmin
			}, filteredList._id).then(data => data)
			if (res) {
				this.fetchUsers();
				this.setState({
					snackbarContent: `Profile Updated Successfully`,
					snackbarOpen: true
				});
			}
		}
	}

	_handleListItemCheckbox = (e) => {
		const { checkedBulkItem } = this.state;
		e.stopPropagation();
		const data = Object.assign({}, checkedBulkItem);
		data.isAdmin = data.isAdmin == null ? true : data.isAdmin === false ? true : false;
		this.setState({
			checkedBulkItem: data,
		})
	}

	editUser = () => {
		const {
			usersList,
			currentIndex,
			switchModal,
			passBlock,
			checkedBulkItem
		} = this.state;
		const user = usersList[currentIndex] ? usersList[currentIndex] : undefined;
		let style = {
			backgroundColor: '#efefef',
			width: '500px',
			margin: '0 auto',
			padding: '10px',
			borderRadius: '4px'
		};
		return (
			<Motion
				defaultStyle={{ y: 220, opacity: 0, backgroundColor: 'white' }}
				style={{
					y: spring(100, { stiffness: 100, damping: 12 }),
					opacity: spring(1),
				}}>
				{
					(interpolatingStyle) =>
						(
							<div
								style={{
									opacity: interpolatingStyle.opacity,
									transform: `translateY(${interpolatingStyle.y}px)`,
									...style
								}}>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>
										<h3>Edit User</h3>
										<form name="edit-user-form" onSubmit={(e) => {
											switchModal ?
												this._handleUpdateUserForm(e, 'submit')
												: this._handleUpdatePassword(e, 'submit', user._id)
										}}>
											{
												switchModal ? (
													<React.Fragment>
														<TextField
															className="full-width mar-bot"
															label="Name"
															name="name"
															onChange={(e) => this._handleUpdateUserForm(e, "name")}
															value={user ? user.name : ''}
														/>
														<TextField
															className="full-width mar-bot"
															label="Username"
															name="username"
															onChange={(e) => this._handleUpdateUserForm(e, "username")}
															value={user ? user.userName : ''}
														/>
														<TextField
															className="full-width mar-bot"
															label="Email"
															onChange={(e) => this._handleUpdateUserForm(e, "email")}
															value={user ? user.email : ''}
															type="email"
															name="email"
														/>
														<FormControlLabel
															className="full-width"
															style={{ margin: 0, padding: 0 }}
															control={
																<Checkbox
																	checked={checkedBulkItem.isAdmin}
																	onChange={(e) => this._handleListItemCheckbox(e)}
																	onClick={(e) => e.stopPropagation()}
																	color="primary"
																	inputProps={{
																		'aria-label': user._id,
																	}}
																/>
															}
															label="Is Admin"
														/>
													</React.Fragment>
												) : (<React.Fragment>
													<TextField
														type="password"
														className="full-width mar-bot"
														label="Password"
														name='password'
														onChange={(e) => this._handleUpdatePassword(e, "current")}
														value={passBlock ? passBlock.current : ''}
													/>
													<TextField
														type="password"
														className="full-width mar-bot"
														label="Confirm Password"
														name='confirmpassword'
														onChange={(e) => this._handleUpdatePassword(e, "confirm")}
														value={passBlock ? passBlock.confirm : ''}
													/></React.Fragment>
													)
											}
											<Button
												style={{ marginTop: 10 }}
												color="primary"
												variant="contained"
												onClick={this._handleModelView}
											>
												{switchModal ? 'Update Password' : 'Update Details'}
											</Button>
											<Button
												style={{ marginLeft: 10, marginTop: 10 }}
												type="submit"
												color="primary"
												variant="contained"
											>Submit
            			</Button>
										</form>
									</div>
									<div>
										<i style={{ color: 'red', cursor: 'pointer', justifySelf: 'flex-end' }}
											className='material-icons'
											onClick={() => this.setState({ showEditUserForm: false })}>
											cancel
                </i>
									</div>
								</div>
							</div>
						)
				}
			</Motion>
		);
	}

	_handleDeleteUser = async (_id) => {
		const res = await Api.deleteUser(_id).then(data => data);
		if (res)
			this.setState({
				snackbarContent: `User Deleted Successfully`,
				snackbarOpen: true
			}, () => {
				this.fetchUsers();
			});
	}

	render() {
		const {
			currentTab,
			showEditUserForm,
			currentUsersList,
			switchModal
		} = this.state;
		return (
			<div className="user-management-container">
				{this.state.overlayLoader ?
					<div className="overlay-loader">
						<CircularProgress className="secondary" />
					</div>
					:
					null
				}
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={this.state.snackbarOpen}
					autoHideDuration={6000}
					onClose={this.handleSnackbarClose}
				>
					<SnackbarContent className="success-bg-color" message={this.state.snackbarContent} />
				</Snackbar>
				<Tabs className="custom-tabs-wrapper" value={currentTab} onChange={this.tabChanged}>
					<Tab label="Create User" />
					<Tab label="Users List" />
				</Tabs>
				{currentTab === 0 &&
					<div className="create-user-wrapper">
						<div className="create-user-form">
							<form autoComplete="off">

								<Typography variant="h5">Create User</Typography>
								<TextField
									className="full-width mar-bot"
									label="Name"
									value={this.state.name}
									onChange={this.handleName}
								/>
								<TextField
									className="full-width mar-bot"
									label="Username"
									value={this.state.userName}
									onChange={this.handleUserName}
								/>
								<TextField
									className="full-width mar-bot"
									label="Email"
									value={this.state.email}
									onChange={this.handleEmail}
									type="email"
									name="email"
								/>
								<TextField
									name="pass"
									type="password"
									className="full-width mar-bot"
									label="Password"
									value={this.state.password}
									onChange={this.handlePassword}
								/>
								<div>
									<Checkbox value="isAdmin" checked={this.state.isAdmin} onChange={this.handleIsAdmin} />
									<label>Is Admin</label>
								</div>
								<Button
									color="primary"
									variant="contained"
									onClick={this.createUser}>
									Submit
            			</Button>

							</form>
						</div>
					</div>
				}
				{currentTab === 1 &&
					<div className="users-list-wrapper">
						<div>
							<div className="users-list-header">
								<div style={{ flexGrow: '1' }}>User Details</div>

								<div style={{ marginRight: '16px' }}>Edit</div>
								<div style={{ marginRight: '16px' }}>Active</div>
								{/* <div style={{marginRight:"16px"}}>Delete</div> */}

							</div>
							{currentUsersList.map((user, index) =>
								<div key={index} className="user-details">
									<div className="user-content">
										<h3>{user.name} </h3>
										<p>
											<span>Email : </span>
											{user.email}
										</p>
										<p>
											<span>Username : </span>
											{user.userName}
										</p>
										<p>
											<span>Role : </span>
											{user.isAdmin ? 'ADMIN' : 'MODERATOR'}
										</p>
									</div>
									<div className="user-actions">
										<Button className="edit" onClick={() => {
											this.setState({
												passBlock: {
													current: '',
													confirm: ''
												},
												usersList: currentUsersList,
												checkedBulkItem: currentUsersList[index],
												showEditUserForm: true,
												currentIndex: index,
											})
										}}>
											<i className="material-icons">edit</i>
										</Button>
										<Button onClick={() => this._handleDeleteUser(user._id)}>
											<i className="material-icons">delete</i>
										</Button>
										<Switch
											checked={user.isActive}
											onChange={() => this.handleUserStatus(user, user.isActive)}
											value="checkedA"
										/>
									</div>
								</div>
							)}

						</div>
					</div>
				}
				{
					showEditUserForm ?
						<div style={{
							position: 'fixed',
							top: 0,
							left: 0,
							backgroundColor: 'rgba(0,0,0,0.5)',
							width: '100%',
							height: '100%',
							zIndex: 200
						}}>

							{this.editUser()}
						</div>
						:
						null
				}
			</div>
		);
	}
}

export default UserManagement;