import CookieManager from '../cookie-manager';
import constants from '../constants';

const {ADMIN, MODERATOR} = constants;

const isAdmin = () => {
	let userData = CookieManager.getCookie('_data');
	if(userData) {
		// userData = JSON.parse(userData);
		let {isAdmin} = userData;
		let role = isAdmin ? ADMIN : MODERATOR;
		return role;
	} else {
		window.location = '/login';
	}
};
const getToken = () => {
	let userData = CookieManager.getCookie('_data');
	if(userData) {
		// userData = JSON.parse(userData);
		let {token} = userData;
		return token;
	} else {
		return null;
	}
};

let RolesPermission = {isAdmin, getToken};

export default RolesPermission;