import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import './style.css';
import Chip from '@material-ui/core/Chip';
import ReactPlayer from 'react-player';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Api from '../../services/api'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { Snackbar, CircularProgress, SnackbarContent } from '@material-ui/core';

class SimpleForm extends Component {
  constructor(props) {
    super(props)
    this.props.currentData !== undefined ?
      (
        this.state = {
          enFlag: this.props.enFlag,
          hiFlag: this.props.hiFlag,
          _id: this.props.currentData._id,
          keywords: '',
          summary: '',
          title: '',
          brokenLogos: [],
          activeStep: 0,
          allImages: this.props.currentData.allImages,
          images: [],
          videos: [],
          fetchingTitle: false,
          customTitle: 'This is custom title',
          allVideos: this.props.currentData.videos,
          siteIcon: this.props.currentData.lsdSiteIcon,
          siteUrl: this.props.currentData.lsdSiteUrl,
          enArticle_url: this.props.currentData.en.article_url,
          hiArticle_url: this.props.currentData.hi.article_url,
          enBroadcastMsg: 'Hey! A new article has been published. You can read it here.',
          hiBroadcastMsg: 'एक नया लेख प्रकाशित हुआ है, जिसे आप यहाँ पढ़ सकते हैं।',
          siteName: '',
          en: {
            links: this.props.currentData.en.links,
            summary: this.props.currentData.en.summary,
            keywords: this.props.currentData.en.keywords,
            title: this.props.currentData.en.title,
            article_url: this.props.currentData.en.article_url,
          },
          hi: {
            links: this.props.currentData.hi.links,
            summary: this.props.currentData.hi.summary,
            keywords: this.props.currentData.hi.keywords,
            title: this.props.currentData.hi.title,
            article_url: this.props.currentData.hi.article_url,
          },
          preview: false,
          loader: false,
          toastMssg: '',
          showToastMssg: false,
          sendPushNotifEn: this.props.currentData.en.pushNotificationSent,
          sendPushNotifHi: this.props.currentData.hi.pushNotificationSent,
          disableEnCheckbox: this.props.currentData.en.pushNotificationSent,
          disableHiCheckbox: this.props.currentData.hi.pushNotificationSent,
          disableBroadcastEnCheckbox: this.props.currentData.en.broadcastNotificationSent,
          disableBroadcastHiCheckbox: this.props.currentData.hi.broadcastNotificationSent,
        }
      ) : (
        this.state = {
          enFlag: false,
          hiFlag: false,
          keywords: '',
          logo: [],
          brokenLogos: [],
          title: '',
          enArticle_url: '',
          hiArticle_url: '',
          summary: '',
          setEnStatus: false,
          setHiStatus: false,
          customTitle: 'This is a custom title',
          siteIcon: '',
          siteUrl: '',
          siteName: '',
          shortSiteName: '',
          images: [],
          videos: [],
          checked: false,
          preview: false,
          loader: false,
          toastMssg: '',
          showToastMssg: false,
          sendPushNotifEn: false,
          sendPushNotifHi: false,
          enBroadcastMsg: 'Hey! A new article has been published. You can read it here.',
          hiBroadcastMsg: 'एक नया लेख प्रकाशित हुआ है, जिसे आप यहाँ पढ़ सकते हैं।',
          en: {
            links: [],
            summary: '',
            keywords: [],
            title: '',
            article_url: '',
          },
          hi: {
            links: [],
            summary: '',
            keywords: [],
            title: '',
            article_url: '',
          },
          propsStatus: this.props.data !== undefined ? true : false,
          videoArrayStatus: false,
          imageArrayStatus: false,
        }
      )
  }


  handlePushNotif = (e) => {
    let lang = e.target.value;
    if (lang === 'en') {
      let sendPushNotifEn = !this.state.sendPushNotifEn;
      this.setState({ sendPushNotifEn });
    }
    else {
      let sendPushNotifHi = !this.state.sendPushNotifHi;
      this.setState({ sendPushNotifHi });
    }
  }


  handleEnNotif = (evt) => {
    let enBroadcastMsg = evt.target.value;
    this.setState({ enBroadcastMsg })
  }

  handleHiNotif = (evt) => {
    let hiBroadcastMsg = evt.target.value;
    this.setState({ hiBroadcastMsg })
  }

  handleInput = async (evt, field, lang) => {
    let data = this.state[lang];
    let seedData = this.props.liteSeedData
    let filtered = []

    if (field === 'article_url') {
      filtered = seedData.filter(story => story[lang][field] === evt.target.value);
    }

    if (filtered.length !== 0 && evt.target.value !== '') {
      this.setState({
        showToastMssg: true,
        toastMssg: 'Article already exist!!!'
      });
    }

    if (evt.target.value !== '') {
      data[field] = evt.target.value;
      if (lang === 'en') {
        this.setState({ en: data });
      } else {
        this.setState({ hi: data });
      }
      this.setState({ loader: true });

      let VideoArray = this.state.videos;
      if (this.state.videoArrayStatus === false && this.props.data) {
          this.props.data.map((item, index) => {
            if (item.videos.length > 0) {
              VideoArray.push({
                url: item.videos[0].path,
                fileObjectUrl: item.videos[0].path
              })
              if(this.state.videos[0].url ===  item.videos[0].path){

              }
            }
          })
        this.setState({ videos: VideoArray, videoArrayStatus: true });
      }
     
      let ImageArray = this.state.images;
          if (this.state.imageArrayStatus === false && this.props.data) {
              this.props.data.map((item, index) => {
                if (item.images.length > 0) {
                  ImageArray.push({
                    url: item.images[0].path,
                    fileObjectUrl: item.images[0].path
                  })
                }
              })
            this.setState({ images: ImageArray, imageArrayStatus : true });
          }

      await Api.getLiteSeedData(evt.target.value)
        .then(result => {
          const metadata = result.metadata;
          let logo = [];
          metadata && metadata.customLogo && metadata.customLogo.map((item, index) => {
            if (!logo.includes(item)) {
              logo.push(item)
            }
          })
          this.setState({
            title: metadata['og:title'],
            summary: metadata['og:description'],
            preview: true,
            keywords: metadata.keywords,
            logo: logo,
            siteUrl: metadata.source,
            siteName: metadata['og:site_name']
          })

          if (this.state.siteName.length === 0) {
            let titlePrefix = this.state.siteUrl.split('.');
            if (titlePrefix[0] === "edition" || titlePrefix[0] === "www") {
              titlePrefix = titlePrefix[1]
            } else if (titlePrefix[0] !== "www") {
              titlePrefix = titlePrefix[0];
            }
            titlePrefix = titlePrefix[0].toUpperCase() + titlePrefix.slice(1)
            this.setState({ shortSiteName: titlePrefix })
          }

          else {
            let siteNamePrefix = this.state.siteName.split('.');
            if (siteNamePrefix[0] === 'www' || (siteNamePrefix[0] === 'https://www' || siteNamePrefix[0] === 'http://www')) {
              siteNamePrefix = siteNamePrefix[1]
              siteNamePrefix = siteNamePrefix[0].toUpperCase() + siteNamePrefix.slice(1)
              this.setState({ shortSiteName: siteNamePrefix })
            }

            else {
              siteNamePrefix = siteNamePrefix[0]
              siteNamePrefix = siteNamePrefix[0].toUpperCase() + siteNamePrefix.slice(1)
              this.setState({ shortSiteName: siteNamePrefix })
            }
          }

          this.setState({ loader: false });
            if (this.state.en.article_url && lang === 'en') this.handleEnFields();
            if(this.state.hi.article_url && lang === 'hi') this.handleHiFields();
        // (this.state.en.article_url && lang === 'en') ? this.handleEnFields() : null;
        // let y = (this.state.hi.article_url && lang === 'hi') ? this.handleHiFields() : null;

        }).catch(error => {
        })
    }
  }



  handleEnFields = () => {
    let englishKeyword = this.state.keywords ? this.state.keywords.split(',') : [];

    this.state.en.article_url !== '' && this.setState({ enArticle_url: this.state.en.article_url });

    this.state.en.article_url && this.setState({ setEnStatus: true })

    if(this.state.setEnStatus)
      this.setState(Object.assign(this.state.en, { article_url: '' }, { title: `${this.state.shortSiteName} | ${this.state.title}` }, { summary: this.state.summary }))


    this.state.setEnStatus && this.setState(Object.assign(this.state.en, { keywords: englishKeyword }))
  }

  handleHiFields = () => {
    let hindiKeyword = this.state.keywords ? this.state.keywords.split(',') : [];

    this.state.hi.article_url !== '' && this.setState({ hiArticle_url: this.state.hi.article_url });

    this.state.hi.article_url && this.setState({ setHiStatus: true })

    if(this.state.setHiStatus)
      this.setState(Object.assign(this.state.hi, { article_url: '' }, { title: `${this.state.shortSiteName} | ${this.state.title}` }, { summary: this.state.summary }))

    this.state.setHiStatus && this.setState(Object.assign(this.state.hi, { keywords: hindiKeyword }))
  }

  handleTitle = (evt, field, lang) => {

    this.setState({ customTitle: evt.target.value })

    let title = this.state[lang];
    title[field] = evt.target.value;
    if (lang === 'en') {
      this.setState({ en: title })
    } else {
      this.setState({ hi: title });
    }
  }

  handleSummary = (evt, field, lang) => {
    let summary = this.state[lang];
    summary[field] = evt.target.value
    if (lang === 'en') {
      this.setState({ en: summary });
    } else {
      this.setState({ hi: summary })
    }
  }


  handleKeyword = (evt, lang) => {
    if (evt.key === 'Enter' && evt.target.value !== '') {
      let keyword = evt.target.value;
      let data = this.state[lang];
      data.keywords.push(keyword);
      if (lang === 'en') {
        this.setState({ en: data });
      } else {
        this.setState({ hi: data });
      }
      evt.target.value = '';
    }
  }

  deleteKeyword = (index, lang) => {
    let data = this.state[lang];
    data.keywords = data.keywords.filter((keyword, indx) => index !== indx);
    if (lang === 'en') {
      this.setState({ en: data });
    } else {
      this.setState({ hi: data });
    }
  }


  addImagefield = () => {
    let imageArray = this.state.images;
    imageArray.push({ url: '' });
    this.setState({ images: imageArray });
  };

  removeImageField = index => {
    let images = [...this.state.images];
    images = images.filter((image, key) => key !== index);
    this.setState({ images });
  };

  removeAllImageField = id => {
    if (this.props.currentData !== undefined) {
      let allImages = [...this.state.allImages]
      allImages = allImages.filter((image, key) => image._id !== id);
      this.setState({ allImages })
    }
  }


  handleImage = (evt, index) => {
    let imageArr = this.state.images;
    imageArr[index].url = evt.target.files[0];
    imageArr[index].fileObjectUrl = URL.createObjectURL(evt.target.files[0]);
    this.setState({ images: imageArr });
  };


  addVideofield = () => {
    let videoArray = this.state.videos;
    videoArray.push({ url: '' });
    this.setState({ videos: videoArray });
  };

  removeVideofield = index => {
    let videos = [...this.state.videos];
    videos = videos.filter((image, key) => key !== index);
    this.setState({ videos });
  };

  removeAllVideofield = index => {
    if (this.props.currentData !== undefined) {
      let allVideos = [...this.state.allVideos]
      allVideos = allVideos.filter((item, key) => key !== index);
      this.setState({ allVideos })
    }
  }


  handleVideo = (evt, index) => {
    if (evt.target.files[0].type === 'video/3gpp' || evt.target.files[0].type === 'video/3gp') {
      this.setState({ showToastMssg: true, toastMssg: 'Unsupported Video Format' });
    } else {
      let videoArr = this.state.videos;
      videoArr[index].url = evt.target.files[0];
      videoArr[index].fileObjectUrl = URL.createObjectURL(evt.target.files[0]);
      this.setState({ videos: videoArr });
    }
    setTimeout(() => {
    }, 200);
  };

  handleURL = (evt) => {
    evt.target.value = " "
    this.setState({ siteUrl: evt.target.value })
  }

  checkedValue = (e) => {
    this.setState({ checked: !this.state.checked })
    this.setState({ siteIcon: e.target.value })
  }

  handleLogo = (evt) => {
    this.setState({ siteIcon: evt.target.value })
  }

  handleSubmit = () => {
    this.setState(Object.assign(this.state.en, { article_url: this.state.enArticle_url }));
    this.setState(Object.assign(this.state.hi, { article_url: this.state.hiArticle_url }));
    const reset = {
      enArticle_url: '',
      hiArticle_url: '',
      images: [],
      logo: [],
      videos: [],
      siteIcon: '',
      siteUrl: '',
      siteName: '',
      en: {
        links: [],
        summary: '',
        keywords: [],
        title: '',
        article_url: '',
      },
      hi: {
        links: [],
        summary: '',
        keywords: [],
        title: '',
        article_url: '',
      },
      loader: false,
      preview: false,
      sendPushNotifEn: false,
      sendPushNotifHi: false,
      enBroadcastMsg: '',
      hiBroadcastMsg: ''
    }

    this.setState({ loader: true });
    try {
      let formData = new FormData();
      formData.append('en', JSON.stringify(this.state.en));
      formData.append('hi', JSON.stringify(this.state.hi));
      formData.append('sendPushNotificationEn', this.state.sendPushNotifEn);
      formData.append('sendPushNotificationHi', this.state.sendPushNotifHi);
      formData.append('sendBroadcastNotifEn', this.state.enBroadcastMsg);
      formData.append('sendBroadcastNotifHi', this.state.hiBroadcastMsg);
      if (this.state.propsStatus) {
        // send lsdFromRequest as true in form-data when LSD is created from manage requsest or dashboard section
        formData.append('lsdFromRequest', true);
        formData.append('requests', JSON.stringify(this.props.data));
      } else {
        formData.append('lsdFromRequest', false);
      }

      this.state.images && this.state.images.map(image => {
        delete image.fileObjectUrl;
        image.url !== undefined && formData.append('media', image.url);
        image.path !== undefined && formData.append('media', image.path);
        return formData;
      });

      if (this.props.currentData !== undefined) {
        this.state.allImages && this.state.allImages.map(image => {
          image.path !== undefined && formData.append('media', image.path);
          return formData;
        });
      }

      this.state.videos && this.state.videos.map(video => {
        formData.append('media', video.url);
        return formData;
      });

      formData.append('lsdSiteUrl', this.state.siteUrl);
      formData.append('lsdSiteIcon', this.state.siteIcon);

      if (this.props.currentData === undefined) {
        Api.createLiteSeedData(formData).then(response => {
          this.setState(reset);
          this.setState({ showToastMssg: true, toastMssg: 'Lite Seed Data created successfully' });
          setTimeout(() => {
            this.props.close && this.props.close();
          }, 1500);
          window.location.reload(false);
        });
      } else {
        Api.updateLiteSeedData(formData, this.props.currentData._id).then(response => {
          this.setState(reset);
          this.setState({ showToastMssg: true, toastMssg: 'Lite Seed Data updated successfully' });
          setTimeout(() => {
            this.props.close();
          }, 1500);
          window.location.reload(false);
        });
      }
    } catch (error) {
      this.setState({ loader: false });
    }
  };

  handleImgError = (e, item) => {
    let currentBrokenLogos = this.state.brokenLogos
    currentBrokenLogos.push(item)
    this.setState({ brokenLogos: currentBrokenLogos })
  }

  closeToastMssg = () => {
    this.setState({ showToastMssg: false });
  }

   componentDidMount = () => {
    let size = Object.keys(this.props).length
    if(this.props.data){
      this.props.data.map((item, index)=> {
        if(item.preferredLanguage === "en")
        {
          this.setState({ enFlag : true }, () =>{
          });
        }
        else if(item.preferredLanguage === 'hi')
        {
          this.setState({ hiFlag : true }, () =>{
          });
        }
      })
    }
    else if (this.props.currentData && size > 1){
      if (this.props.currentData && this.props.currentData.en.article_url !== '') 
        {
          this.setState({ enFlag : true });
        }
        else if(this.props.currentData && this.props.currentData.hi.article_url !== ''){
          this.setState({ hiFlag : true });
        }
    }   
  }

  render() {
    const { currentData } = this.props
    let {
      showToastMssg,
      toastMssg,
      loader,
      images,
      allImages,
      videos,
      allVideos,
      enArticle_url,
      hiArticle_url,
      enBroadcastMsg,
      hiBroadcastMsg,
      enFlag,
      hiFlag
    } = this.state;
    return (
      <div className="lite-seed-data-wrapper">
        <div className="simpleformwrapper" style={currentData !== undefined ? { marginTop: '30px' } : null}>
          <div style={{ marginBottom: '15px' }}>
            <h2>{currentData !== undefined ? 'Edit Lite Seed Data' : 'Add Lite Seed Data'}</h2>
          </div>
          {
            loader ?
              <div className="loader">
                <CircularProgress className="svg-spin" color="secondary" />
              </div>
              :
              null
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showToastMssg}
            autoHideDuration={6000}
            onClose={this.closeToastMssg}>
            <SnackbarContent
              className={toastMssg.includes('successfully') ? 'success-bg-color' : 'error-toast'}
              message={<span id="message-id">{toastMssg}</span>}
            />
          </Snackbar>
          <div className="form-wrapper">
            <div>1. Article link </div>
            <TextField
              id="enArticle_url"
              className="form-textField"
              label="Article Link"
              margin="normal"
              onChange={evt => this.handleInput(evt, 'article_url', 'en')}
              value={enArticle_url}
            />
            <TextField
              id="hiArticle_url"
              className="form-textField"
              label="आर्टिकल लिंक"
              margin="normal"
              onChange={evt => this.handleInput(evt, 'article_url', 'hi')}
              value={hiArticle_url}
            />
          </div>
          <div className="form-wrapper">
            <div>2. Title</div>
            <TextField
              id="entitle"
              className="form-textField"
              label="title"
              margin="normal"
              onChange={evt => this.handleTitle(evt, 'title', 'en')}
              value={this.state.en.title}
            />
            <TextField
              id="hititle"
              className="form-textField"
              label="शीर्षक"
              margin="normal"
              onChange={evt => this.handleTitle(evt, 'title', 'hi')}
              value={this.state.hi.title}
            />
          </div>
          <div className="form-wrapper">
            <div>3. Summary</div>
            <TextField
              id="ensummary"
              className="form-textField"
              label="summary"
              margin="normal"
              onChange={(evt) => this.handleSummary(evt, 'summary', 'en')}
              value={this.state.en.summary}
            />
            <TextField
              id="hisummary"
              className="form-textField"
              label="विवरण"
              margin="normal"
              onChange={(evt) => this.handleSummary(evt, 'summary', 'hi')}
              value={this.state.hi.summary}
            />
          </div>
          <div className="form-wrapper">
            <div>4. Keywords</div>
            <div>
              <TextField
                id="enkeyword"
                className="form-textField"
                label="Keywords"
                margin="normal"
                onKeyPress={(evt) => this.handleKeyword(evt, 'en')}
              />
              {this.state.en.keywords ? this.state.en.keywords.map((keyword, index) =>
                <Chip
                  id='en'
                  key={index}
                  style={{ margin: '3px' }}
                  label={keyword}
                  onDelete={() => this.deleteKeyword(index, 'en')}
                  variant="outlined"
                  color="primary"
                />
              ) : null
              }
            </div>
            <div>
              <TextField
                id="hikeyword"
                className="form-textField"
                label="कीवर्ड"
                margin="normal"
                onKeyPress={(evt) => this.handleKeyword(evt, 'hi')}
              />
              {this.state.hi.keywords ? this.state.hi.keywords.map((keyword, index) =>
                <Chip
                  id='hi'
                  key={index}
                  style={{ margin: '3px' }}
                  label={keyword}
                  onDelete={() => this.deleteKeyword(index, 'hi')}
                  variant="outlined"
                  color="secondary"
                />
              ) : null
              }

            </div>
          </div>
          <div className="form-wrapper">
            <div>5. Base URL</div>
            <TextField
              id="url"
              className="form-textField"
              label="baseURL"
              margin="normal"
              onChange={(evt) => this.handleURL(evt)}
              value={this.state.siteUrl}
            />
          </div>
          <div className="form-wrapper">
            <div>6. Logo</div>
            <div className="logoList">
              {
                this.state.logo && this.state.logo.length >= 1 &&
                this.state.logo.map((item, index) => {
                  if (this.state.brokenLogos.includes(item)) {
                    return null
                  } else {
                    return (
                      <div key={index} style={{ display: 'flex', padding: '10px 0px' }}>
                        <input
                          id={'logo-' + index}
                          type="radio"
                          value={item}
                          color="primary"
                          checked={this.state.siteIcon === item}
                          onChange={(e) => this.checkedValue(e)}
                        />
                        <label htmlFor={'logo-' + index}>
                          <img src={item} alt=" " style={{ width: '200px' }} onError={(e) => this.handleImgError(e, item)} />
                        </label>
                      </div>
                    )
                  }

                })
              }
              <TextField
                id="logo"
                className="form-textField"
                label="logo"
                margin="normal"
                onChange={(evt) => this.handleLogo(evt)}
                value={this.state.siteIcon}
              />
            </div>
          </div>
          <div className="form-wrapper">
            <div>7. Images</div>
            <img
              className="pointer"
              style={{ width: '40px' }}
              onClick={this.addImagefield}
              src={require('../../assets/icons/plus.svg')}
              alt=''
            />
            {
              images.map((image, index) => {
                return (
                  <div className="media-wrapper" key={`${index}-${image._id}`}>
                    <label htmlFor={`img-${index}`} >
                      <Chip clickable label="Upload Image" />
                    </label>
                    <input
                      type="file"
                      id={`img-${index}`}
                      className="form-mediaField"
                      label={image}
                      onChange={(evt) => this.handleImage(evt, index)}
                      key={index}
                      style={{ display: 'none' }}
                    />
                    <div className="image-wrapper" >
                      <img src={(image && image.fileObjectUrl)} alt='' />
                    </div>
                    <i
                      style={{ alignSelf: 'center' }}
                      className="material-icons danger-icon"
                      onClick={() => this.removeImageField(index)}
                    >
                      delete
                </i>
                  </div>
                );
              })
            }
            {
              typeof allImages !== 'undefined' ?
                allImages.map((image, index) => {
                  return (
                    <div className="media-wrapper" key={`${index}-${image._id}`}>
                      <div className="img-wrapper" style={{ width: '70px' }}>
                        <img src={image.path} alt='' />
                      </div>
                      <i
                        style={{ alignSelf: 'center' }}
                        className="material-icons danger-icon"
                        onClick={() => this.removeAllImageField(image._id)}
                      >
                        delete
              </i>
                    </div>
                  );
                })
                : null
            }
          </div>
          <div className="form-wrapper">
            <div>8. Videos</div>
            <img
              className="pointer"
              style={{ width: '40px' }}
              onClick={this.addVideofield}
              src={require('../../assets/icons/plus.svg')}
              alt=''
            />
            {
              videos && videos.map((video, index) => {
                return (
                  <div className="media-wrapper" key={`${index}-${video._id}`}>
                    <label htmlFor={`vid-${index}`} >
                      <Chip clickable label="Upload Video" />
                    </label>
                    <input
                      type="file"
                      id={`vid-${index}`}
                      className="form-mediaField"
                      label={video && video.fileObjectUrl}
                      onChange={(evt) => this.handleVideo(evt, index)}
                      key={index}
                      style={{ display: 'none' }}
                    />
                    {
                      video.path && <ReactPlayer
                        url={video.path}
                        playing={false}
                        controls={true}
                        width="60%"
                        height="200px"
                      />
                    }
                    {
                      video.fileObjectUrl && <ReactPlayer
                        url={video.fileObjectUrl}
                        playing={false}
                        controls={true}
                        width="60%"
                        height="200px"
                      />
                    }
                    <i
                      className="material-icons danger-icon"
                      onClick={() => this.removeVideofield(index)}
                    >
                      delete
              </i>
                  </div>
                );
              })
            }
            {
              typeof allVideos !== 'undefined' ?
                allVideos.map((video, index) => {
                  return (
                    <div className="media-wrapper" key={`${index}-${video._id}`}>
                      <ReactPlayer
                        url={video.path}
                        playing={false}
                        controls={true}
                        width="60%"
                        height="200px"
                      />
                      <i
                        className="material-icons danger-icon"
                        onClick={() => this.removeAllVideofield(index)}
                      >
                        delete
                </i>
                    </div>
                  );
                })
                : null
            }
          </div>
          <div className="form-wrapper">
            <div>9. Broadcast Notifications</div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sendPushNotifEn}
                  onChange={this.handlePushNotif}
                  value="en"
                  disabled={enArticle_url ? false : true}
                />
              }
              label="Send Broadcast Notification English"
            />
            {
              this.state.sendPushNotifEn ?
                <TextField
                  id="broadcastEn"
                  className="form-textField"
                  margin="normal"
                  required
                  label="Required"
                  value={enBroadcastMsg}
                  onChange={(evt) => this.handleEnNotif(evt)}
                />
                : null
            }
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sendPushNotifHi}
                  onChange={this.handlePushNotif}
                  value="hi"
                  disabled={hiArticle_url ? false : true}
                />
              }
              label="Send Broadcast Notification Hindi"
            />
            {
              this.state.sendPushNotifHi ?
                <TextField
                  id="broadcastHi"
                  value={hiBroadcastMsg}
                  className="form-textField"
                  margin="normal"
                  required
                  label="Required"
                  onChange={(evt) => this.handleHiNotif(evt)}
                />
                : null
            }
          </div>
          <div className="form-wrapper">
            {
              this.props.data ?
              (
                (
                  enFlag ?
                    (
                      hiFlag ?
                      (
                        (
                          ((enArticle_url !== '') && (hiArticle_url !== '')) 
                          && 
                          ((enBroadcastMsg !== '') && (hiBroadcastMsg !== ''))
                        )
                        ?
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                        :
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled
                          >
                            Submit
                          </Button>
                        </div>
                      ) 
                      :
                      (
                        ((enArticle_url !== '') && (enBroadcastMsg !== ''))
                        ?
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                        :
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled
                          >
                            Submit
                          </Button>
                        </div>
                      ) 
                    )
                    :
                    (
                      hiFlag ?
                      (
                        ((hiArticle_url !== '') && (hiBroadcastMsg !== ''))
                        ?
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                        :
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled
                          >
                            Submit
                          </Button>
                        </div>
                      )
                      :
                      null
                    )
                )
              )
              :
            currentData ?
            (
              enFlag ?
                (
                  hiFlag ?
                  (
                    (
                      ((enArticle_url !== '') && (hiArticle_url !== '')) 
                      && 
                      ((enBroadcastMsg !== '') && (hiBroadcastMsg !== ''))
                    )
                    ?
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                    :
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                      >
                        Submit
                      </Button>
                    </div>
                  ) 
                  :
                  (
                    ((enArticle_url !== '') && (enBroadcastMsg !== ''))
                    ?
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                    :
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                      >
                        Submit
                      </Button>
                    </div>
                  ) 
                )
                :
                (
                  hiFlag ?
                  (
                    ((hiArticle_url !== '') && (hiBroadcastMsg !== ''))
                    ?
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                    :
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                      >
                        Submit
                      </Button>
                    </div>
                  )
                  :
                  null
                )
            )
            :
            (
              (
                (
                  ((enArticle_url !== '') || (hiArticle_url !== ''))
                  &&
                  ((enBroadcastMsg !== '') && (hiBroadcastMsg !== ''))
                )
                ?
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
                :
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                  >
                    Submit
                  </Button>
                </div>
              ) 
            )
          }
          </div>
        </div>

        <div className="preview_main_wrapper" style={currentData !== undefined ? { marginTop: '14px' } : null} >
          <Card className="preview-content">
            <div style={{ padding: '16px' }}>
              <h2>Preview</h2>
            </div>
            <div className="header-wrapper">
              <div className="logo">
                <img src={this.state.siteIcon} alt="" />
              </div>
              <div className="header-data">
                <div>{this.state.shortSiteName}</div>
                <div>{this.state.siteUrl}</div>
              </div>
            </div>
            <Paper className="preview-content-wrapper" elevation={0}>
              <Typography variant="h5" component="h3"> ARTICLE LINK </Typography>
              <div>
                <a href={this.state.en.article_url}>{enArticle_url}</a>
              </div>
              <div>
                <a href={this.state.hi.article_url}>{hiArticle_url}</a>
              </div>
            </Paper>
            <Paper className="preview-content-wrapper" elevation={0}>
              <Typography variant="h5" component="h3"> TITLE </Typography>
              <Typography component="p" className="size-grey-text"> {this.state.en.title}</Typography>
              <Typography component="p" className="size-grey-text"> {this.state.hi.title}</Typography>
            </Paper>
            <Paper className="preview-content-wrapper" elevation={0}>
              <Typography variant="h5" component="h3"> SUMMARY </Typography>
              <Typography component="p" className="size-grey-text">{this.state.en.summary}</Typography>
              <Typography component="p" className="size-grey-text">{this.state.hi.summary}</Typography>
            </Paper>
            <Paper className="preview-content-wrapper" elevation={0}>
              <Typography variant="h5" component="h3"> KEYWORDS </Typography>
              <Typography component="p" className="size-grey-text">
                {this.state.en.keywords && this.state.en.keywords.map((keyword, index) => <span key={index}>{keyword}, </span>)}
              </Typography>
              <Typography component="p" className="size-grey-text">
                {this.state.hi.keywords && this.state.hi.keywords.map((keyword, index) => <span key={index}>{keyword}, </span>)}
              </Typography>
            </Paper>
            <Paper className="preview-content-wrapper" elevation={0}>
              <Typography variant="h5" component="h3"> Images </Typography>
              <div >
                {
                  images && images.map((image, index) =>
                    <div key={index} className="image-wrapper">
                      <img src={(image && image.fileObjectUrl)} alt='' />
                    </div>
                  )
                }
                {
                  typeof allImages !== 'undefined' ?
                    allImages.map((allImage, index) =>
                      <div key={index} className="img-wrapper">
                        <img src={allImage.path} alt='' />
                      </div>
                    )
                    : ''
                }
              </div>
            </Paper>
            <Paper className="preview-content-wrapper" elevation={0} >
              <Typography variant="h5" component="h3"> Videos </Typography>
              <div className="video-wrapper">
                {
                  videos.length ?
                    videos.map((video, index) =>
                      <ReactPlayer
                        key={index}
                        url={(video.fileObjectUrl)}
                        playing={false}
                        controls={true}
                      />
                    )
                    : null
                }
                {
                  typeof allVideos !== 'undefined' ?
                    allVideos.map((video, index) =>
                      <ReactPlayer
                        key={index}
                        url={video.path}
                        playing={false}
                        controls={true}
                      />
                    )
                    : null
                }
              </div>
            </Paper>
          </Card>
        </div>
      </div>
    )
  }
}

export default SimpleForm;