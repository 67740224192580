import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
// import {
//   Tab,
//   Tabs,
//   Button,
//   Grid,
//   ButtonGroup,
//   TextField,
//   FormGroup,
//   Box,
//   List,
//   ListItem,
//   Snackbar,
//   SnackbarContent
// } from '@material-ui/core';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import Moment from 'react-moment';
import './style.css';
import Api from '../../services/api';

class DonateNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      currentIndex: 0,
      selectedLanguage: 0,
      message: '',
      link: '',
      pageNum: 1,
      pageSize: 20,
      loader: false,
      requestCount: 0,
      notificationLogData: [],
      error: false,
      openSnackbar: false,
      snackbarContent: {
        variant: '',
        message: ''
      }
    };
  }

  componentDidMount() {
    this.getNotificationLog();
  }

  getNotificationLog = () => {
    this.setState({ loader: true, notificationLogData: [] })
    Api.getDonateNotificationLog().
      then(res => res)
      .then(res => {
        this.setState({
          notificationLogData: res.data,
          loader: false,
          requestCount: res.totalNotifs
        });
      }).catch(err => {
        this.setState({ error: err })
      });
  }

  handleMessage = () => {
    let { selectedLanguage, message, link } = this.state;
    let payload = {
      locale: selectedLanguage ? 'hi' : 'en',
      donateURL: link,
      message
    };

    Api
      .sendDonateNotifications(payload)
      .then(res => {
        if (res.success) {
          this.handleClearForm();
          this.setState({
            openSnackbar: true,
            snackbarContent: {
              variant: 'success-bg-color',
              message: 'Sending Notifications'
            }
          });
        } else {
          this.handleClearForm();
        }
      });
  }

  handleClearForm = () => {
    this.setState({
      message: '',
      link: ''
    });
  }

  closeSnackbar = () => {
    this.setState({ openSnackbar: false });
  }

  changePage = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      pageNum: selectedPage,
    }, () => {
      this.getNotificationLog()
    });
  }

  renderPager = () => {
    let pages = this.state.requestCount / this.state.pageSize;
    return <ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={this.changePage}
      containerClassName={'pagination'}
      subContainerClassName={'paginate'}
      activeClassName={'active'}
      initialPage={0}
    />;
  }

  render() {
    const {
      currentTab,
      selectedLanguage,
      message,
      openSnackbar,
      loader,
      snackbarContent,
      notificationLogData
    } = this.state;
    return (
      <div style={{ marginBottom: '150px' }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => this.closeSnackbar()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SnackbarContent
            className={snackbarContent.variant}
            message={snackbarContent.message} />
        </Snackbar>
        <Tabs
          value={currentTab}
          style={{
            backgroundColor: '#efefef',
            top: '0px',
            position: 'fixed',
            width: '100%',
            zIndex: '20',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.09)'
          }}
        >
          <Tab
            onClick={() => this.setState({ currentTab: 0 })}
            label="Add Custom Notification"
          />

          <Tab
            onClick={() => { this.setState({ currentTab: 1 }); this.getNotificationLog() }}
            label="Notification Log"
          />
        </Tabs>
        {currentTab === 0 &&
          <div className="add-custom-notification-wrapper">
            <Box>
              <Grid item>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="large"
                  className="select-language-switcher"
                  aria-label="large contained secondary button group"
                >
                  <Button onClick={() => this.setState({
                    selectedLanguage: 0
                  })} className={`${!selectedLanguage ? 'active' : 'inactive'}`}>English</Button>
                  <Button
                    style={{ fontWeight: 'bold' }}
                    className={`${selectedLanguage ? 'active' : 'inactive'}`}
                    onClick={() => this.setState({
                      selectedLanguage: 1
                    })}>हिंदी</Button>
                </ButtonGroup>
              </Grid>
              <div className="input-container">
                <FormGroup>
                  <TextField
                    id="outlined-full-width"
                    label={selectedLanguage ? 'लिंक' : 'Link'}
                    placeholder={selectedLanguage ? 'लिंक दर्ज करें' : 'Enter the link'}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.link}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => this.setState({ link: e.target.value })}
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    label={selectedLanguage === 0 ? 'Type Your Message in English' : 'अपना संदेश हिंदी में लिखें'}
                    value={message}
                    multiline
                    rowsMax="4"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => e.target.value.length <= 240 ? this.setState({ message: e.target.value }) : ''}
                  />
                  <FormGroup className="button-section">
                    <Button className="submit-button" variant="contained" color="primary" onClick={this.handleMessage}> Submit </Button>
                    <Button className="clear-button" variant="contained" color="primary" onClick={this.handleClearForm}> Clear </Button>
                  </FormGroup>
                </FormGroup>
              </div>
            </Box>
          </div>
        }
        {currentTab === 1 &&
          <div className="notification-log-wrapper">
            <List className="notification-log-list">
              {notificationLogData.length > 0 ? notificationLogData.map((item, index) => (
                <ListItem className="item-wrapper" key={index}>
                  <div className="title" style={{ marginLeft: '10px', display: 'inline-block' }}>
                    {
                      item.message ?
                        (<React.Fragment><span className="main-text">
                          {item.donateURL}
                        </span>
                          <div className="sub-text">
                            {item.message}
                          </div></React.Fragment>)
                        :
                        <span
                          style={{
                            color: '#f44336',
                            fontStyle: 'italic',
                            verticalAlign: 'middle'
                          }}>
                          This request has no text!
                      </span>
                    }
                  </div>
                  <div className="badges-wrapper">
                    <span>
                      <Moment format="DD/MMM/YY" >{item.createdAt}</Moment>
                      <br />
                      <Moment format="hh:mm:ss a" >{item.createdAt}</Moment>
                      <br />
                      {/* <span style={{ fontWeight: 'normal' }}>{item.key} </span> */}
                    </span>
                  </div>
                </ListItem>
              )) : <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex' }}>
                  <h2 className='empty-list'>
                    <i className='material-icons'>error</i>
                    No Notification Logs
                  </h2>
                </div>}
            </List>
            <div
              className='pagination-wrapper'
              style={{ padding: '0 20px' }}>
              {
                this.renderPager()
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

export default DonateNotification;