import React, { Component } from 'react';
import {
	Paper,
	Typography,
	TextField,
	Button,
	Icon,
	CircularProgress,
	Snackbar,
	Chip,
	Checkbox,
	FormControlLabel,
	SnackbarContent,
	Card
} from '@material-ui/core';
import Api from '../../services/api';
import ReactPlayer from 'react-player';
import './style.css';

function decodeHtml(html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
}

class StepperForm extends Component {
	constructor(props) {
		super(props);
		this.props.currentData !== undefined ?
			(
				this.state = {
					_id: this.props.currentData._id,
					activeStep: 0,
					allImages: this.props.currentData.allImages,
					images: [],
          videos: [],
          fetchingTitle: false,
					allVideos: this.props.currentData.videos,
					en: {
						links: this.props.currentData.en.links,
						summary: this.props.currentData.en.summary,
						keywords: this.props.currentData.en.keywords,
						title: this.props.currentData.en.title,
						article_url: this.props.currentData.en.article_url,
					},
					hi: {
						links: this.props.currentData.hi.links,
						summary: this.props.currentData.hi.summary,
						keywords: this.props.currentData.hi.keywords,
						title: this.props.currentData.hi.title,
						article_url: this.props.currentData.hi.article_url,
					},
					preview: false,
					loader: false,
					toastMssg: '',
					showToastMssg: false,
					sendPushNotifEn: this.props.currentData.en.pushNotificationSent,
					sendPushNotifHi: this.props.currentData.hi.pushNotificationSent,
					disableEnCheckbox: this.props.currentData.en.pushNotificationSent,
					disableHiCheckbox: this.props.currentData.hi.pushNotificationSent,
				}
			) : (
				this.state = {
					activeStep: 0,
					images: [],
					videos: [],
					preview: false,
					loader: false,
          toastMssg: '',
          fetchingTitle: false,
					showToastMssg: false,
					sendPushNotifEn: false,
					sendPushNotifHi: false,
					en: {
						links: [],
						summary: '',
						keywords: [],
						title: '',
						article_url: '',
					},
					hi: {
						links: [],
						summary: '',
						keywords: [],
						title: '',
						article_url: '',
					}
				}
			);
	}

	getSteps = () => {
		return [
			'Alt News Article Link',
			'Title',
			'summary',
			'Keywords',
			'Images',
			'Video',
			'Links',
			'Push Notification'
		];
	}

	handlePushNotif = (e) => {
		let lang = e.target.value;
		if (lang === 'en') {
			let sendPushNotifEn = !this.state.sendPushNotifEn;
			this.setState({ sendPushNotifEn });
		}
		else {
			let sendPushNotifHi = !this.state.sendPushNotifHi;
			this.setState({ sendPushNotifHi });
		}
	}
	getStepContent = (step) => {
		const { en, hi, images, allImages, videos, allVideos, sendPushNotifEn, disableEnCheckbox, sendPushNotifHi, disableHiCheckbox } = this.state
		return (
			<React.Fragment>
				<div className="form-wrapper">
					<div>1. Alt news article link</div>
					<TextField
						id="article_url"
						className="form-textField"
						label="Article Link"
						placeholder="Alt news article link"
						margin="normal"
						onChange={evt => this.handleInput(evt, 'article_url', 'en')}
						value={en.article_url}
					/>
					<TextField
						id="article_url"
						className="form-textField"
						label="आर्टिकल लिंक"
						placeholder="ऑल्ट न्यूज़ आर्टिकल लिंक"
						margin="normal"
						onChange={evt => this.handleInput(evt, 'article_url', 'hi')}
						value={hi.article_url}
					/>
				</div>
				<div className="form-wrapper">
					<div>2. Title</div>
					<TextField
						multiline={true}
						rows={2}
						id="title"
						className="form-textField"
						label="title"
						placeholder="Title"
						margin="normal"
						onChange={evt => this.handleInput(evt, 'title', 'en')}
						value={en.title}
					/>
					<TextField
						multiline={true}
						rows={2}
						id="title"
						className="form-textField"
						label="शीर्षक"
						placeholder="शीर्षक"
						margin="normal"
						onChange={evt => this.handleInput(evt, 'title', 'hi')}
						value={hi.title}
					/>
				</div>
				<div className="form-wrapper">
					<div>3. Summary</div>
					<TextField
						id="summary"
						className="form-textField"
						label="summary"
						placeholder="summary"
						margin="normal"
						onChange={evt => this.handleInput(evt, 'summary', 'en')}
						value={en.summary}
					/>
					<TextField
						id="summary"
						className="form-textField"
						label="विवरण"
						placeholder="विवरण"
						margin="normal"
						onChange={evt => this.handleInput(evt, 'summary', 'hi')}
						value={hi.summary}
					/>
				</div>
				<div className="form-wrapper">
					<div>4. Keywords</div>
					<div>
						{en.keywords.map((keyword, index) =>
							<Chip
								key={index}
								style={{ margin: '3px' }}
								onDelete={() => this.deleteKeyword(index, 'en')}
								label={keyword}
								variant="outlined"
								color="primary"
							/>
						)}
						<TextField
							id="keyword"
							className="form-textField"
							label="Keywords"
							placeholder="Type keyword and press RETURN"
							margin="normal"
							onKeyPress={(evt) => this.handleKeyword(evt, 'en')}
						/>
					</div>
					<div>
						{hi.keywords.map((keyword, index) =>
							<Chip
								key={index}
								style={{ margin: '3px' }}
								onDelete={() => this.deleteKeyword(index, 'hi')}
								label={keyword}
								variant="outlined"
								color="secondary"
							/>
						)}
						<TextField
							id="keyword"
							className="form-textField"
							label="कीवर्ड"
							placeholder="कीवर्ड टाइप करें और रिटर्न दबाएँ"
							margin="normal"
							onKeyPress={(evt) => this.handleKeyword(evt, 'hi')}
						/>
					</div>
				</div>
				<div className="upload-image-container" style={{ marginTop: '16px' }}>
					<div>	5. Images</div>
						<img
							className="pointer"
							onClick={this.addImagefield}
							src={require('../../assets/icons/plus.svg')}
							alt=''
							style={{ width: '40px' }}
						/>
						{
							images.map((image, index) => {
								return (
									<div className="media-wrapper" key={`${index}-${image._id}`}>
										<label htmlFor={`img-${index}`} >
											<Chip clickable label="Upload Image" />
										</label>
										<input
											type="file"
											id={`img-${index}`}
											className="form-mediaField"
											label={images[index].name}
											placeholder="Image"
											onChange={evt => this.handleImage(evt, index)}
											key={index}
											style={{ display: 'none' }}
										/>
										<div className="img-wrapper">
											<img src={image.fileObjectUrl} alt='' />
										</div>
										<i
											style={{ alignSelf: 'center' }}
											className="material-icons danger-icon"
											onClick={() => this.removeImageField(index)}
										>
											delete
  									</i>
									</div>
								);
							})
						}
						{
							typeof allImages !== 'undefined' ?
								allImages.map((image, index) => {
									return (
										<div className="media-wrapper" key={`${index}-${image._id}`}>
											<div className="img-wrapper" style={{ width: '70px' }}>
												<img src={allImages.path} alt='' />
											</div>
											<i
												style={{ alignSelf: 'center' }}
												className="material-icons danger-icon"
												onClick={() => this.removeAllImageField(index)}
											>
												delete
  										</i>
										</div>
									);
								})
							: null
						}
				</div>
				<div className="upload-video-container" style={{ marginTop: '16px' }}>
					<div> 6.Videos </div>
						<img
							className="pointer"
							onClick={this.addVideofield}
							src={require('../../assets/icons/plus.svg')}
							alt=''
							style={{ width: '40px' }}
						/>
						{
							videos.map((video, index) => {
							return (
								<div className="media-wrapper" key={`${index}-${video._id}`}>
									<label htmlFor={`vid-${index}`}  >
										<Chip clickable label="Upload Video" />
									</label>
									<input
										type="file"
										id={`vid-${index}`}
										className="form-mediaField"
										label={videos[index].url.name}
										placeholder="Image"
										onChange={evt => this.handleVideo(evt, index)}
										key={index}
										style={{ display: 'none' }}
									/>
									{
                      video.path && <ReactPlayer
                        url={video.path}
                        playing={false}
                        controls={true}
                        width="60%"
                        height="200px"
                      />
                    }
                    {
											video.fileObjectUrl &&
											<ReactPlayer
											key={index}
											url={video.fileObjectUrl}
											width='300px'
											controls={true}
										/>
                    }
									<i
										className="material-icons danger-icon"
										onClick={() => this.removeVideofield(index)}
									>
										delete
  								</i>
								</div>
							);
							})
						}
						{
							typeof allVideos !== 'undefined' ?
							allVideos.map((video, index) => {
								return (
									<div className="media-wrapper" key={`${index}-${video._id}`}>

										<label htmlFor={index} >
											<Chip clickable label="Upload Video" />
											{' '}
											{allVideos.path}
										</label>
										<i
											className="material-icons danger-icon"
											onClick={() => this.removeAllVideofield(index)}
										>
											delete
  								</i>
									</div>
								);
							}) 
							: null
						}
				</div>
				<div className="add-links-container" style={{ marginTop: '16px' }}>
					<div > 7. Add Links	</div>
					<div style={{padding: '5px'}}>
						<i
							className="material-icons success-icon"
							style={{ fontSize: '31px' }}
							onClick={this.addLinkfield}
						>
							add
  					</i>
						{
							en.links.map((link, index) => {
							return (
								<div className="media-wrapper" key={index}>
									<TextField
										id="Link"
										className="form-mediaField"
										label={'Link-' + (index + 1)}
										placeholder="Social media Link"
										margin="normal"
										onChange={evt => this.handleLink(evt, index, 'en')}
										key={index}
										value={en.links[index].url}
									/>
									<i
										className="material-icons danger-icon"
										onClick={() => this.removeLinkfield(index, 'en')}
									>
										delete
  								</i>
								</div>
							);
							})
						}
						{
							hi.links.map((link, index) => {
							return (
								<div className="media-wrapper" key={index}>
									<TextField
										id="Link"
										className="form-mediaField"
										label={'लिंक-' + (index + 1)}
										placeholder="सोशल मीडिया लिंक"
										margin="normal"
										onChange={evt => this.handleLink(evt, index, 'hi')}
										key={index}
										value={hi.links[index].url}
									/>
									<i
										className="material-icons danger-icon"
										onClick={() => this.removeLinkfield(index, 'hi')}
									>
										delete
  								</i>
								</div>
							);
							})
						}
					</div>
				</div>
				<div className="form-wrapper">
					<div>9. Push Notifications</div>
					<FormControlLabel
						control={
							<Checkbox
								checked={sendPushNotifEn}
								onChange={this.handlePushNotif}
								value="en"
								disabled={disableEnCheckbox}
							/>
						}
						label="Send Push Notification English"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={sendPushNotifHi}
								onChange={this.handlePushNotif}
								value="hi"
								disabled={disableHiCheckbox}
							/>
						}
						label="Send Push Notification Hindi"
					/>
				</div>
			</React.Fragment>
		)
	}

	handleKeyword = (evt, lang) => {
		if (evt.key === 'Enter' && evt.target.value !== '') {
			let keyword = evt.target.value;
			let data = this.state[lang];
			data.keywords.push(keyword);
			if (lang === 'en') {
				this.setState({ en: data });
			} else {
				this.setState({ hi: data });
			}
			evt.target.value = '';
		}
	}

	deleteKeyword = (index, lang) => {
		let data = this.state[lang];
		data.keywords = data.keywords.filter((keyword, indx) => index !== indx);
		if (lang === 'en') {
			this.setState({ en: data });
		} else {
			this.setState({ hi: data });
		}
	}

	addImagefield = () => {
		let imageArray = this.state.images;
		imageArray.push({ url: '' });
		this.setState({ images: imageArray });
	};

	removeImageField = index => {
		let images = [...this.state.images];
		images = images.filter((image, key) => key !== index);
		this.setState({ images });
	};

	removeAllImageField = index => {
		let allImages = [...this.state.allImages];
		allImages = allImages.filter((image, key) => key !== index);
		this.setState({ allImages });
	};

	addLinkfield = () => {
		let en = this.state.en;
		let hi = this.state.hi;
		en.links.push({ url: '' });
		hi.links.push({ url: '' });
		this.setState({ en, hi });
	};

	removeLinkfield = (index, lang) => {
		let data = this.state[lang];
		data.links = data.links.filter((image, key) => key !== index);
		if (lang === 'en') {
			this.setState({ en: data });
		} else {
			this.setState({ hi: data });
		}
	};

	addVideofield = () => {
		let videoArray = this.state.videos;
		videoArray.push({ url: '' });
		this.setState({ videos: videoArray });
	};

	removeVideofield = index => {
		let videos = [...this.state.videos];
		videos = videos.filter((image, key) => key !== index);
		this.setState({ videos });
	};

	removeAllVideofield = index => {
		let allVideos = [...this.state.allVideos];
		allVideos = allVideos.filter((image, key) => key !== index);
		this.setState({ allVideos });
	};

	handleImage = (evt, index) => {
		let imageArr = this.state.images;
		imageArr[index].url = evt.target.files[0];
		imageArr[index].fileObjectUrl = URL.createObjectURL(evt.target.files[0]);
		this.setState({ images: imageArr });
	};

	handleLink = (evt, index, lang) => {
		let data = this.state[lang];
		data.links[index].url = evt.target.value;
		if (lang === 'en') {
			this.setState({ en: data });
		} else {
			this.setState({ hi: data });
		}
		evt.target.value = '';
	};

	handleVideo = (evt, index) => {
		if (evt.target.files[0].type === 'video/3gpp' || evt.target.files[0].type === 'video/3gp') {
			this.setState({ showToastMssg: true, toastMssg: 'Unsupported Video Format' });
		} else {
			let videoArr = this.state.videos;
			videoArr[index].url = evt.target.files[0];
			videoArr[index].fileObjectUrl = URL.createObjectURL(evt.target.files[0]);
			this.setState({ videos: videoArr });
		}
		setTimeout(() => {
		}, 200);
	};

  decodeHTML =  (html) => {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

   decodeHTML =  (html) => {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };


	handleInput = (evt, field, lang) => {
		let data = this.state[lang];
		data[field] = evt.target.value;
		let seedData = this.props.seedData;
		let filtered = [];
		if (field === 'article_url') {
			filtered = seedData.filter(story => story[lang][field] === evt.target.value);
		}

		if (filtered.length !== 0 && evt.target.value != '') {
			this.setState({
				showToastMssg: true,
				toastMssg: 'Article already exist!!!'
			});
		}

		if (evt.target.value !== '' && field === 'article_url' && lang === 'en') {
			let title = data[field].replace('https://www.altnews.in/', '').split('-').join(' ').replace('/', '');
			title = title.replace(title[0], title[0].toUpperCase());
		}
		if (evt.target.value !== '' && field === 'article_url' && lang === 'hi') {
			let title = data[field].replace('https://www.altnews.in/hindi/', '').split('-').join(' ').replace('/', '');
			title = title.replace(title[0], title[0].toUpperCase());
		}

		if (lang === 'en') {
			this.setState({ en: data });
		} else {
			this.setState({ hi: data });
		}
		this.handleNext();
	};

	handleNext = async () => {
		const { activeStep, en, hi } = this.state;
		const status = hi.article_url && en.article_url ? 2 : en.article_url ? 0 : 1;
		const url = en.article_url ? en.article_url : hi.article_url;
		const engUrl = en.article_url ? en.article_url : ''
		const hindiUrl = hi.article_url ? hi.article_url : ''
		const clusterUrl = en.article_url && hi.article_url ? 1 : 0;
		if (clusterUrl) {
			const engData = await Api.getArticleDetailsOnAddSeed(engUrl, 0)
				.then(res => res.json()).then(res => res);
			const hinData = await Api.getArticleDetailsOnAddSeed(hindiUrl, 1)
				.then(res => res.json()).then(res => res);
			this.setState({
				en: {
					...this.state.en,
					title: engData[0] ? decodeHtml(engData[0].title.rendered) : '',
				},
				hi: {
					...this.state.hi,
					title: hinData[0] ? decodeHtml(hinData[0].title.rendered) : '',
				}
			})
		}
		else if (engUrl || hindiUrl) {
			Api.getArticleDetailsOnAddSeed(url, status)
				.then(res => res.json()).then(res => {
					if (res.length > 0) {
						if (status == 0) {
							this.setState({
								en: {
									...this.state.en,
									title: decodeHtml(res[0].title.rendered)
								},
								hi: {
									...this.state.hi,
									title: ''
								}
							})
						}
						else if (status == 1) {
							this.setState({
								hi: {
									...this.state.hi,
									title: decodeHtml(res[0].title.rendered)
								},
								en: {
									...this.state.en,
									title: ''
								}
							})
						}
						if (status == 0) {
							this.setState({
								en: {
									...this.state.en,
									summary: decodeHtml(this.state.en.title)
								},
								hi: {
									...this.state.hi,
									summary: ''
								},
							})
						} else if (status == 1) {
							this.setState({
								en: {
									...this.state.en,
									summary: ''
								},
								hi: {
									...this.state.hi,
									summary: decodeHtml(this.state.hi.title)
								},
							})
						} else if (status == 2) {
							this.setState({
								hi: {
									...this.state.hi,
									summary: decodeHtml(this.state.hi.title)
								},
								en: {
									...this.state.en,
									summary: decodeHtml(this.state.en.title)
								},
							})
						}
					}
				});
		}
	};

	submit = () => {
		const { en, hi, sendPushNotifEn, sendPushNotifHi, allImages, images, videos, sendPushNotif, _id	} = this.state;

		const { currentData } = this.props;
		this.setState({ loader: true });
		const reset = {
			activeStep: 0,
			images: [],
			videos: [],
			en: {
				links: [],
				summary: '',
				keywords: [],
				title: '',
				article_url: '',
			},
			hi: {
				links: [],
				summary: '',
				keywords: [],
				title: '',
				article_url: '',
			},
			loader: false,
			preview: false,
			sendPushNotifEn: false,
			sendPushNotifHi: false,
		};

		try {
			const formData = new FormData();
			formData.append('en', JSON.stringify(en));
			formData.append('hi', JSON.stringify(hi));
			formData.append('sendPushNotificationEn', sendPushNotifEn);
			formData.append('sendPushNotificationHi', sendPushNotifHi);
			formData.append('allImages', JSON.stringify(allImages));

			images.map(image => {
				delete image.fileObjectUrl;
				formData.append('media', image.url);
				return formData;
			});

			videos.map(video => {
				formData.append('media', video.url);
				return formData;
			});

			if (typeof allImages === 'undefined') {
				Api.createSeedData(formData).then(response => {
					if (sendPushNotif) Api.broadcastPushNotifcation();
					this.setState(reset);
					this.setState({ showToastMssg: true, toastMssg: 'Seed Data created successfully' });
					window.location.reload(false);
				});
			} else {
				if (currentData.en.pushNotificationSent) {
					formData.set('sendPushNotificationEn', 'disabled');
				} else {
					formData.set('sendPushNotificationEn', sendPushNotifEn);
				}
				if (currentData.hi.pushNotificationSent) {
					formData.set('sendPushNotificationHi', 'disabled');
				} else {
					formData.set('sendPushNotificationHi', sendPushNotifHi);
				}
				Api.updateSeedData(formData, _id).then(response => {
					this.setState(reset);
					this.props.onUpdate();
					this.setState({ showToastMssg: true, toastMssg: 'Seed data updated successfully.' });
					window.location.reload(false);
				});
			}
		} catch (error) {
			this.setState({ loader: false });
			
		}
	};

	closeToastMssg = () => {
		this.setState({ showToastMssg: false });
	}

	preview = () => {
		let preview = true;
		this.setState({ preview });
	};

	render() {
		const steps = this.getSteps();
		const { showToastMssg, toastMssg, loader, en, hi, images, allImages, videos, allVideos } = this.state;
		return (
			<div className="lite-seed-data-wrapper">
				<div className="simpleformwrapper">
					<Typography style={{
						width: '100%',
						display: 'inline-block'
					}}
						variant="h4"
					>
  				</Typography>
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						open={showToastMssg}
						autoHideDuration={6000}
						onClose={this.closeToastMssg}>
						<SnackbarContent
							className={toastMssg.includes('successfully') ? 'success-bg-color' : 'error-toast'}						
							message={<span id="message-id">{toastMssg}</span>}
						/>
					</Snackbar>
						{this.getStepContent()}
						<Button
							variant="contained"
							color="primary"
							onClick={() => this.submit()}
						>
							Save
						</Button>
				</div>
				<div	className="preview_main_wrapper"	>
					{
						loader ?
						<div className="loader">
							<CircularProgress className="svg-spin" color="secondary" />
						</div>
						:
						null
					}
					<Card className="preview-content-withoutData">
						<Paper className="full-width" elevation={0}>
							<Typography variant="h5" component="h3">
								{steps[0]}
							</Typography>
							<div>
								<a href={en.article_url}>{en.article_url}</a>
							</div>
							<div>
								<a href={hi.article_url}>{hi.article_url}</a>
							</div>
						</Paper>
						<Paper className="full-width" elevation={0}>
							<Typography variant="h5" component="h3">
								{steps[1]}
							</Typography>
							<Typography component="p" className="small-grey-text">{en.title}</Typography>
							<Typography component="p" className="small-grey-text">{hi.title}</Typography>
						</Paper>
						<Paper className="full-width" elevation={0}>
							<Typography variant="h5" component="h3">
								{steps[2]}
							</Typography>
							<Typography component="p" className="small-grey-text">{en.summary}</Typography>
							<Typography component="p" className="small-grey-text">{hi.summary}</Typography>
						</Paper>
						<Paper className="full-width" elevation={0}>
							<Typography variant="h5" component="h3">
								{steps[3]}
							</Typography>
							<Typography component="p" className="small-grey-text">
								{en.keywords.map((keyword, index) => <span key={index}>{keyword}, </span>)}
							</Typography>
							<Typography component="p" className="small-grey-text">
								{hi.keywords.map((keyword, index) => <span key={index}>{keyword}, </span>)}
							</Typography>
						</Paper>
						<Paper className="full-width" elevation={0}>
							<Typography variant="h5" component="h3">	{steps[4]}	</Typography>
							<div>
								{
									images.length ?
									images.map((image, index) => (
										<div className="img-wrapper" key={index}>
											<img src={image.fileObjectUrl} alt='' />
										</div>
									))
									: null
								}
								{
									typeof allImages !== 'undefined' ?
										allImages.map((allImage, index) =>
											<div key={index} className="img-wrapper">
												<img src={allImage.path} alt='' />
											</div>
										)
										:	''
								}
							</div>
						</Paper>
						<Paper className="full-width" elevation={0}>
							<Typography variant="h5" component="h3">
								{steps[5]}
							</Typography>
							<div className="video-wrapper">
								{
									videos.length ?
									videos.map((video, index) => (
										<ReactPlayer
											key={index}
											url={video.fileObjectUrl}
											width='300px'
											controls={true}
										/>
									))
									: null
								}

								{
									typeof allVideos !== 'undefined' ?
										allVideos.map((allVideo, index) =>
											<ReactPlayer
												key={index}
												url={allVideo.path}
												width='300px'
												controls={true}
											/>
										)
										:	''
								}
							</div>
						</Paper>
						<Paper className="full-width" elevation={0}>
							<Typography variant="h5" component="h3">
								{steps[6]}
							</Typography>
							<div>
								{
									en.links.length  ?
									en.links.map((link, index) => (
										<div key={index} className='break-word'>
											<a href={link.url}>{link.url}</a>
										</div>
									))
									: null
								}
							</div>
							<div>
								{
									hi.links.length  ?
									hi.links.map((link, index) => (
										<div key={index}>
											<a href={link.url}>{link.url}</a>
										</div>
									))
									: null
								}
							</div>
						</Paper>
					</Card>
				</div>
			</div>
		);
	}
}

export default StepperForm;
