import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import './style.css';
import { Button } from '@material-ui/core';
import SimpleForm from '../simple-form';
import Api from '../../services/api';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player';
import Chip from '@material-ui/core/Chip';
import Moment from 'react-moment';

class LSD extends Component {
  constructor(props) {
    super(props);
    this.SimpleForm = React.createRef();
    this.state = {
      showData: true,
      data: [],
      seedData: []
    }
  }

  listSeed = () => {
    Api.getLiteSeedList()
      .then(res => {
        return res;
      })
      .then(res => {
        let data = res.data;
        this.setState({ seedData: data });
      })
      .catch(error => {
        })
  }

  componentDidMount = () => {
    this.setState({ data: this.props.currentRequest });
    this.listSeed();
  }

  removeDifferentRequest = (id) => {
    const { data } = this.state;
    const filteredRequest = data.filter(item => item._id !== id);
    this.setState({ data: filteredRequest })
  }

  render() {
    return (
      <div className='liteSeedData-view-wrapper'>
        <Paper className='liteSeedData-view'>
          <div className="action-bar">
            <h3>Create Lite Seed Data corresponding to media below</h3>
            <div>
              <Button onClick={() => this.props.addLiteSeedData()}>
                <i className='material-icons' > cancel  </i>
              </Button>
            </div>
          </div>
          <div className='detailed-view-images-wrapper'>
            {this.state.data && this.state.data.length > 0 ?
              this.state.data.map((request, index) =>
                <Card key={request._id} className={`detailed-view-images`}>
                  <i
                    className='material-icons'
                    style={{ zIndex: 3 }}
                    onClick={() => this.removeDifferentRequest(request._id)}>
                    cancel
                  </i>
                  {
                    request.images && request.images.length > 0 ?
                      <CardMedia
                        style={{
                          height: 0,
                          paddingTop: '56.25%',
                        }}
                        image={request.images[0].path}
                      />
                      :
                      <ReactPlayer
                        className='lazy-vid'
                        style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
                        key={index}
                        url={request.videos[0].path}
                        playing={false}
                        controls={true}
                        width='100%'
                        height='85%' />
                  }
                  <Chip
                    className='bottom-left-chip'
                    style={{ zIndex: 3 }}
                    label={<Moment format='DD MMM YYYY'>
                      {request.createdAt}
                    </Moment>}
                    color='secondary'>
                  </Chip>
                  <Chip
                      className="bottom-right-chip"
                      label={`${request.preferredLanguage === "en" ? "EN" : "HI"}`}
                      color={`${request.preferredLanguage === "en" ? "primary" : "secondary"}`}
                    />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {request.description ? request.description : ''}
                    </Typography>
                  </CardContent>
                </Card>
              ) :
              <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex', width: '100%' }}>
                <h2 className='empty-list'>
                  <i className='material-icons'>error</i>
                No Data Found To Show
              </h2>
              </div>
            }
          </div>
          <SimpleForm
            data={this.props.currentRequest}
            status={this.props.showLiteSeedData}
            close={this.props.addLiteSeedData}
            liteSeedData={this.state.seedData}
            showSelectedRequest = {this.props.showSelectedRequest}
          />
        </Paper>
      </div>
    );
  }
}

export default LSD;