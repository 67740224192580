import React, { useState } from 'react';
import './style.css';

// import {
//   Card,
//   CardMedia,
//   Chip,
//   CardContent,
//   Typography,
//   Paper,
//   Button,
//   Box
// } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import ReactPlayer from 'react-player';
import Moment from 'react-moment';
import SeedDataTemplate from '../../templates/seed-data-template';
import Api from '../../services/api';
// import socketIOClient from 'socket.io-client';
import constants from '../../services/constants';

const ConfirmationPage = ({ currentRequest, arcticleItem, ...props }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const url = constants.BASE_URL.substring(7, constants.BASE_URL.length - 5);
  const altIcon = require('../../assets/altnews.jpg');
  // const connectToSocket = () => {
  //   const socket = socketIOClient(url);
  //   socket.connect();
  //   socket.on('processing-status', (status) => {
  //     if (status.status) {
  //       // handle socket response
  //       new Notification('Alt News', {
  //         icon: altIcon,
  //         body: status.status,
  //       });
  //     }
  //   });
  // };

  const bulkMerge = (fc, requests) => {
    setIsDisabled(true);
    Api.bulkMergeWithFC(fc, requests).then(res => {
      if (res.success) {
        props.mergeStatus(res.success, 'Bulk Merge started you will be notified once completed.', 'success-bg-color');
        props.openDialog(false)
        setIsDisabled(false)
        window.location.reload(false);
      }
      else {
        console.error('Error While Initiating the Bulk Merge')
        props.mergeStatus(res.success, res.mssg, 'danger-bg-color');
        props.openDialog(false)
      }
    });
  };

  return (
    <div className="suggested-fc-overlay">
      <div style={{ backgroundColor: '#fff', margin: '40px', borderRadius: '5px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 15px 5px ' }}>
          <span style={{ fontSize: '25px' }}>Confirm want to merge</span>
          <Box style={{ width: '150px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              disabled={isDisabled}
              onClick={() => bulkMerge(arcticleItem, currentRequest)}
              variant="contained"
              color="secondary">
              {isDisabled ? 'Merging' : 'Merge'}
              {isDisabled ? <CircularProgress /> : ''}
            </Button>
            <i
              style={{ fontSize: '30px' }}
              className="material-icons secondary-color icon-action"
              onClick={() => props.openDialog(false)}
            >
              cancel
  					</i>
          </Box>
        </div>
        <Paper className="detailed-confirmation-image-wrapper">
          <Typography variant="h5">{`${currentRequest[0].status.toLowerCase() === "accepted" ? "Dashboard" : "Manage Request"} Similar Request`}</Typography>
          {currentRequest.length > 0 ? <div className="merge-images-preview">
            {currentRequest.map((request, index) =>
              <Card key={request._id} className="detailed-view-images" >
                <i
                  className='material-icons'
                  style={{ zIndex: 3 }}
                  onClick={() => props.removeDifferentRequest(request._id)}>
                  cancel
                </i>
                {request.images.length > 0 ?
                  <CardMedia
                    style={{
                      height: 0,
                      paddingTop: '56.25%',
                    }}
                    image={request.images[0].path}
                  />
                  :
                  <ReactPlayer
                    className='lazy-vid'
                    style={{ backgroundColor: '#222', marginTop: '10px', position: 'relative' }}
                    key={index}
                    url={request.videos[0].path}
                    playing={false}
                    controls={true}
                    width='100%'
                    height='250px' />
                }
                <Chip
                  className='bottom-left-chip'
                  style={{ zIndex: 3 }}
                  label={<Moment format='DD MMM YYYY'>
                    {request.createdAt}
                  </Moment>}
                  color='secondary'>
                </Chip>
                <Chip
                  className="bottom-right-chip"
                  label={`${request.preferredLanguage === "en" ? "EN" : "HI"}`}
                  color={`${request.preferredLanguage === "en" ? "primary" : "secondary"}`}
                />
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {request.description ? request.description : ''}
                  </Typography>
                </CardContent>
              </Card>
            )}</div> :
            <div style={{ textAlign: 'center', minHeight: '40vh', display: 'flex', width: '100%' }}>
              <h2 className='empty-list'>
                <i className='material-icons'>error</i>
                No Data Found To Show
              </h2>
            </div>
          }
          <div className="plus-icon">
            <i className="material-icons">add_circle</i>
          </div>
          <Typography variant="h5">Seed Data Article</Typography>
          <Card className="seed-data-confirmation merge-article">
            <SeedDataTemplate dataToPreview={arcticleItem} />
          </Card>
        </Paper>
      </div>
    </div>
  );
};

export default ConfirmationPage;