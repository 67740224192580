import React, { Component } from 'react';
import './style.css';
import Api from '../../services/api';
import List from '@material-ui/core/List'; 
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SeedDataTemplate from '../../templates/seed-data-template';

class SuggestedFactchecks extends Component {
  
	constructor(props) {
		super(props);
		this.state = {
			dataToCheck : this.props.dataToCheck,
			loader: false,
			suggestedFactChecks: [],
			currentData: [],
			preview: false,
			noSuggestion: false
		};
	}

	componentDidMount() {
		this.fetchSuggestedFactchecks(this.props.dataToCheck.images);
	}

  fetchSuggestedFactchecks = (images) => {
  	this.setState({ loader: true });
  	Api.getSuggesgtedFactchecks(images).then(res => {
  		if (res.success === false) {
  			// DO SOMETHING
  			this.setState({ noSuggestion: true });
  		}else {
  			let suggestedFactChecks = res.matchedFC;
  			this.setState({ suggestedFactChecks });
  		}
  		this.setState({ loader: false });
  	});
  }

  showPreview = (index) => {
  	let  currentData = this.state.suggestedFactChecks[index];
  	let preview = true;
  	this.setState({ currentData, preview });
  }

  mergeWithFC = () => {
  	let { dataToCheck } = this.props;
  	let factCheck = this.state.currentData;
  	Api.mergeWithFC(dataToCheck, factCheck).then(res => {

  		this.props.mergeStatus(res.success);
  		this.props.closeModal();
  	});
  }

  render() { 
  	let { suggestedFactChecks, dataToCheck, loader, preview, currentData, noSuggestion } = this.state;
  	let maxHeight = window.innerHeight - 150 + 'px';
  	return ( 
  		<div className="suggested-fc-wrapper" style={{maxHeight}}>
  			{
          
  				loader ? 
  					<div className="loader-wrapper">
  						<CircularProgress /> 
  					</div>
  					: null
  			}
  			<div className="flex-fc-wrapper">
  				{ 
  					suggestedFactChecks.length ? 
  						(<div className="suggested-list-wrapper">
  							<List>
  								{suggestedFactChecks.map((item, index) => 
  									<ListItem 
  										key={index}
  										// style={{backgroundColor: "#e8eaf6"}}
  										onClick={ () => this.showPreview(index)}
  									>
  										<div>{item.en.title}</div>
  										<div>{' / '+ item.hi.title}</div>
  										{' '}
  										{
  											item.allImages.length ? 
  												(<i className="material-icons primary-color">
                              image
  												</i>) 
  												: null
  										}
  										{
  											item.videos.length ? 
  												(<i className="material-icons primary-color">
                            videocam
  												</i>) 
  												: null
  										}
  									</ListItem>
  								)}
  							</List>
  						</div>) : 
  						<div style={{flex: 1, textAlign: 'center'}}>
  							{
  								noSuggestion ? <p>No Fact Check Found</p> : null
  							}
  						</div>
  				}

  				{
  					preview ? (
  						<div className="suggested-fc-preview-wrapper" >
  							<div className="suggested-fc-actions-wrapper">
  								<Button 
  									variant="contained" 
  									color="primary"
  									onClick={() => this.mergeWithFC()}
  								> 
                    Merge 
  								</Button>
  								<Button 
  									color="secondary"
  									onClick={() => this.setState({ preview: false })}
  								>
  									<i className="material-icons">cancel</i>
  								</Button>
  							</div>
  							<div>
  								<SeedDataTemplate dataToPreview={currentData} />
  							</div>
  						</div>
  					) : null
  				}
  			</div>
  		</div>
  	);
  }
}
 
export default SuggestedFactchecks;