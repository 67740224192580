import React, { Component } from 'react';
import './style.css';
import ReactPlayer from 'react-player';
import {
  Button,
  Snackbar,
  SnackbarContent
} from '@material-ui/core';
import Api from '../../services/api';
import Moment from 'moment'

class MergedFC extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hash: this.props.hash,
      snackbarOpen: false,
      snackbarContent: ''
    };
  }

  deleteMergedMedia = (id) => {

    Api.deleteMergedMedia(id)
      .then(res => {
        return res.json();
      }).then(res => {

        if (res.success) {

          let { hash } = this.state;
          hash = hash.filter(item => item._id !== id);
          this.setState({
            hash,
            snackbarOpen: true,
            snackbarContent: 'Media deleted successfully'
          });
        }
      })
      .catch(err => {
      });
  }

  handleSnackbarClose = () => {

    this.setState({ snackbarOpen: false });
  }

  renderImages = (images, id) => {
    if (images.length) {
      let randomBg = ['#e6ee9c', '#c5e1a5', '#80deea', '#9fa8da', '#f48fb1', '#ffe082', '#bcaaa4'];
      let bg = randomBg[Math.floor(Math.random() * randomBg.length)];
      return (
        <React.Fragment><div
          key={id}
          style={{
            padding: '5px',
            display: 'flex',
            position: 'relative',
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 3px 4px',
            marginRight: '15px',
            flexDirection: 'column',
            background: 'white',
            border: `2px solid ${bg}`,
            borderRadius: '4px',
          }}>
          {images.map((img,index) =>
            <React.Fragment key={index}><div
              key={img._id}
              style={{
                backgroundColor: bg,
                padding: '10px',
                height: '180px',
                margin: '5px',
                borderRadius: '3px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                position: 'relative',
                boxShadow: '0px 3px 4px rgba(0,0,0,0.3)',
              }}>
              <i className='material-icons delete-merged-media-btn'
                onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteMergedMedia(id); }
                }>
                close
          </i>
              <img style={{width: '200px'}} height='100%' src={img.path} />
            </div>
              <div className="image-video-fc-info">
                <div style={{paddingBottom: '10px', paddingTop: '10px'}}
                ><strong>Phash</strong> {img.pHash}</div>
                <div style={{paddingBottom: '10px', paddingTop: '10px'}}>
                  <div style={{display: 'flex'}}><strong style={{paddingRight: '5px'}}>MD5</strong>{img.md5}</div>
                </div>
              <div style={{paddingBottom: '10px', paddingTop: '10px'}}><strong>Created Date</strong> {Moment(img.createdAt)
              .format('DD MMMM YYYY')}</div>
              </div>
            </React.Fragment>
          )}
        </div></React.Fragment>
      );
    }
  }

  renderVideos = (videos, id, videosPhash) => {
    let randomBg = ['#e6ee9c', '#c5e1a5', '#80deea', '#9fa8da', '#f48fb1', '#ffe082', '#bcaaa4'];
    let bg = randomBg[Math.floor(Math.random() * randomBg.length)]; 
    if (videos.length) {
      return (
        <div className="seed-data-fc-card-tile" style={{borderColor: `${bg}`}}>
          <i className='material-icons delete-merged-media-btn'
            onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteMergedMedia(id); }
            }>
            close
          </i>
          {videos.map((video,index) =>
            <div key={index}>
              <div style={{
                borderRadius: '4px',
                padding: '10px', 
                width:"300px",
                backgroundColor: `${bg}`
              }}>
              <ReactPlayer
                style={{backgroundColor: '#000' }}
                url={video ? video.path : ''}
                playing={false}
                controls={true}
                height="220px"
                width="300px"
              /></div>
              <div className="image-video-fc-info">
                <div style={{paddingBottom: '10px', paddingTop: '10px'}}
                ><strong style={{paddingRight: '5px'}}>Phash</strong>
                { videosPhash ? videosPhash[index] ? videosPhash[index][0].pHash : '' : ''}</div>
                <div style={{paddingBottom: '10px', paddingTop: '10px'}}>
                   <div style={{display: 'flex'}}>
                     <strong style={{paddingRight: '5px'}}>MD5</strong> {video ? video.md5 : ''}</div>
                </div>
              <div style={{paddingBottom: '10px', paddingTop: '10px'}}><strong>Created Date</strong> {Moment(video ? video.createdAt : '')
              .format('DD MMMM YYYY')}</div>
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  render() {
    let { hash } = this.state;
    return (
      <div className='merged-fc-modal'>
        <div className='merged-fc-wrapper'>
          <div className='merged-fc-modal-action'>
            <h2 style={{ display: 'inline', alignSelf: 'flex-start' }}>Merged Media</h2>
            <Button
              color='secondary'
              onClick={() => this.props.closeModal()}>
              <i className='material-icons'>close</i>
            </Button>
          </div>
          <div style={{ overflow: 'scroll', height: '70vh' }}>
            <div>
              <h3>Images</h3>
              <div className='merged-fc-image-wrapper'>
                {
                  hash.map(item =>
                    this.renderImages(item.images, item._id)
                  )
                }
              </div>
            </div>
            <div>
              <h3>Videos</h3>
              <div className="video-fc-hash-container">
              {hash.map((item, index) =>
                  <React.Fragment key={index}>
                    {
                      typeof item.video !== 'undefined'
                        ? this.renderVideos(item.video, item._id, item.videoImages)
                        : null
                    }
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <SnackbarContent className="success-bg-color" message={this.state.snackbarContent} />
        </Snackbar>
      </div>
    );
  }
}

export default MergedFC;