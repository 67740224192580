import React, { Component } from 'react';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import './style.css';
import { Link } from 'react-router-dom';
import RolesPermission from '../../services/roles-permission';
import CookieManager from '../../services/cookie-manager';

class Sidebar extends Component {
	constructor(props) {
    super(props);
    let shrinkSidebar = CookieManager.getCookie('shrinkSidebar') === null ? false : CookieManager.getCookie('shrinkSidebar');
    shrinkSidebar = shrinkSidebar === 'true';
		this.state = {
			shrinkSidebar: shrinkSidebar,
			activeLink: '',
			isAdmin:''
		};
	}

	componentDidMount() {
		let path = window.location.pathname;
		switch (path) {
			case '/dashboard':
				this.setState({ activeLink: 1 });
				break;
			case '/seed-data':
				this.setState({ activeLink: 2 });
				break;
			case '/lite-seed-data':
				this.setState({ activeLink: 3 });
				break;
			case '/manage-requests':
				this.setState({ activeLink: 4 });
				break;
			case '/Triage':
				this.setState({ activeLink: 5 });
				break;
			case '/settings':
				this.setState({ activeLink: 6 });
				break;
			case '/users':
				this.setState({ activeLink: 7 });
				break;
			case '/request-log':
				this.setState({ activeLink: 8 });
				break;
			case '/logout':
				this.setState({ activeLink: 9 });
				break;
			case '/workbench':
				this.setState({ activeLink: 11 });
				break;
			case '/similar-requests':
				this.setState({ activeLink: 12 });
				break;
			case '/notifications':
				this.setState({ activeLink: 13 });
				break;
		}
  }
  
  logout = () => {
  	CookieManager.deleteCookie('_data');
  	window.location = '/login';
  }

  shrinkSidebar = () => {
  	let shrinkSidebar = this.state.shrinkSidebar ? false : true;
  	this.setState({ shrinkSidebar });
    this.props.toggleMain();
    CookieManager.setCookie('shrinkSidebar', shrinkSidebar);
  };

  activeLink = (index) => {
  	this.setState({activeLink: index});
  }

  getNav = () => {
  	let { shrinkSidebar, activeLink } = this.state;
  	let shrinkStyle = {
  		opacity: '0',
  		visibility: 'collapse'
  	};
  	let expandStyle = {
  		opacity: '1',
  		transition: 'all 2s ease',
  		visibility: 'visible'
  	};
  	switch(RolesPermission.isAdmin()) {
  	case 'ADMIN' : return (
			<div style={{ overflowY: 'scroll', height: '82vh'}}>
  		<List component="nav" >
  			<Link 
  				onClick={() => this.activeLink(1)}
  				to="/dashboard"
  			>
  				<ListItem 
  					button
  					style={{ backgroundColor: activeLink===1 ? 'rgba(0,0,0,0.1)':'', padding: '0px 10px' , padding: '0px 10px' }}
  				>
  					<ListItemIcon>
  						{/* <i style={{color: "#ff5722"}} className="material-icons">dashboard</i> */}
  						<img
  							style = { {width: '30px', height: '30px'} }
  							src = {require('../../assets/icons/dashboard.png')}
  							alt =''
  						/>

  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Dashboard"
  					/>
  				</ListItem>
  			</Link>
  			<Link
  				onClick={() => this.activeLink(2)}
  				to="/seed-data"
  			>
  				<ListItem  button
  					style={{ backgroundColor: activeLink===2 ? 'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						{/* <i style={{color: "green"}} className="material-icons">spa</i> */}
  						<img
  							style = { {width: '30px', height: '30px'} }
  							src = { require('../../assets/icons/seed.png') }
  							alt =''
  						/>

  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Seed Data"
  					/>
  				</ListItem>
  			</Link>
			<Link
  				onClick={() => this.activeLink(3)}
  				to="/lite-seed-data"
  			>
  				<ListItem  button
  					style={{ backgroundColor: activeLink===3 ? 'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						{/* <i style={{color: "green"}} className="material-icons">spa</i> */}
  						<img
  							style = { {width: '30px', height: '30px', transform: 'rotate(30deg)'} }
  							src = { require('../../assets/icons/seed.png') }
  							alt =''
  						/>

  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Lite Seed Data"
  					/>
  				</ListItem>
  			</Link>
  			<Link onClick={() => this.activeLink(4)} to="/manage-requests">
  				<ListItem button
  					style={{ backgroundColor: activeLink ===4 ?'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						<img
  							style = { {width: '30px', height: '30px'} }
  							src = { require('../../assets/icons/manage.png') }
  							alt =''
  						/>
  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Manage Requests"
  					/>
  				</ListItem>
  			</Link>
  			<Link onClick={() => this.activeLink(6)} to="/settings">
  				<ListItem button
  					style={{ backgroundColor: activeLink ===6 ?'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						<img
  							style = { {width: '30px'} }
  							src = {require('../../assets/icons/settings.svg')}
  							alt =''
  						/>
  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="App Settings"
  					/>
  				</ListItem>
  			</Link>
  			<Link onClick={() => this.activeLink(7)} to="/users">
  				<ListItem button
  					style={{ backgroundColor: activeLink ===7 ?'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						<img
  							style = {{width: '30px', height:'30px'}}
  							src = {require('../../assets/icons/user-mangement.png')}
  							alt=''
  						/>
  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="User Management"
  					/>
  				</ListItem>
  			</Link>
  			<Link onClick={() => this.activeLink(8)} to="/request-log">
  				<ListItem button
  					style={{ backgroundColor: activeLink === 8 ?'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						<img
  							style = {{width: '30px', height:'30px'}}
  							src = {require('../../assets/icons/request-log.png')}
  							alt=''
  						/>
  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Request Log"
  					/>
  				</ListItem>
  			</Link>
				<Link onClick={() => this.activeLink(11)} to="/workbench">
  				<ListItem button
  					style={{ backgroundColor: activeLink === 11 ?'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						<img
  							style = {{width: '30px', height:'30px'}}
  							src = {require('../../assets/icons/icons-bookmark-page.png')}
  							alt=''
  						/>
  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Workbench"
  					/>
  				</ListItem>
  			</Link>
        <Link onClick={() => this.activeLink(12)} to="/similar-requests">
  				<ListItem button
  					style={{ backgroundColor: activeLink === 12 ?'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						<img
  							style = {{width: '30px', height:'30px'}}
  							src = {require('../../assets/icons/similar.png')}
  							alt=''
  						/>
  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Similar Requests"
  					/>
  				</ListItem>
  			</Link>
				<Link onClick={()=> this.activeLink(13)} to="/notifications">
					<ListItem button
						style={{backgroundColor: activeLink === 13 ? 'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}>
							<ListItemIcon>
  						<img
  							style = {{width: '30px', height:'30px'}}
  							src = {require('../../assets/icons/notification.png')}
  							alt=''
  						/>
  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Notifications"
  					/>
						</ListItem>
				</Link>
        <Link onClick={() => this.logout()} to="">
          <ListItem
            className='shutdown' 
            button
  					style={{ backgroundColor: activeLink === 9 ?'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						<img
  							style = {{width: '30px', height:'30px'}}
  							src = {require('../../assets/icons/shutdown.png')}
  							alt=''
  						/>
  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Logout"
  					/>
  				</ListItem>
  			</Link>
			</List>
		</div>	
			);
  	case 'MODERATOR': return (
			<div style={{ overflowY: 'scroll', height: '82vh'}}>
  		<List component="nav" >
  			<Divider />
  			<Link
  				onClick={() => this.activeLink(3)}
  				to="/seed-data"
  			>
  				<ListItem  button
  					style={{ backgroundColor: activeLink===3 ? 'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						{/* <i style={{color: "green"}} className="material-icons">spa</i> */}
  						<img
  							style = {{width: '30px', height: '100%'}}
  							src={require('../../assets/icons/seed.png')}
  							alt =''
  						/>

  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Seed Data"
  					/>
  				</ListItem>
  			</Link>
        <Link onClick={() => this.logout()} to="">
          <ListItem
            className='shutdown' 
            button
  					style={{ backgroundColor: activeLink === 9 ?'rgba(0,0,0,0.1)':'', padding: '0px 10px'  }}            
  				>
  					<ListItemIcon>
  						<img
  							style = {{width: '30px', height:'30px'}}
  							src = {require('../../assets/icons/shutdown.png')}
  							alt=''
  						/>
  					</ListItemIcon>
  					<ListItemText
  						style={shrinkSidebar ? shrinkStyle : expandStyle}
  						primary="Logout"
  					/>
  				</ListItem>
  			</Link>
  		</List>
			</div>
  	);
  	}
  }

  render() {
  	let { shrinkSidebar } = this.state;
  	return (

  		<div
  			className="sidebar"
  			style={{
  				width: shrinkSidebar ? '80px' : '185px',
  				transition: 'all 0.3s ease-out'
  			}}
  		>
  			<div className="sidebar-toggler" onClick={this.shrinkSidebar}>
  				<i style={{verticalAlign: 'middle'}} className="material-icons">list</i>
          <span style={{color: '#fff', verticalAlign: 'middle', fontWeight: '800', overflow: 'hidden'}}>
            {
              shrinkSidebar ? 'FC' : 'Fact Check'
            }
          </span>
  			</div>
  			{this.getNav()}
  		</div>
  	);
  }
}


export default Sidebar;
